import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ThemeSwitcher as ThemeSwitcherComponent } from 'sezane-components';

export const ThemeSwitcher = () => {
    return (
        <div className="c-nav c-header__nav u-mt(xs) u-d(flex) u-ai(center) u-jc(space-around) u-z-index-30">
            <div className="u-c(gray-500)">
                <FormattedMessage id="sidebar.theme_switcher" />
            </div>
            <ThemeSwitcherComponent color="unstyled" textSecondary position="left" />
        </div>
    );
};

export default ThemeSwitcher;
