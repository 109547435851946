export const FETCH_REFERENCES = 'FETCH_REFERENCES';
export const FETCH_REFERENCES_SUCCESS = 'FETCH_REFERENCES_SUCCESS';
export const FETCH_REFERENCES_FAILURE = 'FETCH_REFERENCES_FAILURE';

export default () => ({
    types: [FETCH_REFERENCES, FETCH_REFERENCES_SUCCESS, FETCH_REFERENCES_FAILURE],
    payload: {
        request: {
            url: 'ecommerce-private/v1/references',
            method: 'GET',
            params: {
                type: 'website',
            },
        },
    },
    noSite: true,
});
