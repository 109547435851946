import { withKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Login as LoginComponents } from 'sezane-components';

import { setRealm } from '@actions/auth';
import PageHead from '@components/PageHead';
import routesNames from '@config/routesNames';
import { Loader } from 'sezane-components';

class Login extends React.Component {
    state = {
        clickedOnRealm: null,
    };

    componentDidMount() {
        document.body.classList.add('c-page--cover');
        document.querySelector('#root').classList.remove('c-page__container');
        document.querySelector('#root').classList.add('u-d(flex)');
    }

    componentWillUnmount() {
        document.body.classList.remove('c-page--cover');
        document.querySelector('#root').classList.add('c-page__container');
        document.querySelector('#root').classList.remove('u-d(flex)');
    }

    componentDidUpdate() {
        const { realm, keycloak } = this.props;
        const { clickedOnRealm } = this.state;

        // Realm and keycloack are initialized, we initiate login
        if (clickedOnRealm && realm && keycloak && !keycloak.authenticated && realm === keycloak.realm) {
            this.keycloakLogin();
        }
    }

    keycloakLogin = () => {
        const { location, keycloak } = this.props;
        const options = {};

        if (location.state?.referrer?.pathname) {
            options.redirectUri = window.location.origin + location.state.referrer.pathname;
        }

        keycloak.login(options);
    };

    login = selectedRealm => {
        const { keycloak } = this.props;

        // Keycloak is already initalized with the proper realm
        if (keycloak && !keycloak.authenticated && selectedRealm === keycloak.realm) {
            this.keycloakLogin();
            // Async initialization
        } else {
            this.setState({ clickedOnRealm: selectedRealm });
            this.props.setRealm(selectedRealm);
        }
    };

    render() {
        const { keycloak, keycloakInitialized } = this.props;

        if (keycloak.authenticated) {
            return <Redirect to={routesNames.DASHBOARD} />;
        }

        return (
            <>
                <PageHead title="login.title" />
                {!keycloakInitialized && <Loader />}
                {keycloakInitialized && <LoginComponents login={this.login} />}
            </>
        );
    }
}

Login.propTypes = {
    keycloak: PropTypes.object.isRequired,
    keycloakInitialized: PropTypes.bool.isRequired,
    setRealm: PropTypes.func.isRequired,
    realm: PropTypes.string,
};

const mapStateToProps = state => ({
    realm: state.auth.realm,
});

const mapDispatchToProps = {
    setRealm,
};

export default withKeycloak(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
