import { FiltersParams, SortParams } from '@types';
import { listActionCreator, withPage, withRequestParams, withValues, withNoSite, withNoBrand } from '../../crud';
import { name, schema } from './config';
import { baseUrl } from './../voucherCampaigns/config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (voucherCampaingId: string, page: number, sort: SortParams, filters: FiltersParams, search: object) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const filterParams: Record<string, any> = {};

    if (filters) {
        filterParams.enabled = filters.enabled;
        filterParams.available = filters.available || undefined;
        filterParams.startAt = filters.startAt ? { after: filters.startAt } : undefined;
        filterParams.endAt = filters.endAt ? { before: filters.endAt } : undefined;
    }

    const action = withValues({
        sort,
        filters,
        search,
    })(
        withRequestParams({
            search: search || undefined,
            ...sortParam,
            ...filterParams,
        })(withPage(page)(withNoSite(withNoBrand(actionCreator()))))
    );

    action.payload.request.url = `${action.payload.request.url}/${voucherCampaingId}/code`;
    return action;
};
