import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dateLocalefr from 'date-fns/locale/fr';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { History, LocationState } from 'history';
import { KeycloakInstance, KeycloakProvider, Realm, Theme, ThemeProvider } from 'sezane-components';
import { loginSuccess } from '@actions/auth';
import fetchNotifications from '@actions/notifications';
import fetchReferencesOms from '@actions/oms/references';
import fetchReferencesEco from '@actions/references';
import { changeTheme, setUserConfiguration } from '@actions/ui';
import ScrollToTop from '@components/ScrollToTop';
import createNotifier from '@config/notifier';
import routesNames from '@config/routesNames';
import { RootState } from '@reducers';
import { ConfirmProvider } from '@utils/hooks/useConfirm';
import { getUserLocale, importMessages, LocaleMessages, SupportedLocales } from '@utils/intl';
import { logAppVersion } from '@utils/logs';
import Router from './Router';
import ecommerceApiClient from '@api/clients/ecommerce';
import { UserConfigurationConfigurationWrite } from '@types';

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } } });

const NOTIFICATIONS_TIMEOUT = 30 * 1000;

type Props = {
    language: SupportedLocales | null;
    loginSuccess: (data: { keycloak: Keycloak.KeycloakInstance }) => void;
    fetchNotifications: () => void;
    fetchReferencesEco: () => void;
    fetchReferencesOms: () => void;
    changeTheme: (theme: Theme) => void;
    realm?: Realm;
    history: History<LocationState>;
    theme: Theme;
    setUserConfiguration: (data: UserConfigurationConfigurationWrite) => void;
};

type State = {
    messages: Partial<LocaleMessages> | undefined;
};

export let intlProvider: IntlProvider = new IntlProvider({
    locale: 'fr',
    messages: {},
    onError: () => {},
});

logAppVersion();

class App extends React.Component<Props, State> {
    intervalID = 0;

    state = {
        messages: undefined,
    };

    getLocale = () => {
        const { language } = this.props;

        return language || getUserLocale();
    };

    async updateLocale() {
        const locale = this.getLocale();
        const messages = await importMessages(locale);
        this.setState({ messages });
        intlProvider = new IntlProvider({
            locale,
            messages: messages as Record<string, string>,
            onError: () => {},
        });
        createNotifier(intlProvider.state.intl);
    }

    onAuthSuccess = (keycloak: KeycloakInstance) => {
        const { loginSuccess, fetchReferencesEco, fetchReferencesOms } = this.props;

        loginSuccess({ keycloak });
        fetchReferencesEco();
        fetchReferencesOms();
        this.initNotifications();
        ecommerceApiClient.getUserConfigurationUserConfigurationItem().then(({ data }) => {
            this.props.setUserConfiguration(data);
        });

        window.DD_RUM &&
            window.DD_RUM.onReady(() => {
                const {
                    // @ts-ignore We can't type custom jwt properties
                    tokenParsed: { name, email, sub },
                } = keycloak;
                window.DD_RUM.setUser({
                    id: sub,
                    name,
                    email,
                });
            });
    };

    onAuthError = () => {
        this.props.history.push(routesNames.LOGOUT);
    };

    initNotifications = () => {
        const { fetchNotifications } = this.props;

        fetchNotifications();

        if (!this.intervalID) {
            this.intervalID = window.setInterval(fetchNotifications, NOTIFICATIONS_TIMEOUT);
        }
    };

    componentDidMount() {
        registerLocale('fr', dateLocalefr);

        this.updateLocale();
    }

    componentDidUpdate(prevProps: Props) {
        const { language } = this.props;

        if (language !== prevProps.language) {
            this.updateLocale();
        }
    }

    componentWillUnmount() {
        this.intervalID && clearInterval(this.intervalID);
    }
    render() {
        const locale = this.getLocale();
        return (
            <KeycloakProvider
                clientId="bo-store-client"
                url={window.REACT_APP_KEYCLOACK_URL}
                realm={this.props.realm}
                onAuthSuccess={this.onAuthSuccess}
                onAuthError={this.onAuthError}
            >
                <IntlProvider
                    timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    locale={locale}
                    messages={this.state.messages}
                >
                    <ThemeProvider theme={this.props.theme} setTheme={this.props.changeTheme}>
                        <QueryClientProvider client={queryClient}>
                            <ConfirmProvider>
                                <Router />
                                <ScrollToTop />
                            </ConfirmProvider>
                        </QueryClientProvider>
                    </ThemeProvider>
                </IntlProvider>
            </KeycloakProvider>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    realm: state.auth.realm as Realm,
    language: state.ui.language,
    theme: state.ui.theme,
});

const mapDispatchToProps = {
    loginSuccess,
    fetchNotifications,
    fetchReferencesEco,
    fetchReferencesOms,
    changeTheme,
    setUserConfiguration,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
