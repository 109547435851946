import classNames from 'classnames';
import React, { ReactElement } from 'react';

const HelpBlock = ({ className, help, renderHelp, value }: HelpBlockProps) => {
    if (renderHelp) {
        return <p className={classNames('u-c(info)', className)}>{renderHelp(value) || null}</p>;
    }

    if (help) {
        return <p className={classNames('u-c(info)', className)}>{help}</p>;
    }

    return null;
};

interface HelpBlockProps {
    className?: string;
    help?: ReactElement;
    renderHelp?: (value: any) => ReactElement | null;
    value?: any;
}

export default HelpBlock;
