export const TYPES = {
    FETCH: 'FETCH_NOTIFICATIONS',
    FETCH_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_FAILURE: 'FETCH_NOTIFICATIONS',
};

export default () => ({
    types: [TYPES.FETCH, TYPES.FETCH_SUCCESS, TYPES.FETCH_FAILURE],
    payload: {
        request: {
            url: 'ecommerce-private/v1/notifications',
            method: 'GET',
        },
    },
});
