import React, { CSSProperties, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Grid, Tooltip } from 'sezane-components';

import { setDashboard } from '@actions/ui';
import PageHead from '@components/PageHead';
import Checkbox from '@components/form/input/Checkbox';
import { useAppDispatch, useAppSelector } from '@utils/hooks/redux';
import Profile from './Profile';
import Analytics from './widgets/Analytics';
import BestProducts from './widgets/BestProducts';
import { PreviewOctobre, PreviewSezane } from './widgets/Iframe';
import Image from './widgets/Image';
import QuickAccess from './widgets/QuickAccess';
import Turnover from './widgets/Turnover';

export type WidgetDefaultProps = {
    w: number;
    h: number;
    x: number;
    y: number;
    i: string;
    isResizable?: boolean;
};

const WIDGETS_COMPONENTS = {
    QuickAccess,
    Image,
    Analytics,
    BestProducts,
    PreviewSezane,
    PreviewOctobre,
    Turnover,
};

const DASHBOARD_LAYOUT: Layouts = [
    { w: 2, h: 3, x: 0, y: 0, i: 'Image' },
    { w: 1, h: 2, x: 0, y: 3, i: 'QuickAccess' },
    { w: 1, h: 2, x: 1, y: 3, i: 'Turnover' },
    { w: 2, h: 2, x: 0, y: 4, i: 'BestProducts' },
    { w: 2, h: 2, x: 0, y: 5, i: 'Analytics' },
    {
        w: 1,
        h: 2,
        x: 0,
        y: 6,
        i: 'PreviewSezane',
        notByDefault: true,
    },
    {
        w: 1,
        h: 2,
        x: 1,
        y: 6,
        i: 'PreviewOctobre',
        notByDefault: true,
    },
];

const DEFAULT_LAYOUT = DASHBOARD_LAYOUT.filter(component => !component.notByDefault);

const ResponsiveGridLayout = WidthProvider(Responsive);

interface LayoutWithComponent extends Layout {
    // Override key to use widget names
    i: keyof typeof WIDGETS_COMPONENTS;
    overflow?: CSSProperties['overflow'];
    props?: any;
    notByDefault?: boolean;
}
export type Layouts = LayoutWithComponent[];

type WidgetsList = Array<keyof typeof WIDGETS_COMPONENTS>;

const Dashboard = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [edit, setEdit] = useState(false);

    const layouts = useAppSelector(state =>
        state.ui.dashboard && state.ui.dashboard.length > 0 ? state.ui.dashboard : DEFAULT_LAYOUT
    );

    const setLayouts = (layouts: Layouts) => dispatch(setDashboard(layouts));

    // Migrate old storage to new API storage
    useEffect(() => {
        if (localStorage.getItem('dashboardLayout')) {
            const oldLayouts = JSON.parse(localStorage.getItem('dashboardLayout')!);
            if (oldLayouts.md) {
                setLayouts(oldLayouts.md);
                localStorage.removeItem('dashboardLayout');
            }
        }
    }, []);

    return (
        <div className="c-page--gray">
            <PageHead title="dashboard.page_title" />
            <section>
                <Grid className="u-mt(lg) u-ai(center) u-jc(space-between)">
                    <Col>
                        <Profile />
                    </Col>
                    <Col>
                        <Tooltip
                            position="right"
                            arrowPosition="right"
                            renderToggleButton={toggle => (
                                <Button
                                    icon="cog"
                                    iconOnly
                                    color="tertiary"
                                    size="sm"
                                    onClick={toggle}
                                    hiddenLabel={intl.formatMessage({ id: 'common.edit' })}
                                />
                            )}
                        >
                            <fieldset>
                                <Checkbox
                                    label={<FormattedMessage id="dashboard.actions.edit_layout" />}
                                    className="u-mr(xs)"
                                    name="edit-mode"
                                    asToggle
                                    onChange={e => {
                                        setEdit(e.target.checked);
                                    }}
                                />
                                <p className="u-mt(sm)">
                                    <FormattedMessage id="dashboard.widgets" />
                                </p>
                                <ul className="u-res(list) u-mt(none)">
                                    {(Object.keys(WIDGETS_COMPONENTS) as WidgetsList).map(component => (
                                        <Checkbox
                                            checked={!!layouts.find(layout => layout.i === component)}
                                            key={component}
                                            label={<span>{component}</span>}
                                            className="u-mr(xs)"
                                            name={`checkbox-${component}`}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    setLayouts([
                                                        ...layouts,
                                                        DASHBOARD_LAYOUT.find(l => l.i === component)!,
                                                    ]);
                                                } else {
                                                    setLayouts(layouts.filter(layout => layout.i !== component));
                                                }
                                            }}
                                        />
                                    ))}
                                </ul>
                                <Button
                                    color="secondary"
                                    size="sm"
                                    className="u-mt(sm)"
                                    onClick={() => {
                                        setLayouts(DEFAULT_LAYOUT);
                                    }}
                                >
                                    <FormattedMessage id="dashboard.actions.reset_layout" />
                                </Button>
                            </fieldset>
                        </Tooltip>
                    </Col>
                </Grid>
                <ResponsiveGridLayout
                    isBounded
                    className="layout"
                    breakpoints={{ md: 996 }}
                    cols={{ md: 2 }}
                    layouts={{ md: layouts }}
                    onLayoutChange={(layouts, allLayouts) => {
                        setLayouts(allLayouts.md as Layouts);
                    }}
                    isResizable={edit}
                    isDraggable={edit}
                    rowHeight={160}
                    containerPadding={[0, 0]}
                >
                    {layouts.map(layout => {
                        return WIDGETS_COMPONENTS[layout.i] ? (
                            <div key={layout.i} style={{ overflow: 'hidden' }}>
                                {React.createElement(WIDGETS_COMPONENTS[layout.i], {
                                    key: layout.i,
                                    ...layout,
                                })}
                            </div>
                        ) : null;
                    })}
                </ResponsiveGridLayout>
            </section>
        </div>
    );
};

const DashboardWithErrorFallback = () => (
    <ErrorBoundary fallback={<div className="u-mt(md)">Error while loading dashboard.</div>}>
        <Dashboard />
    </ErrorBoundary>
);

export default DashboardWithErrorFallback;
