import { schema } from '@actions/saleWaitingPages/config';
import { TYPES as CREATE_TYPES } from '@actions/saleWaitingPages/create';
import { TYPES as GET_TYPES } from '@actions/saleWaitingPages/get';
import { TYPES as LIST_TYPES } from '@actions/saleWaitingPages/list';
import { TYPES as UPDATE_TYPES } from '@actions/saleWaitingPages/update';
import { createReducer, CRUD_INITIAL_STATE, getReducer, listReducer, reduceReducers, updateReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    createReducer(CREATE_TYPES, schema),
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema)
);
