import { listActionCreator, withWorkspace, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl, getFilterParams } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    query = null,
    workspaceId = null,
    pagination = true,
    filters = {},
    sort = null,
    save = true
) => {
    const filterParams = getFilterParams(filters);
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    const action = {
        ...actionCreator(),
        noSite: !!filterParams.brand,
        noBrand: !!filterParams.brand,
    };

    return withValues({
        sort,
        pagination,
        page,
        filters,
        save,
    })(
        withRequestParams({
            'saleVersions.translations.label': query || undefined,
            pagination: pagination,
            page: pagination === true && !!page ? page : undefined,
            ...filterParams,
            ...sortParam,
        })(withWorkspace(workspaceId)(action))
    );
};
