export const schema = 'shipping-mode';
export const name = 'SHIPPING_MODE';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/shipping-mode`;

export interface ShippingModesFilters {
    enabled?: boolean;
    itemsPerPage?: number;
}

export const getFilterParams = ({ enabled, ...filters }: ShippingModesFilters) => {
    const filterParams: ShippingModesFilters = { ...filters };

    if (enabled) {
        filterParams['enabled'] = enabled;
    }

    return filterParams;
};
