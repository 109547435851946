import { listActionCreator, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (onlyValue = false) => {
    const valueFilter = onlyValue
        ? {
              'exists[value]': onlyValue,
          }
        : null;

    return withRequestParams(valueFilter)(actionCreator());
};
