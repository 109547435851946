import { listActionCreator, withPage, withValues, withRequestParams, withNoSite } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, sort = null, filters = {}, query = null) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    let filterParams = {};

    if (!!filters) {
        const { enabled, ...otherFilters } = filters;
        filterParams = otherFilters;

        if (filters.enabled) {
            filterParams.enabled = true;
        }
    }

    return withValues({
        sort,
        query,
        filterParams,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filterParams,
        })(withPage(page)(withNoSite(actionCreator())))
    );
};
