export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_REALM = 'SET_REALM';

export const loginSuccess = values => ({
    type: LOGIN_SUCCESS,
    ...values,
});

export const logout = () => {
    return dispatch => {
        dispatch({ type: LOGOUT });
        dispatch({ type: LOGOUT_SUCCESS });

        return Promise.resolve();
    };
};

export const setRealm = realm => ({
    type: SET_REALM,
    realm,
});
