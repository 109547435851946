import { set } from 'lodash';
import { SubmissionError } from 'redux-form';
import getNotifier, { DANGER } from '@config/notifier';
import { DEFAULT_ERROR_TITLE, getSubMessage, REQUEST_CANCELED_EXCEPTION } from '@middlewares/axios';

export default function errorHandler(e: any) {
    const errorData = e.error;

    if (process.env.NODE_ENV !== 'test') {
        const Notifier = getNotifier();
        if (errorData && errorData['invalid-params']) {
            Notifier.notify(DANGER, 'error.default', undefined, getSubMessage(errorData));
        } else if (errorData?.message && e.status !== 500) {
            Notifier.notify(DANGER, 'error.' + errorData.message, undefined, getSubMessage(errorData));
        } else if (errorData?.title && errorData.title !== DEFAULT_ERROR_TITLE) {
            Notifier.notify(DANGER, 'error.default', undefined, errorData.title);
        } else if (errorData?.message !== REQUEST_CANCELED_EXCEPTION) {
            Notifier.notify(DANGER, 'error.default');
        }
    }

    // Handle validation errors / bad requests
    if (e.status === 400) {
        const violations = {};

        if (errorData.validation) {
            // CMS / ApiPlatform errors
            Object.keys(errorData.violations).forEach(path => {
                if (path.endsWith('translations')) {
                    set(violations, '_error', errorData.violations[path].message);

                    return;
                }
                set(violations, path, errorData.violations[path]);
            });
        } else if (errorData.type === 'validation_failed') {
            // OMS / RFC 7807 errors
            (errorData['invalid-params'] ?? []).forEach(({ name, reason, reasonKey }: any) => {
                if (name.endsWith('translations')) {
                    set(violations, '_error', reason);

                    return;
                }
                set(violations, name, { message: reason, messageKey: reasonKey });
            });
        }

        throw new SubmissionError(violations);
    }

    throw e;
}
