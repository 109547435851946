export const schema = 'voucher-campaign';
export const name = 'VOUCHER_CAMPAIGN';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/voucher-campaign`;

export interface Filters {
    brands?: string[];
    enabledOrScheduled?: string;
    recipientType?: string;
    searchOnCode?: string[];
    sites?: string[];
    sources?: string[];
    isExcludedRetailOnly?: string;
    currencies?: string[];
}
