import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import { formValueSelector } from 'redux-form';
import get from 'lodash/get';

export const storeSelector = (state, key) => get(state, key);

export const entitiesSelector = createSelector(storeSelector, store => store.entities);

export const sortedIdsSelector = createSelector(storeSelector, store => store.sortedIds);

export const itemsSelector = createSelector(entitiesSelector, sortedIdsSelector, (entities, sortedIds) =>
    sortedIds.map(id => entities[id])
);

export const allItemsSelector = createSelector(entitiesSelector, entities => Object.values(entities));

export const rootMenuItemsSelector = createSelector(entitiesSelector, entities => {
    const rootEntities = {};
    Object.keys(entities).forEach(id => {
        if (entities[id]['level'] === 0) {
            rootEntities[id] = entities[id];
        }
    });

    return rootEntities;
});

export const itemSelector = createSelector(entitiesSelector, entities => memoize(id => entities[id]));

export const formSelector = createSelector(formValueSelector, selector => (state, field) => selector(state, field));

const getDateFromValue = date => {
    if (!date) {
        return null;
    }

    return date instanceof Date ? date : new Date(date);
};

export const dateFormSelector = createSelector(
    formValueSelector,
    selector => (state, field) => getDateFromValue(selector(state, field))
);
