import { produce } from 'immer';
import remove from 'lodash/remove';
import { schema } from '@actions/saleVersions/config';
import { TYPES as LIST_TYPES } from '@actions/saleVersions/list';
import { TYPES as CREATE_TYPES } from '@actions/saleVersions/create';
import { TYPES as UPDATE_TYPES } from '@actions/saleVersions/update';
import { TYPES as PATCH_TYPES } from '@actions/saleVersions/patch';
import { TYPES as DELETE_SALE_TYPES } from '@actions/sales/delete';
import { CRUD_INITIAL_STATE, listReducer, createReducer, updateReducer, reduceReducers, patchReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

const deleteSaleReducer = (state, action) => {
    switch (action.type) {
        case DELETE_SALE_TYPES.DELETE: {
            return produce(state, draftState => {
                draftState.loading = true;
                draftState.loaders.list = true;

                return draftState;
            });
        }

        case DELETE_SALE_TYPES.DELETE_SUCCESS: {
            const saleId = action.meta.previousAction.id;

            return produce(state, draftState => {
                const salesVersionToDelete = Object.values(draftState.entities).filter(
                    saleVersion => saleVersion.sale.id === saleId
                );
                salesVersionToDelete.forEach(saleVersion => {
                    delete draftState.entities[saleVersion.id];
                    remove(draftState.sortedIds, itemId => saleVersion.id === itemId);
                    draftState.total -= 1;
                });

                draftState.loading = false;
                draftState.loaders.list = false;

                return draftState;
            });
        }

        default:
            return state;
    }
};

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    createReducer(CREATE_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    patchReducer(PATCH_TYPES, schema, true),
    deleteSaleReducer
);
