import { changeListConfigurationCreator } from '../../crud';
import { name } from './config';

export enum OrdersConfigurationPreset {
    DEFAULT = 'DEFAULT',
    TO_VALIDATE = 'TO_VALIDATE',
    CUSTOM = 'CUSTOM',
}

const { types, actionCreator } = changeListConfigurationCreator(name);
export const TYPES = types;

export default (values: any) => actionCreator(values);
