import { normalize } from 'normalizr';
import uniq from 'lodash/uniq';

import apiSchema from '@config/schema';
import { schema } from '@actions/medias/config';
import { TYPES as LIST_TYPES, ORDER_DATE, ORDER_PRODUCT } from '@actions/medias/list';
import { TYPES as PATCH_TYPES } from '@actions/medias/patch';
import { TYPES as GET_PAGES_TYPES } from '@actions/medias/getPages';
import { CHANGE_SELECTION_ORDER_TYPE } from '@actions/medias/selectionOrder';
import {
    CRUD_INITIAL_STATE,
    listReducer as crudListReducer,
    reduceReducers,
    LIST_TYPE_KEYS,
    patchReducer,
} from './crud';

export const INITIAL_STATE = {
    ...CRUD_INITIAL_STATE,
    groupedByProduct: {},
    mediaVariants: {},
    selectionFilters: {
        order: ORDER_DATE,
    },
};
const baseListReducer = crudListReducer(LIST_TYPES, schema);

const listReducer = (state, action) => {
    const result = baseListReducer(state, action);

    if (action.save === false) {
        return result;
    }

    if (action.type === LIST_TYPES[LIST_TYPE_KEYS.init]) {
        return {
            ...result,
            groupedByProduct: {},
        };
    }

    if (action.type === LIST_TYPES[LIST_TYPE_KEYS.success]) {
        const sort = action.meta ? action.meta.previousAction.sort : null;

        if (sort !== ORDER_PRODUCT) {
            const mediaVariants = {};
            Object.keys(result.entities).forEach(mediaId => {
                const media = result.entities[mediaId];
                media.mediaVariants.forEach(mv => {
                    mediaVariants[mv.id] = mv;
                });
            });

            return {
                ...result,
                mediaVariants,
            };
        }

        let entities = {};
        const mediaVariants = {};
        let sortedIds = [];
        const groupedByProduct = {};

        const data = action.payload.rawData;
        const variantIds = Object.keys(data);
        variantIds.forEach(variantId => {
            const medias = data[variantId];
            const normalized = normalize(medias, apiSchema.LIST(schema));
            entities = {
                ...entities,
                ...normalized.entities[schema],
            };
            sortedIds = uniq([...sortedIds, ...normalized.result]);
            groupedByProduct[variantId] = normalized.result;
        });

        Object.keys(entities).forEach(mediaId => {
            const media = entities[mediaId];
            media.mediaVariants.forEach(mv => {
                mediaVariants[mv.id] = mv;
            });
        });

        return {
            ...result,
            entities,
            sortedIds,
            groupedByProduct,
            mediaVariants,
        };
    }

    return result;
};

const selectionOrderReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_SELECTION_ORDER_TYPE: {
            return {
                ...state,
                selectionFilters: {
                    ...state.selectionFilters,
                    order: action.order,
                },
            };
        }

        default:
            return state;
    }
};

const getPagesReducer = (state, action) => {
    if (action.type === GET_PAGES_TYPES.GET_PAGES_SUCCESS) {
        const id = action.meta.previousAction.id;

        const entity = {
            ...(state.entities[id] || {}),
            pages: action.payload.rawData,
        };

        return {
            ...state,
            entities: {
                ...state.entities,
                [id]: entity,
            },
        };
    }

    return state;
};

export default reduceReducers(
    listReducer,
    patchReducer(PATCH_TYPES, schema),
    getPagesReducer,
    selectionOrderReducer,
    INITIAL_STATE
);
