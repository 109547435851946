import readCsv from '@actions/storeVariants/readCsv';
import { saveFileFromApiResponse } from '@config/fileSaver';
import getNotifier, { SUCCESS } from '@config/notifier';
import { getFilterParams, name } from './config';
import { withRequestParams, withWorkspace, asyncExportActionCreator } from '@actions/crud';

const { types, actionCreator } = asyncExportActionCreator(name, `/ecommerce-private/v1/store-variants/export`);
export const TYPES = types;

export const getAction = (
    workspaceId,
    ids = null,
    filters = {},
    sort = null,
    query = null,
    locale = 'fr',
    fields = [],
    preset = null
) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const filterParams = getFilterParams(filters);
    if (ids) {
        filterParams.ids = ids;
    }

    return withRequestParams({
        search: query || undefined,
        ...filterParams,
        ...sortParam,
        fields,
        locale,
        preset,
    })(withWorkspace(workspaceId)(actionCreator()));
};

export default (workspaceId, ids, filters, sort, query, locale, fields, preset) => {
    const action = getAction(workspaceId, ids, filters, sort, query, locale, fields, preset);
    return dispatch =>
        dispatch(action).then(data => {
            if (data.payload.rawData.status === 'done') {
                dispatch(readCsv(data.payload.rawData.path)).then(response => {
                    saveFileFromApiResponse(response, 'export.csv');
                });
            }

            const Notifier = getNotifier();
            Notifier.notify(SUCCESS, {
                id: 'error.common.export_success',
            });

            return data;
        });
};
