import { listActionCreator, withPage, withRequestParams, withValues, withWorkspace } from '../crud';
import { baseUrl, name, schema } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    brandOverride = undefined,
    workspaceId = null,
    excluded,
    query = null,
    sort = {
        field: 'active',
        direction: 'desc',
    },
    save = true
) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    return withValues({
        query,
        save,
    })(
        withRequestParams({
            'pageVersions.translations.title': query || undefined,
            excluded,
            brandOverride,
            ...sortParam,
        })(withPage(page)(withWorkspace(workspaceId)(actionCreator())))
    );
};
