export const defaultResetValue = {
    page: 1,
};

export const isInitiallyOpen = (filters: Record<string, any>) => {
    return Object.keys(filters).filter(filter => filter !== 'page').length > 0;
};

// TODO: refactor this to make it a pure function
export const addRangeFilter = (
    name: string,
    min: number | string | undefined,
    max: number | string | undefined,
    filters: Record<string, any>
) => {
    if (min || max) {
        filters[name] = {};
        filters[name].after = min || undefined;
        filters[name].before = max || undefined;
    }
};

// TODO: refactor this to make it a pure function
export const addOnlyTrueFilter = (name: string, value: boolean, filters: Record<string, any>) => {
    if (value === true) {
        filters[name] = true;
    }
};

export const removeDisabledFilters = (obj: object) =>
    Object.fromEntries(Object.entries(obj).filter(([k, v]: any) => !!v));
