import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { Icon, Tooltip } from 'sezane-components';

import { Size } from '@types';
import HelpBlock from './HelpBlock';
import TranslationError from './TranslationError';
import TranslationLabel from './TranslationLabel';
import TranslationWarning from './TranslationWarning';

const AbstractInput = <P extends AbstractInputProps>(
    renderInput: (props: P) => ReactNode,
    showLabel = true,
    showErrors = true
) => {
    const component: FC<P> = props => {
        const {
            className,
            control,
            formGroupClassName,
            help,
            id,
            inline,
            input,
            isRequired,
            label,
            labelClassName,
            labelValues,
            margin,
            meta,
            more,
            renderHelp,
            tag,
        } = props;

        // We want initial values to be validated if they are not empty
        const touched = meta?.touched || meta?.submitFailed;
        const displayError = showErrors && !!meta?.error && touched;

        return (
            <div
                className={classNames(
                    'c-form-group',
                    {
                        'c-form-group--control': control,
                        'c-form-group--inline': inline,
                        'is-invalid': displayError,
                        [`u-mt(${margin})`]: !!margin,
                    },
                    className,
                    formGroupClassName
                )}
            >
                {showLabel && label && (
                    <div className="c-form-group__label c-form-group__label--inline">
                        <TranslationLabel
                            label={label}
                            values={labelValues}
                            className={labelClassName}
                            htmlFor={id}
                            isRequired={isRequired}
                        />
                        {tag && (
                            <span className="c-tag c-tag--secondary c-tag--sm u-ml(xs)">
                                <FormattedMessage id={tag} />
                            </span>
                        )}
                        {(help || renderHelp) && (
                            <span className="u-ml(xs)">
                                <Tooltip
                                    onFocus
                                    renderToggleButton={() => (
                                        <Icon className="u-c(info)" icon="info-circle" color="secondary" size="sm" />
                                    )}
                                >
                                    <HelpBlock
                                        className="u-fz(sm) u-mt(xs) u-mw(300p) wrap"
                                        help={help}
                                        renderHelp={renderHelp}
                                        value={input?.value}
                                    />
                                </Tooltip>
                            </span>
                        )}
                    </div>
                )}
                {renderInput(props)}
                {displayError && <TranslationError error={meta ? meta.error : null} />}
                {showErrors && meta?.warning && <TranslationWarning warning={meta?.warning} />}
                {more}
            </div>
        );
    };

    // @ts-ignore
    component.defaultProps = {
        margin: 'sm',
    };

    component.displayName = 'AbstractInput';

    return component;
};

export type AbstractInputProps = {
    className?: string;
    control?: boolean;
    defaultSelected?: boolean;
    disabled?: boolean;
    expanded?: boolean;
    formGroupClassName?: string;
    help?: ReactElement;
    helpTooltip?: boolean;
    id?: string;
    inline?: boolean;
    input?: WrappedFieldInputProps;
    inputSize?: Size;
    isRequired?: boolean;
    label?: ReactElement | string;
    labelClassName?: string;
    labelValues?: any;
    margin?: Size;
    meta?: WrappedFieldMetaProps;
    more?: ReactNode;
    name?: string;
    onChange?: (value: any) => void;
    pattern?: string;
    pimVariantUuid?: string;
    placeholder?: ReactNode;
    renderHelp?: (value: any) => ReactElement | null;
    renderLabel?: (value: any, locale?: string) => ReactNode;
    segmented?: boolean;
    tag?: string;
    variantId?: number;
    withImport?: boolean;
    withTime?: boolean;
    workspaceId?: number | null;
};

export default AbstractInput;
