import apiSchema from '@config/schema';
import { schema, baseUrl } from './config';
import { formatForApi } from '@utils/dates';

export const TYPES = {
    DUPLICATE: 'DUPLICATE_WORKSPACE',
    DUPLICATE_SUCCESS: 'DUPLICATE_WORKSPACE_SUCCESS',
    DUPLICATE_FAILURE: 'DUPLICATE_WORKSPACE_FAILURE',
};

export default values => ({
    types: [TYPES.DUPLICATE, TYPES.DUPLICATE_SUCCESS, TYPES.DUPLICATE_FAILURE],
    schema: apiSchema.LIST(schema),
    payload: {
        request: {
            url: `${baseUrl}/${values.id}/duplicate`,
            method: 'POST',
            data: {
                ...values,
                beginOn: formatForApi(values.beginOn),
                finishOn: formatForApi(values.finishOn),
            },
        },
    },
});
