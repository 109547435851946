import { createActionCreator, withNoSite, withNoBrand, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = createActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (data, workspaces) =>
    withRequestParams({
        workspaces,
    })(withNoSite(withNoBrand(actionCreator(data))));
