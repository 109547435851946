import { addOnlyTrueFilter } from '@utils/filters';

export const schema = 'salestax';
export const name = 'SALESTAX';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/salestax`;

interface Filters {
    country?: string;
    salesTaxOctobre?: boolean;
    salesTaxSezane?: boolean;
}

export const getFilterParams = (filters: Filters) => {
    const { salesTaxOctobre = false, salesTaxSezane = false, ...otherFilters } = filters || {};
    let filterParams = { ...otherFilters };

    addOnlyTrueFilter('salesTaxOctobre', salesTaxOctobre, filterParams);
    addOnlyTrueFilter('salesTaxSezane', salesTaxSezane, filterParams);

    return filterParams;
};
