import { schema } from '@actions/oms/userTags/config';
import { TYPES as CREATE_TYPES } from '@actions/oms/userTags/create';
import { TYPES as DELETE_TYPES } from '@actions/oms/userTags/delete';
import { TYPES as LIST_TYPES } from '@actions/oms/userTags/list';
import { TYPES as UPDATE_TYPES } from '@actions/oms/userTags/update';
import { CRUD_INITIAL_STATE, createReducer, listReducer, updateReducer, reduceReducers, deleteReducer } from '../crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    createReducer(CREATE_TYPES, schema),
    deleteReducer(DELETE_TYPES),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema)
);
