import React, { CSSProperties } from 'react';
import useResizeObserver from 'use-resize-observer';

const IFRAME_PREVIEW_SIZE = 2000;
const ZOOM = 0.7;

export default function Iframe({ src }: { src: string }) {
    const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
    const scaling = width / IFRAME_PREVIEW_SIZE / ZOOM;
    const style: CSSProperties = {
        overflow: 'hidden',
        // pointerEvents: 'none',
        touchAction: 'none',
        width: `${IFRAME_PREVIEW_SIZE}px`,
        height: `${IFRAME_PREVIEW_SIZE}px`,
        border: '0px',
        zoom: ZOOM,
        // @ts-expect-error
        MozTransform: `scale(${scaling})`,
        MozTransformOrigin: '0 0',
        OTransform: `scale(${scaling})`,
        OTransformOrigin: '0 0',
        WebkitTransform: `scale(${scaling})`,
        WebkitTransformOrigin: '0 0',
    };

    return (
        <div ref={ref} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <iframe src={src} referrerPolicy="no-referrer" style={style} title="Site Sézane" scrolling="no" />
        </div>
    );
}

export const PreviewSezane = () => <Iframe src="https://www.sezane.com/fr" />;
export const PreviewOctobre = () => <Iframe src="https://www.octobre-editions.com/fr" />;
