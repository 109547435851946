import { ID_PLACEHOLDER, listActionCreator } from '../../crud';
import { baseUrl, name, schema } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (id: number) => {
    let action = actionCreator();
    // This is a list request but with a specific id so we cheat a little
    action.payload.request.url = action.payload.request.url.replace(ID_PLACEHOLDER, id);

    return action;
};
