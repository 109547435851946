import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import axios from './axios';
import crashReporter from './crashReporter';
import stateUrlRehydrate from './stateUrlRehydrate';
import apiDataFixer from './apiDataFixer';
import userPreferencesMiddleware from './userPreferences';

export default () =>
    applyMiddleware(thunk, axios, crashReporter, stateUrlRehydrate, apiDataFixer, userPreferencesMiddleware);
