import React from 'react';

import { changeLanguage } from '@actions/ui';
import { useAppDispatch, useAppSelector } from '@utils/hooks/redux';
import { SUPPORTED_LANGAGES } from '@utils/intl';

export const HeaderLangSwitcher = () => {
    const { language = 'fr' } = useAppSelector(state => state.ui);
    const dispatch = useAppDispatch();

    return (
        <div className="u-d(inline-block) u-c(white) u-pl(sm) u-pr(sm) u-mt(sm)">
            {SUPPORTED_LANGAGES.map(lang => {
                let intlLanguage = new Intl.DisplayNames([language || 'fr'], { type: 'language' }).of(lang) || lang;
                intlLanguage = intlLanguage.charAt(0).toUpperCase() + intlLanguage.slice(1);

                return (
                    <button
                        key={lang}
                        type="button"
                        className="c-button c-button--unstyled u-c(white) u-p(xxs)"
                        onClick={() => dispatch(changeLanguage(lang))}
                        style={{ opacity: lang === language ? 1 : 0.6 }}
                    >
                        {intlLanguage}
                    </button>
                );
            })}
        </div>
    );
};

export default HeaderLangSwitcher;
