import useEventListener from '@utils/hooks/useEventListener';
import { useState } from 'react';

const CMD = 'Meta';
const CTRL = 'Control';

export const SUBMIT_KEYS = {
    firstKeys: [CMD, CTRL],
    secondKey: 'Enter',
};
export const EDIT_KEYS = {
    firstKeys: [CMD, CTRL],
    secondKey: 'e',
};
export const NAVIGATION_KEYS = {
    up: 'ArrowUp',
    right: 'ArrowRight',
    down: 'ArrowDown',
    left: 'ArrowLeft',
};
export const COPY_KEYS = {
    firstKeys: [CMD, CTRL],
    secondKey: 'c',
};
export const PASTE_KEYS = {
    firstKeys: [CMD, CTRL],
    secondKey: 'v',
};
export const NEXT_LOCALE_KEYS = {
    firstKeys: [CMD, CTRL],
    secondKey: 'l',
};

export const setKeyControlledAction = (callback, keys, isDisabled = false) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isFirstKeyPressed, setIsFirstKeyPressed] = useState(false);

    const handlerDown = event => {
        if (isDisabled) {
            return;
        }

        const key = event.key;
        if (!isFirstKeyPressed && keys.firstKeys.includes(key)) {
            setIsFirstKeyPressed(true);
            return;
        }
        if (isFirstKeyPressed) {
            if (keys.secondKey === key) {
                if (keys !== COPY_KEYS && keys !== PASTE_KEYS) {
                    event.preventDefault();
                }
                callback();
            }
        }
    };

    const handlerUp = ({ key }) => {
        if (isDisabled) {
            return;
        }
        if (keys.firstKeys.includes(key)) {
            setIsFirstKeyPressed(false);
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener('keydown', handlerDown);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener('keyup', handlerUp);
};
