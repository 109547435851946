import { schema } from '@actions/customisationServices/config';
import { TYPES as LIST_TYPES } from '@actions/customisationServices/list';
import { TYPES as CREATE_TYPES } from '@actions/customisationServices/create';
import { TYPES as UPDATE_TYPES } from '@actions/customisationServices/update';
import { TYPES as DELETE_TYPES } from '@actions/customisationServices/delete';
import { TYPES as PATCH_TYPES } from '@actions/customisationServices/patch';
import { TYPES as GET_TYPES } from '@actions/customisationServices/get';
import {
    CRUD_INITIAL_STATE,
    listReducer,
    createReducer,
    updateReducer,
    deleteReducer,
    patchReducer,
    reduceReducers,
    getReducer,
} from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    createReducer(CREATE_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    deleteReducer(DELETE_TYPES, schema),
    patchReducer(PATCH_TYPES, schema),
    getReducer(GET_TYPES, schema)
);
