import { name, baseUrl, schema } from './config';
import apiSchema from '@config/schema';

export const TYPES = {
    MARK_AS_TREATED: `MARK_${name}`,
    MARK_AS_TREATED_SUCCESS: `MARK_${name}_AS_TREATED_SUCCESS`,
    MARK_AS_TREATED_FAILURE: `MARK_${name}_AS_TREATED_FAILURE`,
};

export default ids => ({
    types: [TYPES.MARK_AS_TREATED, TYPES.MARK_AS_TREATED_SUCCESS, TYPES.MARK_AS_TREATED_FAILURE],
    schema: apiSchema.LIST(schema),
    payload: {
        request: {
            url: `${baseUrl}/mark-as-treated`,
            method: 'PUT',
            params: { ids },
        },
    },
});
