import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Case, Col, Grid } from 'sezane-components';

import routesNames from '@config/routesNames';

const QuickAccess = () => (
    <Case size="sm" color="primary" style={{ height: '100%' }}>
        <h2 className="u-pt(xs) u-pb(xs) u-h3 u-tt(uppercase) u-fw(bolder) u-c(info)">
            <FormattedMessage id="dashboard.quick_access.title" />
        </h2>
        <Grid className="u-mt(base)">
            <Col number="5">
                <h3 className="u-fz(sm) u-tt(uppercase) u-fw(bolder)">
                    <FormattedMessage id="dashboard.quick_access.catalog" />
                </h3>
                <Button
                    color="ghost"
                    size="sm"
                    className="u-mt(xs) u-w(100)"
                    icon="tshirt"
                    linkTo={routesNames.LIST_PRODUCTS}
                >
                    <FormattedMessage id="dashboard.quick_access.products" />
                </Button>
                <Button
                    color="ghost"
                    size="sm"
                    className="u-mt(xs) u-w(100)"
                    icon="sticky-note"
                    linkTo={`${routesNames.LIST_PAGES}?isTree=true&enabled=true&itemsPerPage=150`}
                >
                    <FormattedMessage id="dashboard.quick_access.pages" />
                </Button>
                <Button
                    color="ghost"
                    size="sm"
                    className="u-mt(xs) u-w(100)"
                    icon="images"
                    linkTo={routesNames.MEDIA_LIBRARY}
                >
                    <FormattedMessage id="dashboard.quick_access.medias" />
                </Button>
                <Button
                    color="ghost"
                    size="sm"
                    className="u-mt(xs) u-w(100)"
                    icon="compass"
                    linkTo={routesNames.MAIN_MENU}
                >
                    <FormattedMessage id="dashboard.quick_access.menu" />
                </Button>
            </Col>
            <Col number="5" push="1">
                <h3 className="u-fz(sm) u-tt(uppercase) u-fw(bolder)">
                    <FormattedMessage id="dashboard.quick_access.tools" />
                </h3>
                <a
                    href="https://client.nibelis.com/accueil/"
                    target="_blank"
                    rel="noreferrer"
                    className="c-button c-button--ghost c-button--sm u-mt(xs) u-w(100)"
                >
                    <FormattedMessage id="dashboard.quick_access.nibelis" />
                </a>
                <a
                    href="https://erp.sezane.com/dashboard"
                    target="_blank"
                    rel="noreferrer"
                    className="c-button c-button--ghost c-button--sm u-mt(xs) u-w(100)"
                >
                    <FormattedMessage id="dashboard.quick_access.erp" />
                </a>
            </Col>
        </Grid>
    </Case>
);

export default QuickAccess;
