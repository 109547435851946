import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "stockView.List" */ '@containers/stockView/List'));
const Search = lazy(() => import(/* webpackChunkName: "stockView.Search" */ '@containers/stockView/Search'));

export default [
    <Route path={routesNames.STOCK_VIEW} component={List} exact key="list" />,
    <Route path={routesNames.STOCK_VIEW_SEARCH} component={Search} exact key="search" />,
    <Route
        path={routesNames.STOCK_SHOP_VIEW}
        component={() => <Search withShopStock={true} />}
        exact
        key="shop-search"
    />,
];
