export const schema = 'ecards';
export const name = 'ECARDS';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/ecard`;

export type EcardFilters = {
    brands?: string[];
    createdAtAfter?: string;
    createdAtBefore?: string;
    currencyCode?: string[];
    expiredAtAfter?: string;
    expiredAtBefore?: string;
    sendAtAfter?: string;
    sendAtBefore?: string;
    status?: string[];
};

export const getFilterParams = (filters: EcardFilters = {}) => {
    const filterParams: Record<string, any> = { ...filters };

    if (filters.currencyCode) {
        filterParams.currencyCode = filters.currencyCode.map(code => code.toLowerCase());
    }

    if (filters.createdAtAfter) {
        filterParams['createdAt[after]'] = filters.createdAtAfter;
    }
    if (filters.createdAtBefore) {
        filterParams['createdAt[before]'] = filters.createdAtBefore;
    }

    if (filters.expiredAtAfter) {
        filterParams['expiredAt[after]'] = filters.expiredAtAfter;
    }
    if (filters.expiredAtBefore) {
        filterParams['expiredAt[before]'] = filters.expiredAtBefore;
    }

    if (filters.sendAtAfter) {
        filterParams['sendAt[after]'] = filters.sendAtAfter;
    }
    if (filters.sendAtBefore) {
        filterParams['sendAt[before]'] = filters.sendAtBefore;
    }

    return filterParams;
};
