import { Menu } from 'sezane-components';
import routesNames, { PAGES_DEFAULT_SEARCH } from './routesNames';

const config: Menu = [
    {
        title: 'sidebar.home',
        icon: 'home',
        route: routesNames.DASHBOARD,
    },
    {
        title: 'sidebar.catalog',
        icon: 'tshirt',
        childrens: [
            {
                title: 'sidebar.sales',
                route: routesNames.LIST_SALES,
                search: '?page=1&sort%5Bfield%5D=updatedAt&sort%5Bdirection%5D=DESC',
            },
            {
                title: 'sidebar.products',
                route: routesNames.LIST_PRODUCTS,
            },
            {
                title: 'sidebar.multi_products',
                route: routesNames.LIST_MULTI_PRODUCTS,
            },
            {
                title: 'sidebar.preorders',
                route: routesNames.LIST_PREORDERS,
                search: '?hidePastPreorders=true',
            },
            {
                title: 'sidebar.customisation_services',
                route: routesNames.LIST_CUSTOMISATION_SERVICES,
                search: '?enabled=true&page=1',
            },
            {
                title: 'sidebar.sequencings',
                route: routesNames.LIST_SEQUENCINGS,
            },
        ],
    },
    {
        title: 'sidebar.content',
        icon: 'sticky-note',
        childrens: [
            {
                title: 'sidebar.page_versions',
                route: routesNames.LIST_PAGES,
                search: PAGES_DEFAULT_SEARCH,
            },
            {
                title: 'sidebar.media_library',
                route: routesNames.MEDIA_LIBRARY,
            },
            {
                title: 'sidebar.popins',
                route: routesNames.LIST_POPINS,
                search: '?onlyActive=true&page=1&sort%5Bfield%5D=active&sort%5Bdirection%5D=DESC',
            },
            {
                title: 'sidebar.mobile_content',
                route: routesNames.LIST_MOBILE_CONTENTS,
            },
            {
                title: 'sidebar.evergreen_medias',
                route: routesNames.LIST_EVERGREEN_MEDIAS,
            },
            {
                title: 'sidebar.push_products',
                route: routesNames.LIST_PUSH_PRODUCTS,
                search: '?onlyActive=true&page=1&sort%5Bfield%5D=active&sort%5Bdirection%5D=DESC',
            },
            {
                title: 'sidebar.workspaces',
                route: routesNames.LIST_WORKSPACES,
            },
        ],
    },
    {
        title: 'sidebar.navigation',
        icon: 'compass',
        childrens: [
            {
                title: 'sidebar.menu',
                route: routesNames.MAIN_MENU,
            },
            {
                title: 'sidebar.footer',
                route: routesNames.FOOTER,
            },
            {
                title: 'sidebar.mandatory_login',
                route: routesNames.UPDATE_MANDATORY_LOGIN,
            },
        ],
    },
    {
        title: 'sidebar.orders',
        icon: 'truck',
        childrens: [
            {
                title: 'sidebar.carts',
                route: routesNames.LIST_CARTS,
            },
            {
                title: 'sidebar.orders',
                route: routesNames.LIST_ORDERS,
                search: '?noSite=true',
            },
            {
                title: 'sidebar.returns',
                route: routesNames.LIST_RETURNS,
            },
            {
                title: 'sidebar.user_credit',
                route: routesNames.LIST_USER_CREDITS,
                search: '?enabled=true&page=1&sort%5Bfield%5D=createdAt&sort%5Bdirection%5D=DESC&status%5B0%5D=actif',
            },
            {
                title: 'sidebar.commercial_operations',
                route: routesNames.LIST_COMMERCIAL_OPERATIONS,
                search: '?enabled=true',
            },
            {
                title: 'sidebar.voucher_campaigns',
                route: routesNames.LIST_VOUCHER_CAMPAIGNS,
                search: '?enabled=true&page=1&sort%5Bfield%5D=createdAt&sort%5Bdirection%5D=DESC',
            },
            {
                title: 'sidebar.ecards',
                route: routesNames.LIST_ECARDS,
                search: '?page=1',
            },
            {
                title: 'sidebar.sales_statistics',
                route: routesNames.LIST_SALES_STATISTICS,
            },
        ],
    },
    {
        title: 'sidebar.clients',
        icon: 'user',
        childrens: [
            {
                title: 'sidebar.clients',
                route: routesNames.LIST_USERS,
            },
            {
                title: 'sidebar.newsletter_subscribers',
                route: routesNames.LIST_NEWSLETTER_SUBSCRIBERS,
                search: '?page=1',
            },
            {
                title: 'sidebar.stock_alerts',
                route: routesNames.LIST_STOCK_ALERTS,
                search: '?hasFuture=true&page=1',
            },
        ],
    },
    {
        title: 'sidebar.stock',
        icon: 'database',
        childrens: [
            {
                title: 'sidebar.stock_view_search',
                route: routesNames.STOCK_VIEW_SEARCH,
            },
            {
                title: 'sidebar.stock_view',
                route: routesNames.STOCK_VIEW,
            },
            {
                title: 'sidebar.scheduled_updates',
                route: routesNames.LIST_SCHEDULED_UPDATES,
                search: '?page=1',
            },
            {
                title: 'sidebar.stock_shop_search',
                route: routesNames.STOCK_SHOP_VIEW,
            },
        ],
    },
    {
        title: 'sidebar.retail',
        icon: 'store',
        childrens: [
            {
                title: 'sidebar.retail_sales',
                link: window.REACT_APP_BO_RETAIL,
                // @ts-expect-error: Update components types to allow custom accessRight for external links
                accessRight: 'ROLE_RETAIL_SALES_READ',
            },
            {
                title: 'sidebar.shops',
                route: routesNames.LIST_SHOPS,
            },
        ],
    },
    {
        title: 'sidebar.logistic',
        icon: 'warehouse',
        childrens: [
            {
                title: 'sidebar.packagings',
                route: routesNames.LIST_PACKAGINGS,
            },
            {
                title: 'sidebar.shipping_modes',
                route: routesNames.LIST_SHIPPING_MODES,
                search: '?enabled=true&page=1',
            },
            {
                title: 'sidebar.order_return_modes',
                route: routesNames.LIST_ORDER_RETURN_MODES,
                search: '?enabled=true&page=1',
            },
            {
                title: 'sidebar.logistic_files',
                route: routesNames.LOGISTIC_FILES,
            },
            {
                title: 'sidebar.warehouses',
                route: routesNames.LIST_WAREHOUSES,
            },
        ],
    },
    {
        title: 'sidebar.administration',
        icon: 'tools',
        childrens: [
            {
                title: 'sidebar.log_reports',
                route: routesNames.LIST_LOG_REPORTS,
                notifications: 'logReports',
            },
            {
                title: 'sidebar.roles',
                route: routesNames.LIST_ROLES,
            },
            {
                title: 'sidebar.sites',
                route: routesNames.LIST_SITES,
            },
            {
                title: 'sidebar.constants',
                route: routesNames.LIST_PARAMETERS,
            },
            {
                title: 'sidebar.crons',
                route: routesNames.LIST_CRONS,
            },
            {
                title: 'sidebar.user_tags',
                route: routesNames.LIST_USER_TAGS,
            },
            {
                title: 'sidebar.color_parameters',
                route: routesNames.LIST_COLOR_PARAMETERS,
            },
            {
                title: 'sidebar.vat',
                route: routesNames.LIST_VAT,
            },
            {
                title: 'sidebar.sales_tax',
                route: routesNames.LIST_SALES_TAX,
                search: '?page=1&itemsPerPage=999',
            },
            {
                title: 'sidebar.email_templates',
                route: routesNames.LIST_EMAIL_TEMPLATES,
            },
        ],
    },
];

export default config;
