import { addOnlyTrueFilter } from '@utils/filters';

export const schema = 'scheduledUpdates';
export const name = 'SCHEDULED_UPDATES';
export const baseUrl = `${window.REACT_APP_STOCK_API_URL}/stock-private/v1/scheduled-update`;

export interface ScheduledUpdatesFilters {
    showRecurring?: boolean;
    showTerminated?: boolean;
}

export const getFilterParams = (filters: ScheduledUpdatesFilters) => {
    const { showRecurring = false, showTerminated = false, ...otherFilters } = filters || {};
    let filterParams = { ...otherFilters };

    addOnlyTrueFilter('is_recurring', showRecurring, filterParams);
    addOnlyTrueFilter('show_terminated', showTerminated, filterParams);

    return filterParams;
};
