import React from 'react';
import difference from 'lodash/difference';
import get from 'lodash/get';
import set from 'lodash/set';
import { SubmissionError } from 'redux-form';

import getNotifier, { DANGER } from '@config/notifier';

/**
 * Get missing translations from a form
 * Used to display an invalid translation state
 *
 * @param {object|array} allTranslations
 * @param {array} availableLocales
 */
export const getMissingTranslations = (
    allTranslations: Record<string, Record<string, any>>[] | {} | undefined,
    availableLocales: string[],
    nullableData: boolean = false
): string[] => {
    const defaultLocale = 'fr';
    const localesToTranslate = availableLocales.filter(locale => locale !== defaultLocale);

    if (!allTranslations) {
        return [];
    }

    if (!Array.isArray(allTranslations)) {
        return getMissingTranslations([allTranslations], availableLocales, nullableData);
    }

    const missing: string[][] = [];
    allTranslations.forEach(translations => {
        const allLocalesValues = Object.keys(translations);
        const differences = difference(localesToTranslate, allLocalesValues);

        if (differences.length > 0) {
            missing.push(differences);
        } else if (translations[defaultLocale]) {
            const defaultLocaleProperties = Object.keys(translations[defaultLocale]).filter(key => key !== 'id');
            const localesWithoutTranslations: Record<string, any> = {};

            localesToTranslate.forEach(locale => {
                let localeToCompareProperties = Object.keys(translations[locale]);
                // Compare properties
                if (nullableData) {
                    localeToCompareProperties = localeToCompareProperties.filter(
                        property => !!translations[locale][property]
                    );
                }
                const localePropertiesDifference = difference(defaultLocaleProperties, localeToCompareProperties);

                if (localePropertiesDifference.length > 0) {
                    localesWithoutTranslations[locale] = true;
                }

                for (const [key, value] of Object.entries(translations[locale])) {
                    if (
                        (value === null || value === '') &&
                        translations[defaultLocale][key] !== null &&
                        translations[defaultLocale][key] !== ''
                    ) {
                        localesWithoutTranslations[locale] = true;
                    }
                }
            });

            missing.push(Object.keys(localesWithoutTranslations));
        }
    });

    return ([] as string[]).concat(...missing);
};

export const handleFormErrorsOutsideContext = (e: Error) => {
    if (e instanceof SubmissionError) {
        console.error('Validation error', Object.values(e.errors));

        // Display validation errors since redux form is not handling them
        if (e.errors) {
            const Notifier = getNotifier();
            Object.values(e.errors).forEach(({ message }) => {
                Notifier.notify(DANGER, 'validation.' + message);
            });
        }
    }
};

export const formatLocaleValues = (initialValues: Record<string, any>, valuesKey: string = 'translations') => {
    const values = { ...initialValues };
    if (!values[valuesKey] || (Array.isArray(values[valuesKey]) && values[valuesKey].length === 0)) {
        values[valuesKey] = {};
    }
    return values;
};

// Enhance parameters returned from the API (usually violations parameters)
export const enhanceTranslationValues = (values?: Record<string, React.ReactNode>) => {
    const enhanceValues = values;

    // Some validation error should return "link" & "href" values
    if (enhanceValues && enhanceValues.link && typeof enhanceValues.href === 'string') {
        enhanceValues.link = <a href={enhanceValues.href}>{enhanceValues.link}</a>;
    }

    // Automatic violation parameters from Symfony follow the format {{ parameterName }}
    if (enhanceValues) {
        Object.entries(enhanceValues).forEach(([key, value]) => {
            if (key.startsWith('{{ ')) {
                const newKey = key.replace('{{ ', '').replace(' }}', '');
                enhanceValues[newKey] = value;
            }
        });
    }

    return enhanceValues;
};

export const addTranslationsLocale = (item: any, translationPaths = ['translations']) => {
    const newItem = { ...item };

    translationPaths.forEach(translationPath => {
        const translations: Record<string, any> | undefined = get(item, translationPath);

        if (translations) {
            const newTranslations = { ...translations };

            Object.keys(newTranslations).forEach(key => {
                newTranslations[key] = { ...newTranslations[key], locale: key };
            });

            set(newItem, translationPath, newTranslations);
        }
    });

    return newItem;
};
