import { getActionCreator, ID_PLACEHOLDER, withRequestParams, withWorkspace } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = getActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}`);
export const TYPES = types;

export default (id, workspaceId, catalogVariantId) => {
    return withRequestParams({
        catalogVariantId,
    })(withWorkspace(workspaceId)(actionCreator(id)));
};
