import { listActionCreator, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl, getFilterParams } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (query = null, filters = null, itemsPerPage = null, page = 1, save = true) => {
    const filterParams = getFilterParams(filters);
    const noSite = !!filterParams.brand || (filters && filters.noSite) || (filters && filters.site) || undefined;
    const pagination = !!itemsPerPage;

    return withValues({
        query,
        pagination,
        itemsPerPage: itemsPerPage,
        noSite: noSite,
        noBrand: !!filterParams.brand,
        save,
    })(
        withRequestParams({
            label: query || undefined,
            itemsPerPage: itemsPerPage || undefined,
            pagination,
            page: !!page && pagination ? page : undefined,
            ...filterParams,
        })(actionCreator())
    );
};
