import { listActionCreator, withNoBrand, withNoSite, withRequestParams } from '@actions/crud';
import { baseUrl } from './config';

export type UpdateModes = 'Automatic' | 'Blocked' | 'Forced';

export const TYPES = {
    LIST: 'LIST_SCHEDULED_UPDATE_EANS',
    LIST_SUCCESS: 'LIST_SCHEDULED_UPDATE_EANS_SUCCESS',
    LIST_FAILURE: 'LIST_SCHEDULED_UPDATE_EANS_FAILURE',
};

export default (scheduledUpdateId: number, updateMode: UpdateModes) => () => {
    const { actionCreator } = listActionCreator(
        'SCHEDULED_UPDATE_EANS',
        'scheduledUpdateEans',
        `${baseUrl}/${scheduledUpdateId}/eans`
    );

    return withRequestParams({
        update_mode: updateMode,
    })(withNoBrand(withNoSite(actionCreator())));
};
