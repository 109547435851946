import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import carts from './carts';
import catalogItems from './catalogItems';
import catalogProducts from './catalogProducts';
import catalogVariants from './catalogVariants';
import colorParameters from './colorParameters';
import compositionLayouts from './compositionLayouts';
import compositions from './compositions';
import compositionTemplates from './compositionTemplates';
import countries from './countries';
import crons from './crons';
import customisationServices from './customisationServices';
import ecards from './ecards';
import emailTemplates from './oms/emailTemplates';
import entityHistories from './entityHistories';
import evergreenMedias from './evergreenMedias';
import footerItems from './footerItems';
import jobs from './jobs';
import languages from './languages';
import logReports from './logReports';
import medias from './medias';
import memos from './memos';
import menuItems from './menuItems';
import mobileContents from './mobileContents';
import models from './models';
import notifications from './notifications';
import newsletterSubscribers from './oms/newsletterSubscribers';
import packagings from './oms/packagings';
import omsReferences from './oms/references';
import orderReturnModes from './oms/orderReturnModes';
import salesTax from './oms/salesTax';
import shops from './oms/shops';
import userTags from './oms/userTags';
import vat from './oms/vat';
import orders from './oms/orders';
import pages from './pages';
import pageVersions from './pageVersions';
import parameters from './parameters';
import parametersOms from './oms/parameters';
import popins from './popins';
import preFooters from './preFooters';
import pushProducts from './pushProducts';
import queue from './queue';
import references from './references';
import retailSales from './retailSales';
import roles from './roles';
import sales from './sales';
import saleVersions from './saleVersions';
import saleWaitingPages from './saleWaitingPages';
import scheduledUpdateEans from './scheduledUpdateEans';
import scheduledUpdates from './scheduledUpdates';
import search from './search';
import shippingModes from './oms/shippingModes';
import shippingModeReturnModes from './oms/shippingModeReturnModes';
import shippingZones from './shippingZones';
import sites from './sites';
import sizeAdvice from './sizeAdvice';
import stockAlerts from './stockAlerts';
import stockView from './stockView';
import stockViewSearch from './stockViewSearch';
import storeMultiProducts from './storeMultiProducts';
import storeVariants from './storeVariants';
import typologies from './typologies';
import ui from './ui';
import userCredits from './userCredits';
import users from './users';
import voucherCampaigns from './oms/voucherCampaigns';
import vouchers from './oms/vouchers';
import workspaces from './workspaces';
import form from './form';

const persistConfig = (key: string, blacklist = ['loading'], whitelist?: string[]) => ({
    key,
    storage: storage,
    blacklist,
    whitelist,
});

const reducers = combineReducers({
    auth: persistReducer(persistConfig('auth'), auth),
    carts,
    catalogItems,
    catalogProducts: persistReducer(
        persistConfig('catalogProducts', undefined, ['editionConfiguration']),
        catalogProducts
    ),
    catalogVariants,
    colorParameters,
    compositionLayouts,
    compositions,
    compositionTemplates,
    countries,
    crons,
    customisationServices,
    entityHistories,
    evergreenMedias,
    footerItems,
    form,
    jobs,
    languages,
    logReports,
    medias: persistReducer(persistConfig('medias', undefined, ['selectionFilters']), medias),
    memos,
    menuItems,
    mobileContents,
    models,
    notifications,
    orders: persistReducer(persistConfig('orders', undefined, ['listConfiguration', 'listView']), orders),
    pages,
    pageVersions,
    parameters,
    popins,
    preFooters,
    pushProducts,
    queue,
    references,
    retailSales,
    roles,
    sales,
    saleVersions,
    saleWaitingPages,
    scheduledUpdates,
    scheduledUpdateEans,
    search,
    shippingZones,
    sites,
    sizeAdvice,
    stockAlerts,
    stockView: persistReducer(persistConfig('stockView'), stockView),
    stockViewSearch,
    storeMultiProducts,
    storeVariants: persistReducer(
        persistConfig('storeVariants', undefined, ['listConfiguration', 'listView']),
        storeVariants
    ),
    typologies,
    ui,
    users,
    workspaces: persistReducer(persistConfig('workspaces'), workspaces),
    oms: combineReducers({
        ecards,
        emailTemplates,
        newsletterSubscribers,
        orderReturnModes,
        packagings,
        parametersOms,
        references: persistReducer(persistConfig('references'), omsReferences),
        salesTax,
        shippingModes,
        shippingModeReturnModes,
        shops,
        userCredits,
        userTags,
        vat,
        voucherCampaigns,
        vouchers,
    }),
});

export type RootState = ReturnType<typeof reducers>;

type ReducerKey1 = keyof RootState;
type ReducerKey2 = `oms.${string & keyof RootState['oms']}`;
export type ReducerKey = ReducerKey1 | ReducerKey2;

export default reducers;
