import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "returns.List" */ '@containers/returns/List'));
const Show = lazy(() => import(/* webpackChunkName: "returns.Show" */ '@containers/returns/Show'));

export default [
    <Route path={routesNames.SHOW_RETURN} component={Show} exact={true} key="show" />,
    <Route path={routesNames.LIST_RETURNS} component={List} strict={true} key="list" />,
];
