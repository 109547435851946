import { schema } from '@actions/compositionLayouts/config';
import { TYPES as GET_TYPES } from '@actions/compositionLayouts/get';
import { TYPES as LIST_TYPES } from '@actions/compositionLayouts/list';
import { TYPES as UPDATE_TYPES } from '@actions/compositionLayouts/update';
import { TYPES as CREATE_TYPES } from '@actions/compositionLayouts/create';
import { CRUD_INITIAL_STATE, getReducer, listReducer, reduceReducers, updateReducer, createReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    createReducer(CREATE_TYPES, schema)
);
