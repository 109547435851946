import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Show = lazy(() => import(/* webpackChunkName: "sequencings.Show" */ '@containers/sequencings/Show'));
const List = lazy(() => import(/* webpackChunkName: "sequencings.List" */ '@containers/sequencings/List'));

export default [
    <Route path={routesNames.SHOW_SEQUENCING} component={Show} strict={true} key="show" />,
    <Route path={routesNames.LIST_SEQUENCINGS} component={List} strict={true} key="list" />,
];
