import { addRangeFilter } from '@utils/filters';

export const schema = 'workspace';
export const name = 'WORKSPACE';
export const baseUrl = 'ecommerce-private/v1/workspaces';

export const getFilterParams = filters => {
    let filterParams = {};

    if (!!filters) {
        const { beginBefore, beginAfter, finishBefore, finishAfter, status, catalogProduct, ...otherFilters } = filters;

        filterParams = { ...otherFilters };

        addRangeFilter('beginOn', beginAfter, beginBefore, filterParams);
        addRangeFilter('finishOn', finishAfter, finishBefore, filterParams);

        filterParams.status = status || undefined;
        filterParams.catalogProductId = catalogProduct || undefined;
    }

    return filterParams;
};
