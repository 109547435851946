import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "scheduledUpdates.List" */ '@containers/scheduledUpdates/List'));
const Create = lazy(
    () => import(/* webpackChunkName: "scheduledUpdates.Create" */ '@containers/scheduledUpdates/Create')
);
const Edit = lazy(() => import(/* webpackChunkName: "scheduledUpdates.Edit" */ '@containers/scheduledUpdates/Edit'));

export default [
    <Route path={routesNames.LIST_SCHEDULED_UPDATES} component={List} exact key="list" />,
    <Route path={routesNames.CREATE_SCHEDULED_UPDATE} component={Create} exact key="create" />,
    <Route path={routesNames.EDIT_SCHEDULED_UPDATE} component={Edit} exact key="edit" />,
];
