import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Header as SezaneHeader } from 'sezane-components';

import BrandSwitcher from './BrandSwitcher';
import HeaderLangSwitcher from './HeaderLangSwitcher';
import HeaderLocaleSwitcher from './HeaderLocaleSwitcher';
import Menu from './Menu';
import Profile from './Profile';
import SiteSwitcher from './SiteSwitcher';
import ThemeSwitcher from './ThemeSwitcher';
import ZoomSwitcher from './ZoomSwitcher';

const Header = () => {
    return (
        <SezaneHeader togglePosition="refonte">
            {collapsed => (
                <>
                    <BrandSwitcher collapsed={collapsed} />
                    {/* @ts-ignore */}
                    <SiteSwitcher collapsed={collapsed} />
                    <HeaderLocaleSwitcher collapsed={collapsed} />
                    <Menu />
                    {!collapsed && <HeaderLangSwitcher />}
                    {!collapsed && <ThemeSwitcher />}
                    {!collapsed && <ZoomSwitcher />}
                    <Profile />
                    {!collapsed && (
                        <div className="c-nav c-header__nav u-mt(md) u-pb(md) u-ta(center) u-fz(sm) u-c(gray-500)">
                            <FormattedMessage id="sidebar.version" />{' '}
                            {process.env.COMMIT_HASH && (
                                <Link
                                    href={`${process.env.REACT_APP_GITHUB_URL}/commit/${process.env.COMMIT_HASH}`}
                                    target="_blank"
                                >
                                    {process.env.COMMIT_HASH}
                                </Link>
                            )}
                        </div>
                    )}
                </>
            )}
        </SezaneHeader>
    );
};

export default Header;
