import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Link } from 'sezane-components';

import PageHead from '@components/PageHead';
import routesNames from '@config/routesNames';

const NotFound = () => (
    <div className="not-found u-pt(lg)">
        <PageHead title="not_found.title" />
        <div className="not-found__content">
            <h1 className="u-d(flex) u-ai(center)">
                <Icon icon="warning" size="sm" className="u-mr(sm)" />
                <FormattedMessage id="not_found.title" />
            </h1>
            <h3 className="not-found__info">
                <FormattedMessage id="not_found.text" />
            </h3>
            <div className="u-mt(md)">
                <Link className="c-link" to={routesNames.DASHBOARD}>
                    <FormattedMessage id="not_found.back" />
                </Link>
            </div>
        </div>
    </div>
);

export default NotFound;
