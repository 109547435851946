import placeholderDark from '@img/media/placeholder_dark.png';
// import placeholderLight from '@img/media/placeholder_light.png';

export type MediaFormats = 'small' | 'large' | 'cover' | 'medium' | 'video' | 'no_transformation' | 'preview';

export const formats: Record<MediaFormats, string> = {
    small: 'ADMIN_SMALL_THUMBNAILS',
    large: 'ADMIN_LARGE_THUMBNAILS',
    cover: 'ADMIN_COVER_THUMBNAILS',
    medium: 'ADMIN_MEDIUM_THUMBNAILS',
    video: 'VIDEO',
    no_transformation: 'ADMIN_NO_TRANSFORMATION',
    preview: 'TEMP_PREVIEW',
};

export const PLACEHOLDER = placeholderDark;
