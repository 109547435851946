import { Layouts } from '@containers/dashboard';
import { Brands, Locale, Site, UserConfigurationConfigurationWrite, Workspace } from '@types';
import { SupportedLocales } from '@utils/intl';
import { MenuItem, Theme } from 'sezane-components';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_SITE = 'CHANGE_SITE';
export const CHANGE_BRAND = 'CHANGE_BRAND';
export const CHANGE_WORKSPACE = 'CHANGE_WORKSPACE';
// Site locale
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SET_USER_CONFIGURATION = 'SET_USER_CONFIGURATION';
export const SET_MENU_FAVORITES = 'SET_MENU_FAVORITES';
export const SET_DASHBOARD = 'SET_DASHBOARD';

export const changeTheme = (theme: Theme) => ({
    type: CHANGE_THEME,
    theme,
});

export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR,
});

export const changeSite = (site: Site, workspace: Workspace) => ({
    type: CHANGE_SITE,
    site,
    workspace,
});

export const changeBrand = (brand: Brands) => ({
    type: CHANGE_BRAND,
    brand,
});

export const changeWorkspace = (workspace: Workspace) => ({
    type: CHANGE_WORKSPACE,
    workspace,
});

export const changeLocale = (locale: Locale) => ({
    type: CHANGE_LOCALE,
    locale,
});

export const changeLanguage = (language: SupportedLocales) => ({
    type: CHANGE_LANGUAGE,
    language,
});

export const setUserConfiguration = (userConfiguration: UserConfigurationConfigurationWrite) => ({
    type: SET_USER_CONFIGURATION,
    userConfiguration,
});

export const setMenuFavorites = (menuFavorites: MenuItem[]) => ({
    type: SET_MENU_FAVORITES,
    menuFavorites,
});

export const setDashboard = (dashboard: Layouts) => ({
    type: SET_DASHBOARD,
    dashboard,
});
