import { schema } from '@actions/storeMultiProducts/config';
import { TYPES as LIST_TYPES } from '@actions/storeMultiProducts/list';
import { TYPES as CREATE_TYPES } from '@actions/storeMultiProducts/create';
import { TYPES as UPDATE_TYPES } from '@actions/storeMultiProducts/update';
import { TYPES as DELETE_TYPES } from '@actions/storeMultiProducts/delete';
import { CRUD_INITIAL_STATE, reduceReducers, listReducer, createReducer, updateReducer, deleteReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    createReducer(CREATE_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    deleteReducer(DELETE_TYPES, schema)
);
