import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { RootState } from '@reducers';
import { References, Site } from '@types';

const TYPE_WEBSITE = 'website';
const TYPE_PHYSICAL = 'physical';

export const isWebsite = (site: References.Site | Site) => site.type === TYPE_WEBSITE;
export const isPhysical = (site: References.Site | Site) => site.type === TYPE_PHYSICAL;
export const filterWebsiteSites = (sites: References.Site[]) => sites.filter(isWebsite);
export const filterPhysicalSites = (sites: References.Site[]) => sites.filter(isPhysical);

const brandsSelector = (state: RootState) => state.references?.brands || [];
const uiBrandCodeSelector = (state: RootState, brandCode?: string | false) => {
    if (brandCode === false) return false;
    if (brandCode === '') return null;
    return brandCode || state.ui?.brand?.code;
};

// Temporary fix to get the correct brand code for sites (should be fixed in API)
const mapBrandSite =
    (brandCode: string) =>
    (site: References.Site): References.Site => ({ ...site, brand: brandCode });

export const sitesSelector = createSelector([brandsSelector, uiBrandCodeSelector], (brands, brandCode) => {
    if (brandCode === null) return [];
    if (brandCode) {
        const brand = brands.find(brand => brand.code === brandCode);
        return brand?.sites.map(mapBrandSite(brand.code)) || [];
    }
    return brands.reduce((acc: References.Site[], brand) => acc.concat(brand.sites.map(mapBrandSite(brand.code))), []);
});
export const websitesSelector = createSelector(sitesSelector, sites => {
    return filterWebsiteSites(sites);
});
export const physicalSitesSelector = createSelector(sitesSelector, sites => {
    return filterPhysicalSites(sites);
});
export const filterSitesWithWorkspaces = (sites: Array<References.Site | Site>) =>
    sites.filter(site => site.workspacesIds && site.workspacesIds.length > 0);
export const reorderWebsites = (sites: Site[], sitesOrdered: References.Site[]) => {
    const ids = sitesOrdered.map(site => site.id);
    return sortBy(sites, site => ids.indexOf(site.id!));
};
