import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(() => import(/* webpackChunkName: "packagings.Create" */ '@containers/packagings/Create'));
const List = lazy(() => import(/* webpackChunkName: "packagings.List" */ '@containers/packagings/List'));

export default [
    <Route path={routesNames.CREATE_PACKAGING} component={Create} exact={true} key="create" />,
    <Route path={routesNames.LIST_PACKAGINGS} component={List} exact={true} key="list" />,
];
