import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import { Icon, Separator } from 'sezane-components';

import LocaleTranslationStatus from '@components/status/LocaleTranslationStatus';
import { RootState } from '@reducers';
import { Locale, Site, TranslationStatusDic, Workspace } from '@types';
import { getMissingTranslations } from '@utils/forms';
import usePrevious from '@utils/hooks/usePrevious';
import { NEXT_LOCALE_KEYS, setKeyControlledAction } from '@utils/shortcuts';

export const getLocalesCodesFromSites = (sites: Site[]) => {
    let allCodes: string[] = [];
    if (sites && sites.length > 0) {
        sites.forEach(site => {
            // @ts-ignore
            allCodes = union(
                allCodes,
                site?.availableLocales?.map(locale => locale.code)
            );
        });
    }

    return allCodes.length > 0 ? allCodes : null;
};

export const getLocalesCodesFromWorkspaces = (workspaces: Workspace[]) => {
    let allSites: Site[] = [];
    if (workspaces && workspaces.length > 0) {
        workspaces.forEach(workspace => {
            if (workspace.site && !allSites.find(site => site.id === workspace.site?.id)) {
                allSites.push(workspace.site);
            }
        });
    }

    return getLocalesCodesFromSites(allSites);
};

type OwnProps = {
    form?: string;
    mandatoryLocales?: string[];
    getTranslations?: (values: any, mandatoryLocales?: string[]) => Record<string, Record<string, any>>[] | {};
    nullableData?: boolean;
    translationStatus?: TranslationStatusDic;
};

type LocaleSwitcherProps = OwnProps & {
    selected: string;
    onChange: (locale: string) => void;
    locales: Locale[];
    form?: string;
    missingTranslations: string[];
    className?: string;
    isInModal?: boolean;
    currentMandatoryLocales: string[];
    locale: Locale;
};

const LocaleSwitcher = ({
    selected,
    onChange,
    className,
    missingTranslations,
    isInModal = false,
    currentMandatoryLocales,
    locales,
    translationStatus,
    locale,
}: LocaleSwitcherProps) => {
    const availableLocales = locales.map(({ code }) => ({
        mandatory: currentMandatoryLocales.includes(code!),
        code: code!,
    }));
    const orderedAvailableLocales = sortBy(availableLocales, availableLocale => !availableLocale.mandatory);
    const previousLocale = usePrevious(locale);

    useEffect(() => {
        if (locale && previousLocale && locale.code !== previousLocale.code) {
            onChange(locale.code!);
        }
    }, [locale, previousLocale]);

    setKeyControlledAction(() => {
        const index = orderedAvailableLocales.findIndex(l => selected === l.code);
        if (index < 0) return;
        else if (index < orderedAvailableLocales.length - 1) {
            onChange(orderedAvailableLocales[index + 1].code);
        } else {
            onChange(orderedAvailableLocales[0].code);
        }
    }, NEXT_LOCALE_KEYS);

    if (availableLocales.length <= 1) {
        return null;
    }

    return (
        <div
            className={classNames({
                'c-language__wrapper': !isInModal,
            })}
        >
            <ul
                className={classNames(
                    'c-language',
                    {
                        'c-language--fixed': !isInModal,
                        'c-language--modal-fixed': isInModal,
                    },
                    className
                )}
            >
                <li className="u-mt(xs) u-mb(xs)">
                    <Icon icon="globe-europe" className="u-c(gray-600)" />
                </li>
                {orderedAvailableLocales.map((locale, index) => {
                    return (
                        <li
                            key={locale.code}
                            className={classNames('c-language__item u-w(100) u-d(flex) u-fxd(column)', {
                                'c-language__item--invalid': missingTranslations.includes(locale.code),
                            })}
                        >
                            <button
                                type="button"
                                onClick={() => onChange(locale.code)}
                                className={classNames('c-language__link u-fx(1)', {
                                    'c-language__link--is-active': locale.code === selected,
                                })}
                            >
                                {locale.code}
                                {translationStatus && translationStatus[locale.code] && (
                                    <LocaleTranslationStatus translationStatus={translationStatus[locale.code]} />
                                )}
                                {missingTranslations.includes(locale.code) &&
                                    !(translationStatus && translationStatus[locale.code]) && (
                                        <LocaleTranslationStatus translationStatus="missing_trad" />
                                    )}
                            </button>

                            {index === orderedAvailableLocales.filter(locale => locale.mandatory).length - 1 && (
                                <Separator margin="none" />
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const mapStateToProps = (state: RootState, { form, getTranslations, nullableData, translationStatus }: OwnProps) => {
    const currentMandatoryLocales = (
        state.ui.site.translationLocale
            ? [state.ui.site.translationLocale.code, state.ui.site.defaultLocale?.code]
            : [state.ui.site.defaultLocale?.code]
    ) as string[];
    let missingTranslations: string[] = [];
    let translationStatusValue: TranslationStatusDic | undefined = translationStatus;

    if (!!form) {
        const selector = formValueSelector(form);
        const values = getFormValues(form)(state);
        const translations = getTranslations
            ? getTranslations(values, currentMandatoryLocales)
            : selector(state, 'translations');
        missingTranslations = getMissingTranslations(translations, currentMandatoryLocales, nullableData);
        translationStatusValue = translationStatusValue || selector(state, 'translationLocalesStatus');
    }

    return {
        // @ts-ignore TODO Fix typings of reducers with redux-toolkit
        locales: state.references.locales as Locale[],
        missingTranslations,
        currentMandatoryLocales,
        translationStatus: translationStatusValue,
        locale: state.ui.locale,
    };
};

export default connect(mapStateToProps)(LocaleSwitcher);
