import { listActionCreator, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (onlyGlobal = false, query = null) =>
    withValues({
        query,
    })(
        withRequestParams({
            global: onlyGlobal ? 1 : undefined,
            name: query || undefined,
        })(actionCreator())
    );
