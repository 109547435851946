import { listActionCreator, withValues } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (save = true) =>
    withValues({
        save,
    })(actionCreator());
