import { TYPES } from '@actions/notifications';

export const INITIAL_STATE = {
    logReports: 0,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case TYPES.FETCH_SUCCESS: {
            const data = action.payload.rawData;

            return {
                ...state,
                ...data,
            };
        }

        default:
            return state;
    }
}
