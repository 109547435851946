import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import routesNames from '@config/routesNames';

const Create = lazy(() => import(/* webpackChunkName: "warehouses.Create" */ '@containers/warehouses/Create'));
const Update = lazy(() => import(/* webpackChunkName: "warehouses.Update" */ '@containers/warehouses/Update'));
const List = lazy(() => import(/* webpackChunkName: "warehouses.List" */ '@containers/warehouses/List'));

export default [
    <Route path={routesNames.CREATE_WAREHOUSE} component={Create} strict={true} key="create" />,
    <Route path={routesNames.UPDATE_WAREHOUSE} component={Update} strict={true} key="update" />,
    <Route path={routesNames.LIST_WAREHOUSES} component={List} strict={true} key="list" />,
];
