/* eslint-disable import/order */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThunkDispatch } from 'redux-thunk';
import { Loader } from 'sezane-components';
import '../node_modules/sezane-components/dist/bo-components.esm.css';

import initIconLibrary from '@config/iconLibrary';
import configureStore from '@config/store';
import './index.css';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { RootState } from '@reducers';
import App from './app/App';

const { store, persistor } = configureStore();

type AppAction = any; // this should not be any but since we use redux-axios-middleware that override the type property, we can't use ReturnType<typeof store.dispatch>
export type AppDispatch = ThunkDispatch<RootState, any, AppAction>;
export const reduxPersistor = persistor;

initIconLibrary();

const Root = () => (
    <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
            <Router>
                <App />
            </Router>
        </PersistGate>
    </Provider>
);

const root = createRoot(document.getElementById('root')!);
root.render(<Root />);
