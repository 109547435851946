import { schema } from '@actions/stockView/config';
import { TYPES as CONFIGURATION_TYPES } from '@actions/stockView/configuration';
import { TYPES as LIST_TYPES } from '@actions/stockView/list';
import { DEFAULT_FIELDS } from '@containers/stockView/fieldFormatters';
import { configurationReducer, CRUD_INITIAL_STATE, listReducer, reduceReducers } from './crud';

export const INITIAL_STATE = { ...CRUD_INITIAL_STATE, listConfiguration: DEFAULT_FIELDS };

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    configurationReducer(CONFIGURATION_TYPES),
    INITIAL_STATE
);
