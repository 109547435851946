import { listActionCreator, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (query = null) =>
    withValues({
        query,
    })(
        withRequestParams({
            displayName: query || undefined,
        })(actionCreator())
    );
