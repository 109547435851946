import { listActionCreator, withRequestParams } from '@actions/crud';
import { Sort } from 'sezane-components';
import { baseUrl, name, schema } from './config';

const { types, actionCreator } = listActionCreator(name, schema, `${baseUrl}/list-stocks`, 'ean');
export const TYPES = types;

export default (data: string[], sort?: Sort) => {
    const action = actionCreator();

    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    return withRequestParams({
        ...sortParam,
    })({
        ...action,
        payload: {
            ...action.payload,
            request: {
                ...action.payload.request,
                method: 'POST',
                data,
            },
        },
    });
};
