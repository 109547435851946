import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "users.List" */ '@containers/users/List'));
const Show = lazy(() => import(/* webpackChunkName: "users.Show" */ '@containers/users/show'));

export default [
    <Route path={routesNames.SHOW_USER} component={Show} exact={true} key="show" />,
    <Route path={routesNames.LIST_USERS} component={List} strict={true} key="list" />,
];
