import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import { normalize } from 'normalizr';
import qs from 'qs';
import { SubmissionError } from 'redux-form';
import set from 'lodash/set';
import { keycloakInstance } from 'sezane-components';

import getNotifier, { DANGER } from '@config/notifier';
import routesNames from '@config/routesNames';
import { DEFAULT_BRAND_CODE } from '@utils/brands';
import history from '../history';
import SubMessage from '@components/notification/SubMessage';

const client = axios.create({
    baseURL: window.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

const INTERNAL_ERROR_STATUS = 500;
const UNAUTHORIZED_STATUS = 401;
const BAD_REQUEST_STATUS = 400;
export const REQUEST_CANCELED_EXCEPTION = 'Canceled request';
export const DEFAULT_ERROR_TITLE = 'An error occurred';

export const catchCancelledRequestException = e => {
    if (e?.error?.message !== REQUEST_CANCELED_EXCEPTION) {
        throw e;
    }
};

const isLoggingError = error => {
    const isLoggingIn = history.location.pathname === routesNames.LOGIN;

    return !isLoggingIn && error.response && error.response.status === UNAUTHORIZED_STATUS;
};

const isValidationError = error => {
    if (!error.response?.data || error.response.status !== BAD_REQUEST_STATUS) {
        return false;
    }

    // CMS / ApiPlatform errors
    if (
        error.response.data.validation &&
        error.response.data.violations &&
        Object.keys(error.response.data.violations)
    ) {
        return true;
    }

    // OMS / RFC 7807 errors
    if (
        error.response.data.type === 'validation_failed' &&
        error.response.data['invalid-params'] &&
        error.response.data['invalid-params'].length > 0
    ) {
        return true;
    }

    return false;
};

export const requestInterceptor = ({ getState }, config) => {
    const params = config.params || {};
    const actions = config.reduxSourceAction || {};
    const { ui } = getState();

    if (actions.filters?.site) {
        params.site = actions.filters.site;
    } else if (!actions.noSite) {
        const site = ui.site ? ui.site.id : 1;
        params.site = actions.multipleSite ? [site] : site;
    }

    if (!actions.noBrand) {
        params.brand = ui.brand ? ui.brand.code : DEFAULT_BRAND_CODE;
    }

    config.params = new URLSearchParams(qs.stringify(params));

    if (!config.headers) {
        config.headers = {};
    }

    if (keycloakInstance) {
        config.headers.Authorization = `Bearer ${keycloakInstance.token}`;
    }

    return config;
};

export const responseInterceptor = ({ getSourceAction }, response) => {
    const action = getSourceAction(response.config);
    const result =
        action.schema && response.data && !action.skipNormalization
            ? normalize(response.data, action.schema)
            : {
                  entities: {},
                  result: [],
              };

    result.rawData = response.data;

    if (action.page) {
        result.page = action.page;
    }

    if (response.headers?.['content-range']) {
        const total = response.headers['content-range'].match(/\d+-\d+\/(\d+)/)[1];
        result.total = parseInt(total, 10);
    }

    if (response.headers?.['content-disposition']) {
        result.fileName = response.headers['content-disposition'].match(/^.*filename="?([^"\n]*)"?$/)[1];
    }

    return result;
};

export const getSubMessage = error => {
    let messages = [];
    const errorData = error?.response?.data || error || {};

    if (errorData.violations) {
        messages = Object.entries(errorData.violations).map(([id, violation]) => {
            return {
                id: `validation.${violation.message || id}`,
                values: violation.parameters,
            };
        });
    }

    if (errorData.linkedData) {
        Object.keys(errorData.linkedData).forEach(key => {
            const linkedData = errorData.linkedData[key];
            linkedData.forEach(name => {
                messages.push({ id: `error.linked_data.${key}`, values: { name } });
            });
        });
    }

    if (errorData['invalid-params']) {
        messages = errorData['invalid-params'].map(error => `${error.name ? `${error.name}: ` : ''}${error.reason}`);
    }

    return <SubMessage messages={messages} />;
};

export const notifyError = (errorData, error) => {
    if (process.env.NODE_ENV === 'test') return;
    if (error?.message === REQUEST_CANCELED_EXCEPTION) return;

    const Notifier = getNotifier();

    if (error && isLoggingError(error)) {
        Notifier.notify(DANGER, 'error.must_be_logged_in');
        history.push(routesNames.LOGIN);
    } else if (errorData && errorData['invalid-params']) {
        Notifier.notify(DANGER, 'error.default', undefined, getSubMessage(errorData));
    } else if (errorData?.message && error?.response?.status && error?.response?.status !== INTERNAL_ERROR_STATUS) {
        Notifier.notify(DANGER, 'error.' + errorData.message, undefined, getSubMessage(errorData));
    } else if (errorData?.detail) {
        Notifier.notify(DANGER, 'error.default', undefined, errorData.detail);
    } else if (errorData?.title && errorData.title !== DEFAULT_ERROR_TITLE) {
        Notifier.notify(DANGER, 'error.default', undefined, errorData.title);
    } else if (error?.message !== REQUEST_CANCELED_EXCEPTION) {
        Notifier.notify(DANGER, 'error.default');
    }
};

const options = {
    returnRejectedPromiseOnError: process.env.NODE_ENV !== 'test',
    interceptors: {
        request: [requestInterceptor],
        response: [responseInterceptor],
    },
    onError: ({ action, next, error }) => {
        let errorObject = error;
        if (!error.response) {
            errorObject = {
                message: error.message,
                status: 0,
            };
        }

        const errorData = error.response?.data;
        notifyError(errorData, error);

        if (isValidationError(error)) {
            const violations = {};
            if (errorData.validation) {
                // CMS / ApiPlatform errors
                Object.keys(errorData.violations).forEach(path => {
                    if (path.endsWith('translations')) {
                        set(violations, '_error', errorData.violations[path].message);

                        return;
                    }
                    set(violations, path, errorData.violations[path]);
                });
            } else if (errorData.type === 'validation_failed') {
                // OMS / RFC 7807 errors
                (errorData['invalid-params'] ?? []).forEach(({ name, reason }) => {
                    if (name.endsWith('translations')) {
                        set(violations, '_error', reason);

                        return;
                    }
                    set(violations, name, { message: reason });
                });
            }

            if (action.context?.errorsPrefix) {
                errorObject.violations = {
                    [action.context.errorsPrefix]: violations,
                };
            } else {
                errorObject.violations = violations;
            }
        }

        const nextAction = {
            type: action.types[2],
            error: errorObject,
            meta: {
                previousAction: action,
            },
        };

        next(nextAction);

        if (errorObject.violations) {
            throw new SubmissionError(errorObject.violations);
        }

        return nextAction;
    },
};

export default axiosMiddleware(client, options);
