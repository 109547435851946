import { addOnlyTrueFilter, addRangeFilter } from '@utils/filters';

export const schema = 'push-product';
export const name = 'PUSH_PRODUCT';
export const baseUrl = 'ecommerce-private/v1/push-products';

export const getFilterParams = filters => {
    const { onlyActive, beginBefore, beginAfter, ...otherFilters } = filters || {};

    const filterParams = { ...otherFilters };

    addOnlyTrueFilter('active', onlyActive, filterParams);

    addRangeFilter('beginOn', beginAfter, beginBefore, filterParams);

    return filterParams;
};
