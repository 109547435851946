import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(
    () => import(/* webpackChunkName: "compositionLayouts.Create" */ '@containers/compositionLayouts/Create')
);
const Update = lazy(
    () => import(/* webpackChunkName: "compositionLayouts.Update" */ '@containers/compositionLayouts/Update')
);
const List = lazy(
    () => import(/* webpackChunkName: "compositionLayouts.List" */ '@containers/compositionLayouts/List')
);

export default [
    <Route path={routesNames.UPDATE_COMPOSITION_LAYOUT} component={Create} exact={true} key="create" />,
    <Route path={routesNames.CREATE_COMPOSITION_LAYOUT} component={Update} exact={true} key="update" />,
    <Route path={routesNames.LIST_COMPOSITION_LAYOUTS} component={List} strict={true} key="list" />,
];
