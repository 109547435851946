import { name, baseUrl } from './config';

export const TYPES = {
    GET_PAGES: `GET_PAGES_${name}`,
    GET_PAGES_SUCCESS: `GET_PAGES_${name}_SUCCESS`,
    GET_PAGES_FAILURE: `GET_PAGES_${name}_FAILURE`,
};

export default (id, workspace) => ({
    types: [TYPES.GET_PAGES, TYPES.GET_PAGES_SUCCESS, TYPES.GET_PAGES_FAILURE],
    payload: {
        request: {
            url: `${baseUrl}/${id}/pages`,
            method: 'GET',
            params: {
                workspace,
            },
        },
    },
    id,
});
