import apiSchema from '@config/schema';
import { name, schema, baseUrl } from './config';

export const TYPES = {
    GET: `GET_${name}`,
    GET_SUCCESS: `GET_${name}_SUCCESS`,
    GET_FAILURE: `GET_${name}_FAILURE`,
};

export default () => ({
    types: [TYPES.GET, TYPES.GET_SUCCESS, TYPES.GET_FAILURE],
    schema: apiSchema.ENTITY(schema),
    payload: {
        request: {
            url: `${baseUrl}`,
            method: 'GET',
        },
    },
});
