import { IntlShape } from 'react-intl';
import { getNotifierInstance, DANGER, WARNING, SUCCESS } from 'sezane-components';

type Notifier = ReturnType<typeof getNotifierInstance>;
let instance: Notifier | null = null;

export { DANGER, WARNING, SUCCESS };

const getInstance = (intl: IntlShape | null = null) => {
    if (!intl && instance) {
        return instance;
    }

    if (!intl) {
        throw new Error('Notifier can not be instantiated without intl.');
    }

    instance = getNotifierInstance(intl);

    return instance;
};

export default getInstance;
