import { deleteActionCreator, ID_PLACEHOLDER } from '../../crud';
import { baseUrl, name } from './config';

const { types, actionCreator } = deleteActionCreator(
    `${name}_REFUND_FREEZE`,
    `${baseUrl}/${ID_PLACEHOLDER}/refund-freeze`
);
export const TYPES = types;

export default (reference: number) => {
    return actionCreator(reference);
};
