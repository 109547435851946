/* eslint no-underscore-dangle: ["error", {allow: ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]}] */
import { createStore, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer, { RootState } from '../reducers';
import getRootMiddleware from '../middlewares';

export const PERSIST_KEY = 'root-v2';
const PERSIST_CONFIG = {
    whitelist: ['auth', 'ui', 'references'],
    key: PERSIST_KEY,
    storage,
    debug: process.env.NODE_ENV === 'development',
};

export default function configureStore(initialState?: RootState) {
    const composeEnhancers =
        ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
        compose;

    const store = createStore(
        persistReducer(PERSIST_CONFIG, rootReducer),
        initialState,
        composeEnhancers(getRootMiddleware())
    );

    if (process.env.NODE_ENV !== 'production') {
        // @ts-ignore
        if (process.env.hot) {
            // @ts-ignore
            process.env.hot.accept(() => {
                store.replaceReducer(persistReducer(PERSIST_CONFIG, rootReducer));
            });
        }
    }

    const persistor = persistStore(store);

    return { store, persistor };
}
