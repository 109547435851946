import { name } from './config';

export const TYPES = {
    DUPLICATE: `DUPLICATE_${name}`,
    DUPLICATE_SUCCESS: `DUPLICATE_${name}_SUCCESS`,
    DUPLICATE_FAILURE: `DUPLICATE_${name}_FAILURE`,
};

export default (workspaceId, sites, pageVersions) => ({
    types: [TYPES.DUPLICATE, TYPES.DUPLICATE_SUCCESS, TYPES.DUPLICATE_FAILURE],
    payload: {
        request: {
            url: `ecommerce-private/v1/workspaces/${workspaceId}/duplicate-pages`,
            method: 'POST',
            data: {
                sites,
                pageVersions,
            },
        },
    },
    noSite: true,
});
