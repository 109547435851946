import { schema } from '@actions/oms/userCredits/config';
import { TYPES as CREATE_TYPES } from '@actions/oms/userCredits/create';
import { TYPES as LIST_TYPES } from '@actions/oms/userCredits/list';
import { TYPES as UPDATE_TYPES } from '@actions/oms/userCredits/update';
import { reduceReducers, updateReducer, CRUD_INITIAL_STATE, listReducer, createReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    createReducer(CREATE_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema)
);
