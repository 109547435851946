import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { Icon } from 'sezane-components';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Size, Color } from '@types';

const Status: FC<StatusProps> = ({ size = 'md', color = 'info', label, icon, className }) => (
    <span
        className={classNames(className, {
            'c-status': true,
            [`c-status--${size}`]: size !== 'md',
            [`c-status--${color}`]: color !== 'info',
        })}
        role="status"
    >
        {!!icon && <Icon icon={icon} className="c-status__icon" />}
        <span className="u-hidden">{label}</span>
    </span>
);

interface StatusProps {
    className?: string;
    color?: Color;
    icon?: IconName;
    label?: ReactNode;
    size?: Size;
}

export default Status;
