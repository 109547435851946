import { schema } from '@actions/preFooter/config';
import { TYPES as GET_TYPES } from '@actions/preFooter/get';
import { TYPES as UPDATE_TYPES } from '@actions/preFooter/update';
import { CRUD_INITIAL_STATE, reduceReducers, getReducer, updateReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    // get action acts like a list action (no id)
    getReducer(GET_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema)
);
