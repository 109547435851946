import { batchUpdateActionCreator, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = batchUpdateActionCreator(name, schema, `${baseUrl}/batch`);
export const TYPES = types;

export default (values, workspace) => {
    return withRequestParams({
        workspace,
    })(actionCreator(values));
};
