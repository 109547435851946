import { updateActionCreator, ID_PLACEHOLDER } from '../../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = updateActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}/status`, 'reference');
export const TYPES = types;

type OrderTransition = {
    transition: string;
};

// TODO Better typing for status from the Swagger specification
export default (reference: number, status: OrderTransition) => {
    return actionCreator(reference, status);
};
