import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "emailTemplates.List" */ '@containers/emailTemplates/List'));
const Update = lazy(() => import(/* webpackChunkName: "emailTemplates.Update" */ '@containers/emailTemplates/Update'));

export default [
    <Route path={routesNames.UPDATE_EMAIL_TEMPLATE} component={Update} strict={true} key="update" />,
    <Route path={routesNames.LIST_EMAIL_TEMPLATES} component={List} strict={true} key="list" />,
];
