import { FETCH_REFERENCES_SUCCESS } from '@actions/references';
import { References } from '@types';

export const INITIAL_STATE: References.References = {
    accountPages: [],
    blockTypes: [],
    brands: [],
    channels: [],
    checkoutPages: [],
    cloudinaryFormats: {} as References.CloudinaryFormats,
    countryStates: {} as References.CountryStates,
    crons: [],
    currencies: [],
    customisationServiceCharacterPresets: [],
    customisationServiceTypes: [],
    customisationServiceFormats: [],
    customisationServiceColors: [],
    customisationServicePositions: [],
    customisationServiceSymbols: [],
    ecardStatuses: [],
    genders: [],
    gestureReasons: [],
    gestureRefund: [],
    locales: [],
    logisticSites: [],
    logisticStatus: [],
    menuItemTypes: [],
    mobileContentRedirectionTypes: [],
    orderLogisticStatuses: [],
    orderSupportReasons: [],
    orderReturnStatuses: [],
    orderSupportStatuses: [],
    productBrands: [],
    positions: [],
    preorderStatus: [],
    promotionalCampaignDistributionTypes: [],
    promotionalCampaignSequentialDistributionTypes: [],
    promotionalCampaignTypes: [],
    recipients: [],
    reserves: [],
    retailSaleTypes: [],
    rubrics: [],
    retailOrderTypes: [],
    shippingProviders: [],
    siteCurrencyPosition: [],
    staticPages: [],
    staticPagesRoutes: {} as References.StaticPagesRoutes,
    stockTypes: [],
    targets: [],
    variantStatuses: [],
    vatTypes: [],
    workerContractTypes: [],
    menu_item_dropdown_block_type: [],
    menu_item_card_block_type: [],
};

export default function (state = INITIAL_STATE, action: any): References.References {
    switch (action.type) {
        case FETCH_REFERENCES_SUCCESS: {
            const data = action.payload.rawData;

            return {
                accountPages: [...data.account_pages],
                blockTypes: [...data.block_types],
                brands: [...data.brands],
                channels: [...data.channels],
                checkoutPages: [...data.checkout_pages],
                cloudinaryFormats: { ...data.cloudinary_formats },
                countryStates: data.country_states,
                crons: [...data.crons],
                currencies: [...data.currencies],
                customisationServiceCharacterPresets: [...data.customisation_service_character_presets],
                customisationServiceTypes: [...data.customisation_service_types],
                customisationServiceFormats: [...data.customisation_service_formats],
                customisationServiceColors: [...data.customisation_service_colors],
                customisationServicePositions: [...data.customisation_service_positions],
                customisationServiceSymbols: [...data.customisation_service_symbols],
                ecardStatuses: [...data.ecard_statuses],
                genders: [...data.genders],
                gestureReasons: [...data.gesture_reasons],
                gestureRefund: [...data.gesture_refund],
                locales: [...data.locales],
                logisticSites: [...data.logistic_sites],
                logisticStatus: [...data.logistic_status],
                menuItemTypes: [...data.menu_item_types],
                mobileContentRedirectionTypes: [...data.mobile_content_redirection_types],
                orderLogisticStatuses: Object.values(data.order_logistic_status),
                orderSupportReasons: data.order_support_reasons,
                orderReturnStatuses: [...data.order_return_status],
                orderSupportStatuses: [...data.order_support_status],
                productBrands: [...data.product_brands],
                positions: [...data.positions],
                preorderStatus: [...data.preorder_status],
                promotionalCampaignDistributionTypes: [...data.promotional_campaign_distribution_types],
                promotionalCampaignSequentialDistributionTypes: [
                    ...data.promotional_campaign_sequential_distribution_types,
                ],
                promotionalCampaignTypes: [...data.promotional_campaign_types],
                recipients: [...data.recipients],
                reserves: [...data.reserves],
                rubrics: [...data.rubrics],
                retailOrderTypes: [...data.retail_order_types],
                retailSaleTypes: [...data.retail_sale_types],
                shippingProviders: [...data.shipping_providers],
                siteCurrencyPosition: [...data.site_currency_position],
                staticPages: [...data.static_pages],
                staticPagesRoutes: { ...data.static_pages_routes },
                stockTypes: [...data.active_stocks],
                targets: [...data.targets],
                variantStatuses: [...data.variants_statuses],
                vatTypes: [...data.vat_types],
                workerContractTypes: [...data.worker_contract_types],
                menu_item_dropdown_block_type: [...data.menu_item_dropdown_block_type],
                menu_item_card_block_type: [...data.menu_item_card_block_type],
            };
        }

        default:
            return state;
    }
}
