import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ZoomSwitcher as ZoomSwitcherComponent } from 'sezane-components';

export const ZoomSwitcher = () => {
    return (
        <div className="c-nav c-header__nav u-mt(xs) u-d(flex) u-ai(center) u-jc(space-around)">
            <div className="u-c(gray-500)">
                <FormattedMessage id="sidebar.zoom_switcher" />
            </div>
            <ZoomSwitcherComponent />
        </div>
    );
};

export default ZoomSwitcher;
