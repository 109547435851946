import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "pages.List" */ '@containers/pages/list'));
const Update = lazy(() => import(/* webpackChunkName: "pages.Update" */ '@containers/pages/update'));

export default [
    <Route path={routesNames.UPDATE_PAGE} component={Update} exact={true} key="update" />,
    <Route path={routesNames.LIST_PAGES} component={List} strict={true} key="list" />,
];
