import { schema } from '@actions/users/config';
import { TYPES as CREATE_TYPES } from '@actions/users/create';
import { TYPES as LIST_TYPES } from '@actions/users/list';
import { TYPES as GET_TYPES } from '@actions/users/get';
import { TYPES as UPDATE_TYPES } from '@actions/users/update';
import { TYPES as REACTIVATE_TYPES } from '@actions/users/reactivate';
import { CRUD_INITIAL_STATE, reduceReducers, listReducer, getReducer, updateReducer, createReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    createReducer(CREATE_TYPES, schema),
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    updateReducer(REACTIVATE_TYPES, schema)
);
