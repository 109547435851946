import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import { changeSite } from '@actions/ui';
import { Dropdown } from 'sezane-components';
import { websitesSelector } from '@utils/sites';
import { findSimilarWorkspaceForSite } from '@utils/workspaces';
import { allItemsSelector } from '@components/crud/selectors';

class SiteSwitcher extends React.Component {
    switchSite = site => {
        const { history, changeSite, currentWorkspace, workspaces } = this.props;
        const workspace = currentWorkspace
            ? findSimilarWorkspaceForSite(site, workspaces, currentWorkspace)
            : site.currentWorkspace;
        const location = Object.assign({}, history.location);
        const search = location.search.replace(/workspace=\d+/, workspace ? `workspace=${workspace.id}` : '');
        history.replace({
            ...location,
            search,
        });

        changeSite(site, workspace);
    };

    render() {
        const { sites, currentSite, collapsed } = this.props;

        return (
            <Dropdown
                id="header-site-switcher"
                color="unstyled"
                textSecondary
                position="left"
                size="xs-bis"
                className={classNames('c-header__dropdown c-page__header-dropdown u-z-index-20', {
                    'u-mt(xs)': !collapsed,
                    'u-mt(lg)': collapsed,
                })}
                triggerClassName="c-header__dropdown-trigger"
                label={
                    <>
                        <span className="c-header__dropdown-label c-page__dropdown-label">
                            <FormattedMessage id="sidebar.layout.site_switcher" />
                        </span>
                        {currentSite.label}
                    </>
                }
                options={sites.map(site => ({
                    onSelect: () => this.switchSite(site),
                    renderOption: () => site.label,
                }))}
                selected={sites.findIndex(site => site.id === currentSite.id)}
            />
        );
    }
}

SiteSwitcher.propTypes = {
    ...ReactRouterPropTypes,
    sites: PropTypes.array.isRequired,
    currentSite: PropTypes.object.isRequired,
    changeSite: PropTypes.func.isRequired,
    collapsed: PropTypes.bool.isRequired,
    currentWorkspace: PropTypes.object,
    workspaces: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    sites: websitesSelector(state),
    currentSite: state.ui.site,
    currentWorkspace: state.ui.workspace,
    workspaces: allItemsSelector(state, 'workspaces'),
});

const mapDispatchToProps = dispatch => ({
    changeSite: (site, workspace) => dispatch(changeSite(site, workspace)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteSwitcher));
