import { OrdersConfigurationPreset } from '@actions/oms/orders/configuration';

export const DEFAULT_FIELDS = {
    id: true,
    ordered_at: true,
    source: true,
    site: true,
    country: false,
    type: false,
    user: true,
    nb_articles: true,
    total_price: true,
    shipping_mode: true,
    payment_status: true,
    status: true,
    after_sale_status: false,
    returns: false,
    address: false,
    reason: false,
    shop: false,
};

const TO_VALIDATE_FIELDS = {
    id: true,
    ordered_at: true,
    source: false,
    site: true,
    type: false,
    user: true,
    total_price: true,
    shipping_mode: false,
    payment_status: false,
    status: true,
    country: false,
    address: true,
    reason: true,
    shop: false,
};

export const PRESETS = {
    [OrdersConfigurationPreset.DEFAULT]: DEFAULT_FIELDS,
    [OrdersConfigurationPreset.TO_VALIDATE]: TO_VALIDATE_FIELDS,
};
