import { listActionCreator, withRequestParams, withValues, withWorkspace } from '../crud';
import { name, schema, baseUrl, searchUrl, getFilterParams, getFilterBody } from './config';
import apiSchema from '@config/schema';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    query = null,
    locale = 'fr',
    filters = {},
    sort = {
        field: 'enabled',
        direction: 'desc',
    },
    workspaceId = null,
    pagination = false,
    save = true,
    itemsPerPage = null,
    source,
    serialization = null
) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const filterParams = getFilterParams(filters);
    const data = getFilterBody(filters);

    // To send data in the body, we need to use the search endpoint in POST
    // Otherwise we'll use the simple list endpoint
    const action = data
        ? {
              types: [types.LIST, types.LIST_SUCCESS, types.LIST_FAILURE],
              schema: apiSchema.LIST(schema),
              payload: {
                  request: {
                      url: searchUrl,
                      method: 'POST',
                      params: {},
                      cancelToken: source?.token,
                      data,
                  },
              },
          }
        : actionCreator(source);

    return withValues({
        page,
        pagination,
        query,
        save,
    })(
        withRequestParams({
            page: pagination === true && page ? page : undefined,
            search: query || undefined,
            ...filterParams,
            pagination: pagination,
            ...sortParam,
            locale,
            itemsPerPage: itemsPerPage || undefined,
            serialization: serialization || undefined,
        })(withWorkspace(workspaceId)(action))
    );
};
