import { listActionCreator, withNoBrand, withNoSite, withPage, withRequestParams, withValues } from '../../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

type Sort = {
    field: string;
    direction: string;
};

type Filters = {}; // TODO: better typing

export default (page = 1, sort: Sort, filters: Filters, query = {}) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    return withValues({
        sort,
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
        })(withPage(page)(withNoBrand(withNoSite(actionCreator()))))
    );
};
