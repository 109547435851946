import { TYPES as LIST_BY_KIND_TYPES } from '@actions/compositionTemplates/listByKind';

export const INITIAL_STATE = {
    data: {},
    loading: false,
    total: 0,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LIST_BY_KIND_TYPES.LIST_BY_KIND: {
            return { ...state, loading: true };
        }
        case LIST_BY_KIND_TYPES.LIST_BY_KIND_SUCCESS: {
            return {
                ...state,
                data: action.payload.rawData || {},
                total: action.payload.total || 0,
                loading: false,
            };
        }
        case LIST_BY_KIND_TYPES.LIST_BY_KIND_FAILURE: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
}
