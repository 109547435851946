import { listActionCreator, withPage, withRequestParams, withValues } from '@actions/crud';
import { StockViewFilters } from '@containers/stockView/Filters';
import { baseUrl, getFilterParams, name, schema } from './config';

const { types, actionCreator } = listActionCreator(name, schema, `${baseUrl}/view-stocks`, 'ean');
export const TYPES = types;

export default (page = 1, sort: any = null, filters: StockViewFilters, search: string) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    const action = {
        ...actionCreator(),
        noSite: true,
        noBrand: true,
    };

    const filterParams = getFilterParams(filters, search);

    return withValues({
        sort,
    })(
        withRequestParams({
            ...sortParam,
            ...filterParams,
        })(withPage(page)(action))
    );
};
