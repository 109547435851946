import { FiltersParams, SortParams } from '@types';
import { listActionCreator, withPage, withRequestParams, withValues, withNoBrand, withNoSite } from '../../crud';

import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    sort: SortParams | null = null,
    filters: FiltersParams | null = null,
    query: object | null = null,
    withoutPagination = false
) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    const action = withValues({
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filters,
        })(withNoBrand(withNoSite(actionCreator())))
    );

    return withoutPagination ? action : withPage(page)(action);
};
