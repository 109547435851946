import {
    patchActionCreator,
    notifyPendingCreation,
    withRequestParams,
    withNoSite,
    ID_PLACEHOLDER,
    withValues,
} from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = patchActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}`);
export const TYPES = types;

export const getAction = (id, values, workspaces, preserveVersions = false) =>
    withValues({
        id,
    })(
        withRequestParams({
            workspaces,
            preserveVersions,
        })(withNoSite(actionCreator(id, values, { preserveVersions })))
    );

export default (id, values, workspaces, preserveVersions) => {
    const action = getAction(id, values, workspaces, preserveVersions);

    return notifyPendingCreation(action, 'sales.duplicate.implicit_pending', schema);
};
