import { listActionCreator, withPage, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, query = null) =>
    withRequestParams({
        search: query || undefined,
    })(withPage(page)(actionCreator()));
