import { name, baseUrl } from './config';

export const TYPES = {
    DELETE_IN_WORKSPACE: `DELETE_${name}_IN_WORKSPACE`,
    DELETE_IN_WORKSPACE_SUCCESS: `DELETE_${name}_IN_WORKSPACE_SUCCESS`,
    DELETE_IN_WORKSPACE_FAILURE: `DELETE_${name}_IN_WORKSPACE_FAILURE`,
};

export default (id, workspaceId) => ({
    types: [TYPES.DELETE_IN_WORKSPACE, TYPES.DELETE_IN_WORKSPACE_SUCCESS, TYPES.DELETE_IN_WORKSPACE_FAILURE],
    payload: {
        request: {
            url: `${baseUrl}/${id}`,
            method: 'DELETE',
            params: { workspace: workspaceId },
        },
    },
    noSite: true,
    id,
    workspaceId,
});
