import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(() => import(/* webpackChunkName: "sales.Create" */ '@containers/sales/Create'));
const Update = lazy(() => import(/* webpackChunkName: "sales.Update" */ '@containers/sales/Update'));
const List = lazy(() => import(/* webpackChunkName: "sales.Update" */ '@containers/sales/List'));

export default [
    <Route path={routesNames.CREATE_SALE} component={Create} exact={true} key="create" />,
    <Route path={routesNames.UPDATE_SALE} component={Update} exact={true} key="update" />,
    <Route path={routesNames.LIST_SALES} component={List} strict={true} key="list" />,
];
