import { addOnlyTrueFilter } from '@utils/filters';

export const schema = 'packagings';
export const name = 'PACKAGINGS';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/packaging`;

interface Filters {
    onlyActive: boolean;
    sites: string[];
}

export const getFilterParams = (filters: Filters) => {
    const { onlyActive, ...otherFilters } = filters || {};
    let filterParams = { ...otherFilters };

    addOnlyTrueFilter('enabled', onlyActive, filterParams);

    return filterParams;
};
