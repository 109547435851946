import { schema } from '@actions/sales/config';
import { TYPES as GET_TYPES } from '@actions/sales/get';
import { TYPES as DELETE_TYPES } from '@actions/sales/delete';
import { TYPES as LIST_TYPES } from '@actions/sales/list';
import { TYPES as DUPLICATE_FROM_VERSION_TYPES } from '@actions/sales/duplicateFromVersion';
import { CRUD_INITIAL_STATE, deleteReducer, getReducer, listReducer, reduceReducers } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

const duplicateReducer = (state, action) => {
    switch (action.type) {
        case DUPLICATE_FROM_VERSION_TYPES.DUPLICATE: {
            return {
                ...state,
                loading: true,
            };
        }

        case DUPLICATE_FROM_VERSION_TYPES.DUPLICATE_SUCCESS: {
            const entities = {
                ...state.entities,
                ...(action.payload.entities[schema] || {}),
            };

            return {
                ...state,
                entities,
                loading: false,
            };
        }

        case DUPLICATE_FROM_VERSION_TYPES.DUPLICATE_FAILURE: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
};

export default reduceReducers(
    getReducer(GET_TYPES, schema),
    deleteReducer(DELETE_TYPES),
    listReducer(LIST_TYPES, schema),
    duplicateReducer
);
