import { updateActionCreator, ID_PLACEHOLDER } from '../crud';
import { name, schema, baseUrl } from './config';
import { RoleRoleWrite } from '@types';

const { types, actionCreator } = updateActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}`);
export const TYPES = types;

export default (id: number, values: RoleRoleWrite) => {
    return actionCreator(id, values);
};
