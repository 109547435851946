/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link, Icon } from 'sezane-components';
import routesNames from '@config/routesNames';

const Profile = ({ user }) => {
    if (!user) {
        return null;
    }

    return (
        <div className="c-header__profile u-mt(sm)">
            <div className="u-d(flex) u-c(gray-500)">
                <Icon className="u-fxs(0) u-mr(xxs)" icon="user" />
                <div>
                    {user.given_name}
                    <div className="u-c(gray-600)">
                        <FormattedMessage id="sidebar.layout.team" values={{ label: user?.roles[0] }} />
                    </div>
                </div>
            </div>
            <div className="u-mt(sm) u-c(gray-500)">
                <Link
                    to={routesNames.LOGOUT}
                    tabIndex={0}
                    className="c-header__signout c-link u-d(flex) u-ai(center)"
                    role="button"
                >
                    <Icon className="u-fxs(0) u-mr(xxs)" icon="sign-out-alt" />
                    <FormattedMessage id="sidebar.layout.logout" />
                </Link>
            </div>
        </div>
    );
};

Profile.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = state => ({
    user: state.auth.user,
});
export default connect(mapStateToProps)(Profile);
