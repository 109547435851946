import { listActionCreator, withPage, withRequestParams, withValues, withWorkspace } from '../crud';
import { name, schema, baseUrl, getFilterParams } from './config';

export const ORDER_PRODUCT = 'product';
export const ORDER_DATE = 'date';
export const ORDER = [ORDER_PRODUCT, ORDER_DATE];
const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, orderBy = ORDER_DATE, filters = {}, workspace = null, save = true) => {
    const filterParams = getFilterParams(filters);

    return withPage(page)(
        withWorkspace(workspace)(
            withValues({
                sort: orderBy,
                filters,
                skipNormalization: orderBy === ORDER_PRODUCT,
                save,
            })(
                withRequestParams({
                    orderBy,
                    ...filterParams,
                })(actionCreator())
            )
        )
    );
};
