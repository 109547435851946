import useAcl from './useAcl';

type AuthorizedRoutePros = {
    yes: Function;
    no: Function;
    path: string;
};

const AuthorizedRoute = ({ path, yes, no }: AuthorizedRoutePros) => {
    const { isPathAllowed } = useAcl();

    return isPathAllowed(path) ? yes() : no();
};

AuthorizedRoute.defaultProps = {
    yes: () => null,
    no: () => null,
};

export default AuthorizedRoute;
