import { ID_PLACEHOLDER, createActionCreator } from '../../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = createActionCreator(
    `${name}_REFUND_FREEZE`,
    schema,
    `${baseUrl}/${ID_PLACEHOLDER}/refund-freeze`,
    'reference'
);
export const TYPES = types;

export default (reference: number) => {
    const action = actionCreator();
    action.payload.request.url = action.payload.request.url.replace(ID_PLACEHOLDER, reference.toString());
    return action;
};
