import React from 'react';
import { CommandMenu as CommandMenuComponent, Command, Icon } from 'sezane-components';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import routesNames from '@config/routesNames';
import menu from '@config/menu';
import { useQuery } from '@tanstack/react-query';
import omsApiClient from '@api/clients/oms';
import ecommerceApiClient from '@api/clients/ecommerce';
import { useAppSelector } from '@utils/hooks/redux';

const ACTION_ROUTES: Array<{ route: keyof typeof routesNames; label: string }> = [
    { route: 'CREATE_SALE', label: 'sales.create' },
    { route: 'CREATE_SHIPPING_MODE', label: 'shipping_modes.create' },
    { route: 'CREATE_ORDER_RETURN_MODES', label: 'order_return_modes.create.title' },
    { route: 'CREATE_MULTI_PRODUCT', label: 'products.list.create_multi_product' },
    { route: 'LIST_MULTI_PRODUCTS', label: 'multi_products.list.title' },
    { route: 'LIST_PARAMETERS', label: 'parameters.list.title' },
    { route: 'PRE_FOOTER', label: 'pre_footer.title' },
    { route: 'CREATE_SITE', label: 'sites.create' },
    { route: 'CREATE_BRAND', label: 'brands.create.title' },
    { route: 'CREATE_VOUCHER_CAMPAIGN', label: 'voucher_campaigns.titles.create' },
    { route: 'CREATE_POPIN', label: 'popins.create' },
    { route: 'CREATE_CUSTOMISATION_SERVICES', label: 'customisation_services.create' },
    { route: 'CREATE_PACKAGING', label: 'packagings.create' },
    { route: 'CREATE_SHOPS', label: 'shops.create' },
    { route: 'CREATE_SCHEDULED_UPDATE', label: 'scheduled_updates.create.title' },
];

const MAX_RESULTS = 3;

const CommandMenu = () => {
    const workspace = useAppSelector(state => state.ui.workspace?.id);
    const history = useHistory();
    const intl = useIntl();
    const [search, setSearch] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const isSearchEnabled = open && search.length > 2;
    const { data: orders, isInitialLoading: isOrdersLoading } = useQuery(
        ['orders', search],
        async () => omsApiClient.orderList({ search, itemsPerPage: MAX_RESULTS }),
        { enabled: isSearchEnabled }
    );
    const { data: carts, isInitialLoading: isCartsLoading } = useQuery(
        ['carts', search],
        // @ts-expect-error
        async () => omsApiClient.cartsList({ search, itemsPerPage: MAX_RESULTS }),
        { enabled: isSearchEnabled }
    );
    const { data: users, isInitialLoading: isUsersLoading } = useQuery(
        ['users', search],
        async () => omsApiClient.userList({ search, itemsPerPage: MAX_RESULTS }),
        { enabled: isSearchEnabled }
    );

    const { data: products, isInitialLoading: isProductsLoading } = useQuery(
        ['products', search],
        async () =>
            ecommerceApiClient.getStoreVariantsCollection({
                // @ts-expect-error
                search,
                workspace,
                locale: 'fr',
                itemsPerPage: MAX_RESULTS,
            }),
        { enabled: isSearchEnabled }
    );
    const isLoading = isOrdersLoading || isUsersLoading || isCartsLoading || isProductsLoading;
    const hasData =
        (orders?.data && orders?.data.length > 0) ||
        (users?.data && users?.data.length > 0) ||
        (carts?.data && carts?.data.length > 0) ||
        (products?.data && products?.data.length > 0);

    return (
        <CommandMenuComponent
            open={open}
            onOpenChange={setOpen}
            shouldFilter={!hasData}
            filter={(value, search) => {
                if (hasData) return 1;
                if (value.includes(search)) return 1;

                return 0;
            }}
            onKeyDown={e => {
                if (e.key === 'Backspace' && !search) {
                    e.preventDefault();
                    setSearch('');
                }

                if (e.key === 'Escape') {
                    setSearch('');
                }
            }}
        >
            <Command.Input
                placeholder={intl.formatMessage({ id: 'command_menu.placeholder' })}
                value={search}
                onValueChange={setSearch}
            />
            <Command.List>
                {!isLoading && (
                    <Command.Empty>
                        <FormattedMessage id="command_menu.no_results" />
                    </Command.Empty>
                )}
                {!hasData && (
                    <>
                        {menu
                            .filter(item => item.childrens)
                            .map((item, index) => (
                                <Command.Group
                                    key={index}
                                    heading={
                                        <>
                                            {item.icon && (
                                                <Icon className="c-icon--monospace c-nav__icon" icon={item.icon} />
                                            )}
                                            <span className="u-ta(center) u-jc(center) u-ml(xxs) u-pb(xxxs)">
                                                <FormattedMessage id={item.title} />
                                            </span>
                                        </>
                                    }
                                >
                                    {item.childrens?.map(({ route, title, search }, index) => (
                                        <Command.Item
                                            key={`${route}-${index}`}
                                            onSelect={() => {
                                                history.push(`${route}${search || ''}`);
                                                setOpen(false);
                                            }}
                                        >
                                            <FormattedMessage id={title} />
                                        </Command.Item>
                                    ))}
                                </Command.Group>
                            ))}
                        <Command.Group heading={intl.formatMessage({ id: 'command_menu.heading.actions' })}>
                            {ACTION_ROUTES.map(({ route, label }) => (
                                <Command.Item
                                    key={route}
                                    onSelect={() => {
                                        history.push(routesNames[route]);
                                        setOpen(false);
                                    }}
                                >
                                    <FormattedMessage id={label} />
                                </Command.Item>
                            ))}
                        </Command.Group>
                    </>
                )}
                {!isLoading && orders?.data && orders?.data.length > 0 && (
                    <Command.Group heading={intl.formatMessage({ id: 'command_menu.heading.orders' })}>
                        {orders?.data.map(item => (
                            <Command.Item
                                key={`order-${item.reference}`}
                                onSelect={() => {
                                    history.push(routesNames.SHOW_ORDER.replace(':id', item.reference.toString()));
                                    setOpen(false);
                                }}
                            >
                                Commande {item.reference}
                            </Command.Item>
                        ))}
                    </Command.Group>
                )}
                {!isLoading && users?.data && users?.data.length > 0 && (
                    <Command.Group heading={intl.formatMessage({ id: 'command_menu.heading.users' })}>
                        {users?.data.map(user => (
                            <Command.Item
                                key={`user-${user.keycloakId}`}
                                onSelect={() => {
                                    history.push(routesNames.SHOW_USER.replace(':id', user.keycloakId));
                                    setOpen(false);
                                }}
                            >
                                Utilisateur {user.fullName}
                            </Command.Item>
                        ))}
                    </Command.Group>
                )}
                {!isLoading && carts?.data && carts?.data.length > 0 && (
                    <Command.Group heading={intl.formatMessage({ id: 'command_menu.heading.carts' })}>
                        {carts?.data.map(cart => (
                            <Command.Item
                                key={`cart-${cart.uid}`}
                                onSelect={() => {
                                    history.push(routesNames.SHOW_CART.replace(':id', cart.uid!));
                                    setOpen(false);
                                }}
                            >
                                Panier {cart.uid}
                            </Command.Item>
                        ))}
                    </Command.Group>
                )}
                {!isLoading && products?.data && products?.data.length > 0 && (
                    <Command.Group heading={intl.formatMessage({ id: 'command_menu.heading.products' })}>
                        {products?.data.map(product => (
                            <Command.Item
                                key={`product-${product.id}`}
                                onSelect={() => {
                                    history.push(routesNames.UPDATE_PRODUCT.replace(':id', product.id!.toString()));
                                    setOpen(false);
                                }}
                            >
                                Produit "{product.catalogVariant?.translations?.fr?.label} -{' '}
                                {product.catalogVariant?.translations?.fr?.color}"
                            </Command.Item>
                        ))}
                    </Command.Group>
                )}
                {isLoading && (
                    <Command.Loading>
                        <p className="u-pt(xs) u-pb(xs)">
                            <FormattedMessage id="command_menu.searching" />
                        </p>
                    </Command.Loading>
                )}
            </Command.List>
        </CommandMenuComponent>
    );
};

export default CommandMenu;
