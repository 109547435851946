import apiSchema from '@config/schema';
import { schema } from './config';

export const TYPES = {
    PUBLISH: 'PUBLISH_WORKSPACE',
    PUBLISH_SUCCESS: 'PUBLISH_WORKSPACE_SUCCESS',
    PUBLISH_FAILURE: 'PUBLISH_WORKSPACE_FAILURE',
};

export default id => ({
    types: [TYPES.PUBLISH, TYPES.PUBLISH_SUCCESS, TYPES.PUBLISH_FAILURE],
    schema: apiSchema.ENTITY(schema),
    payload: {
        request: {
            url: `/ecommerce-private/v1/workspaces/${id}/publish`,
            method: 'PUT',
        },
    },
});
