import { CHANGE_THEME, SET_DASHBOARD, SET_MENU_FAVORITES } from '@actions/ui';
import { RootState } from '@reducers';
import { Middleware } from 'redux';

import ecommerceApiClient from '@api/clients/ecommerce';
import { UserConfigurationConfigurationWrite } from '@types';

const SHOULD_UPDATE_CONFIGURATION_ACTION = [CHANGE_THEME, SET_MENU_FAVORITES, SET_DASHBOARD];

export const userPreferencesMiddleware: Middleware<{}, RootState> = store => next => action => {
    if (SHOULD_UPDATE_CONFIGURATION_ACTION.includes(action.type)) {
        const uiState = store.getState().ui;
        let configuration = { theme: uiState.theme, favorites: uiState.menuFavorites, dashboard: uiState.dashboard };

        if (action.type === CHANGE_THEME) {
            configuration.theme = action.theme;
        } else if (action.type === SET_MENU_FAVORITES) {
            configuration.favorites = action.menuFavorites;
        } else if (action.type === SET_DASHBOARD) {
            configuration.dashboard = action.dashboard;
        }

        ecommerceApiClient.updateUserConfigurationUserConfigurationItem(
            configuration as UserConfigurationConfigurationWrite
        );
    }

    return next(action);
};

export default userPreferencesMiddleware;
