import React, { PropsWithChildren, ReactNode, useContext, useRef, useState } from 'react';
import { Modal } from 'sezane-components';

import { setKeyControlledAction, SUBMIT_KEYS } from '@utils/shortcuts';

// Context to make confirm modal available
export const ConfirmContext = React.createContext({
    show:
        (defaultTitle?: ReactNode, defaultContent?: ReactNode) =>
        (
            successCallback?: ((...args: any[]) => void) | null,
            errorCallback?: ((...args: any[]) => void) | null,
            title?: ReactNode,
            content?: ReactNode
        ) =>
        (...args: any[]) => {},
});

export const ConfirmProvider = ({ children }: PropsWithChildren) => {
    const localSuccessCallback = useRef<any>(null);
    const localErrorCallback = useRef<any>(null);
    const localResolve = useRef<any>(null);

    // Modal props
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState<ReactNode>(null);
    const [content, setContent] = useState<ReactNode>(null);

    const show =
        (defaultTitle?: ReactNode, defaultContent?: ReactNode) =>
        (
            successCallback?: ((...args: any[]) => void) | null,
            errorCallback?: ((...args: any[]) => void) | null,
            title?: ReactNode,
            content?: ReactNode
        ) =>
        (...args: any[]) => {
            return new Promise(resolve => {
                setTitle(title || defaultTitle);
                setContent(content || defaultContent);
                setOpen(true);
                localResolve.current = resolve;
                localSuccessCallback.current = successCallback ? () => successCallback(...args) : null;
                localErrorCallback.current = errorCallback ? () => errorCallback(...args) : null;
            });
        };

    const hide = () => {
        localResolve.current(localErrorCallback && localErrorCallback.current ? localErrorCallback.current() : null);
        setOpen(false);
    };

    const confirm = () => {
        if (open) {
            localResolve.current(localSuccessCallback.current());
            hide();
        }
    };

    setKeyControlledAction(confirm, SUBMIT_KEYS);

    return (
        <>
            {open && (
                <Modal title={title} isOpen={true} onClosed={hide} onConfirm={confirm} toggleButton={false}>
                    {content}
                </Modal>
            )}
            <ConfirmContext.Provider value={{ show }}>{children}</ConfirmContext.Provider>
        </>
    );
};

export default function useConfirm(title?: ReactNode, content?: ReactNode) {
    const { show } = useContext(ConfirmContext);
    return show(title, content);
}
