import { name } from './config';

export const TYPES = {
    READ_CSV: `READ_${name}_CSV`,
    READ_CSV_SUCCESS: `READ_${name}_CSV_SUCCESS`,
    READ_CSV_FAILURE: `READ_${name}_CSV_FAILURE`,
};

export default path => ({
    types: [TYPES.READ_CSV, TYPES.READ_CSV_SUCCESS, TYPES.READ_CSV_FAILURE],
    payload: {
        request: {
            headers: {
                Accept: 'text/csv',
            },
            responseType: 'blob',
            url: `/ecommerce-private/v1/store-variants/read-export`,
            method: 'GET',
            params: {
                path,
            },
        },
    },
});
