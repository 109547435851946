import { produce } from 'immer';

import { schema } from '@actions/mobileContents/config';
import { TYPES as GET_TYPES } from '@actions/mobileContents/get';
import { TYPES as LIST_TYPES } from '@actions/mobileContents/list';
import { TYPES as BATCH_UPDATE_TYPES } from '@actions/mobileContents/batchUpdate';
import { getReducer, listReducer, batchUpdateReducer, CRUD_INITIAL_STATE, reduceReducers } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

const mergeMobileContent = (oldMobileContent, newMobileContent) => {
    return {
        ...oldMobileContent,
        ...newMobileContent,
    };
};

const updateMobileContentFromAction = (draftState, action) => {
    const newMobileContentId = action.payload.result;
    const newMobileContent = action.payload.entities[schema][newMobileContentId];

    draftState.entities[newMobileContentId] = mergeMobileContent(
        draftState.entities[newMobileContentId] || {},
        newMobileContent
    );

    return draftState;
};

const getReducerWithMerge = (state, action) => {
    const baseReducer = getReducer(GET_TYPES, schema);

    if (action.type === GET_TYPES.GET_SUCCESS) {
        return produce(state, draftState => {
            draftState.loading = false;
            draftState.loaders.get = false;
            draftState.error = false;

            return updateMobileContentFromAction(draftState, action);
        });
    }

    return baseReducer(state, action);
};

export default reduceReducers(
    getReducerWithMerge,
    listReducer(LIST_TYPES, schema),
    batchUpdateReducer(BATCH_UPDATE_TYPES, schema)
);
