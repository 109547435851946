import { Country } from '@types';
import { addRangeFilter } from '@utils/filters';

export const schema = 'order';
export const name = 'ORDER';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/orders`;

export type OrdersFilters = {
    brands?: string[];
    countries?: Country[];
    createdAtAfter?: string;
    createdAtBefore?: string;
    hasReturn?: boolean;
    isOmsOrder?: boolean;
    totalPriceMax?: number;
    totalPriceMin?: number;
    toValidate?: boolean;
};

export const getFilterParams = (filters: OrdersFilters) => {
    let filterParams: Record<string, any> = { ...filters };

    if (!!filters) {
        const {
            brands,
            countries,
            createdAtAfter,
            createdAtBefore,
            hasReturn,
            isOmsOrder,
            totalPriceMax,
            totalPriceMin,
            toValidate,
            ...otherFilters
        } = filters;

        filterParams = { ...otherFilters };

        if (countries) {
            const countryCodes: string[] = [];
            countries.forEach(country => {
                countryCodes.push(country.code!);
            });
            filterParams['countries'] = countryCodes;
        }

        if (createdAtAfter || createdAtBefore) {
            filterParams.createdAt = {};
            filterParams.createdAt.after = createdAtAfter || undefined;
            filterParams.createdAt.before = createdAtBefore || undefined;
        }

        if (toValidate) {
            filterParams.status = ['to_validate'];
        }

        if (brands) {
            filterParams.brands = [brands];
        }

        if (totalPriceMin) {
            filterParams['totalPriceIncludingAllTaxes[after]'] = totalPriceMin * 100;
        }
        if (totalPriceMax) {
            filterParams['totalPriceIncludingAllTaxes[before]'] = totalPriceMax * 100;
        }

        if (hasReturn === true) {
            filterParams.hasReturn = hasReturn;
        }
        if (isOmsOrder === true) {
            filterParams.isOmsOrder = isOmsOrder;
        }
    }

    return filterParams;
};
export const getExportFilterParams = ({
    brands,
    countries,
    createdAtAfter,
    createdAtBefore,
    hasReturn,
    totalPriceMax,
    totalPriceMin,
    toValidate,
    ...otherFilters
}: OrdersFilters = {}) => {
    const filterParams: Record<string, any> = { ...otherFilters };

    if (countries) {
        const countryCodes: string[] = [];
        countries.forEach(country => {
            countryCodes.push(country.code!);
        });
        filterParams.countries = countryCodes;
    }
    if (toValidate === true) {
        filterParams.status = ['to_validate'];
    }
    if (brands) {
        filterParams.brands = [brands];
    }
    if (hasReturn === true) {
        filterParams.hasReturn = hasReturn;
    }

    addRangeFilter('createdAt', createdAtAfter, createdAtBefore, filterParams);
    addRangeFilter(
        'totalPriceIncludingAllTaxes',
        totalPriceMin ? totalPriceMin * 100 : undefined,
        totalPriceMax ? totalPriceMax * 100 : undefined,
        filterParams
    );

    return filterParams;
};
