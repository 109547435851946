import { produce } from 'immer';
import { schema } from '@actions/oms/orders/config';
import { TYPES as GET_TYPES } from '@actions/oms/orders/get';
import { TYPES as LIST_TYPES } from '@actions/oms/orders/list';
import { TYPES as UPDATE_STATUS_TYPES } from '@actions/oms/orders/updateStatus';
import { DEFAULT_FIELDS, PRESETS } from '@containers/orders/configuration/builder';
import { OrdersConfigurationPreset, TYPES as CONFIGURATION_TYPES } from '@actions/oms/orders/configuration';
import { TYPES as REFUND_FREEZE_TYPES } from '@actions/oms/orders/refundFreeze';
import { TYPES as REFUND_UNFREEZE_TYPES } from '@actions/oms/orders/refundUnfreeze';
import { reduceReducers, getReducer, listReducer, CRUD_INITIAL_STATE, updateReducer } from '../crud';

export const INITIAL_STATE = {
    ...CRUD_INITIAL_STATE,
    listConfiguration: DEFAULT_FIELDS,
};

const configurationReducer = (state: any, action: any) => {
    switch (action.type) {
        case CONFIGURATION_TYPES.CHANGE_LIST_CONFIGURATION: {
            return {
                ...state,
                listConfiguration: {
                    ...state.listConfiguration,
                    ...action.values,
                },
                listView: OrdersConfigurationPreset.CUSTOM,
            };
        }
        case LIST_TYPES.LIST: {
            const toValidateChanged =
                state.filters?.toValidate !== undefined && state.filters?.toValidate !== action.filters?.toValidate;
            return {
                ...state,
                listConfiguration: toValidateChanged
                    ? action.filters?.toValidate === true
                        ? PRESETS[OrdersConfigurationPreset.TO_VALIDATE]
                        : PRESETS[OrdersConfigurationPreset.DEFAULT]
                    : state.listConfiguration,
                listView: toValidateChanged
                    ? action.filters?.toValidate === true
                        ? OrdersConfigurationPreset.TO_VALIDATE
                        : OrdersConfigurationPreset.DEFAULT
                    : state.listView,
            };
        }

        default:
            return state;
    }
};

const refundFreezeReducer = (state: any, action: any) => {
    switch (action.type) {
        case REFUND_FREEZE_TYPES.CREATE_SUCCESS: {
            const newEntities = action.payload.entities[schema] || {};
            const entities = {
                ...state.entities,
                ...newEntities,
            };

            return {
                ...state,
                entities,
            };
        }

        case REFUND_UNFREEZE_TYPES.DELETE_SUCCESS: {
            const reference = action.meta.previousAction.id;

            return produce(state, (draftState: any) => {
                draftState.entities[reference].hasRefundFreeze = false;

                return draftState;
            });
        }

        default:
            return state;
    }
};

export default reduceReducers(
    configurationReducer,
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_STATUS_TYPES, schema),
    refundFreezeReducer,
    INITIAL_STATE
);
