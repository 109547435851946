import { updateActionCreator, ID_PLACEHOLDER } from '../crud';
import { name, schema, baseUrl, idAttribute } from './config';

const { types, actionCreator } = updateActionCreator(
    `${name}_REACTIVATE`,
    schema,
    `${baseUrl}/${ID_PLACEHOLDER}/reactivate`,
    idAttribute
);
export const TYPES = types;

export default actionCreator;
