import { schema } from '@actions/oms/packagings/config';
import { TYPES as LIST_TYPES } from '@actions/oms/packagings/list';
import { TYPES as GET_TYPES } from '@actions/oms/packagings/get';
import { TYPES as DELETE_TYPES } from '@actions/oms/packagings/delete';
import { TYPES as IMPORT_TYPES } from '@actions/oms/packagings/importLinkedItems';
import { TYPES as UPDATE_TYPES } from '@actions/oms/packagings/update';
import { CRUD_INITIAL_STATE, deleteReducer, getReducer, listReducer, reduceReducers, updateReducer } from '../crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

const importLinkedItemsReducer = (state: any, action: any, ...rest: any[]) => {
    switch (action.type) {
        case IMPORT_TYPES.IMPORT_PACKAGINGS_LINKED_ITEMS_SUCCESS:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.rawData.id]: {
                        ...state.entities[action.payload.rawData.id],
                        countItems: action.payload.rawData.count,
                    },
                },
            };
        default:
            return { ...state };
    }
};

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    getReducer(GET_TYPES, schema),
    deleteReducer(DELETE_TYPES),
    updateReducer(UPDATE_TYPES, schema),
    importLinkedItemsReducer
);
