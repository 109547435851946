import { addOnlyTrueFilter } from '@utils/filters';

export const schema = 'sale';
export const name = 'SALE';
export const baseUrl = 'ecommerce-private/v1/sales';

export const getFilterParams = ({ enabled = true, ...otherFilters } = {}) => {
    let filterParams = { ...otherFilters };

    addOnlyTrueFilter('enabled', enabled, filterParams);

    return filterParams;
};
