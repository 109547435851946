import { set } from 'lodash';
import { SubmissionError } from 'redux-form';
import { keycloakInstance } from 'sezane-components';

import { notifyError } from '@middlewares/axios';
import { Api, QueryParamsType } from '../../types/oms';

class OmsApi extends Api<any> {
    // Override array serialization for PHP format
    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any, index: number) => this.encodeQueryParam(`${key}[${index}]`, v)).join('&');
    }
}

const omsApiClient = new OmsApi({
    baseUrl: window.REACT_APP_OMS_API_URL,
    baseApiParams: {
        headers: {
            Accept: 'application/json',
        },
        secure: true,
    },
    securityWorker: () => {
        return {
            ...(process.env.NODE_ENV !== 'test' && {
                headers: {
                    Authorization: `Bearer ${keycloakInstance.token}`,
                },
            }),
        };
    },
});

export default new Proxy(omsApiClient.omsPrivate, {
    get(target, propKey: keyof typeof omsApiClient.omsPrivate) {
        const origMethod = target[propKey];

        return function (...args: any[]) {
            // @ts-ignore
            let result = origMethod.apply(this, args);
            return result.catch((e: any) => {
                const errorData = e.error;
                notifyError(errorData, e);

                // Handle validation errors / bad requests
                if (e.status === 400) {
                    const violations = {};

                    if (errorData.validation) {
                        // CMS / ApiPlatform errors
                        Object.keys(errorData.violations).forEach(path => {
                            if (path.endsWith('translations')) {
                                set(violations, '_error', errorData.violations[path].message);

                                return;
                            }
                            set(violations, path, errorData.violations[path]);
                        });
                    } else if (errorData.type === 'validation_failed') {
                        // OMS / RFC 7807 errors
                        (errorData['invalid-params'] ?? []).forEach(({ name, reason, reasonKey }: any) => {
                            if (name.endsWith('translations')) {
                                set(violations, '_error', reason);

                                return;
                            }
                            set(violations, name, { message: reason, messageKey: reasonKey });
                        });
                    }

                    throw new SubmissionError(violations);
                }

                throw e;
            });
        };
    },
});
