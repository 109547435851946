import isString from 'lodash/isString';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Tooltip } from 'sezane-components';

import { Size } from '@types';

const InheritedValue: FC<InheritedValueProps> = ({ inherited, intl, size }) => {
    if (!inherited) {
        return null;
    }

    return (
        <Tooltip
            position="right"
            renderToggleButton={toggle => (
                <Button
                    icon="paste"
                    iconOnly
                    color="unstyled"
                    onClick={toggle}
                    hiddenLabel={intl.formatMessage({ id: 'common.form.inherited_value_btn' })}
                    size={size}
                />
            )}
            onFocus
        >
            <h4>
                <FormattedMessage id="common.form.inherited_value" />
            </h4>
            {isString(inherited) ? <div dangerouslySetInnerHTML={{ __html: inherited }} /> : inherited}
        </Tooltip>
    );
};

interface InheritedValueProps extends WrappedComponentProps {
    inherited: ReactNode;
    size?: Size;
}

export default injectIntl(InheritedValue);
