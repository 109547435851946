import classNames from 'classnames';
import React, { useState } from 'react';
import { Icon, MenuItem } from 'sezane-components';
import styles from './FavoriteWrapper.module.css';

interface FavoriteWrapperProps {
    children: React.ReactNode;
    item: MenuItem;
    selected?: boolean;
    handleFavorite: (item: MenuItem) => void;
}
const FavoriteWrapper: React.FC<FavoriteWrapperProps> = ({ children, item, selected = false, handleFavorite }) => {
    const [hover, setHover] = useState(false);

    return (
        <span
            className="u-d(flex) u-ai(center)"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {children}
            {hover && (
                <Icon
                    aria-selected={selected}
                    icon="star"
                    className={classNames(
                        !selected && styles.unfilled,
                        'u-fz(xs) c-icon--monospace c-nav__link u-d(none)@tablet-down'
                    )}
                    onClick={() => handleFavorite(item)}
                />
            )}
        </span>
    );
};

export default FavoriteWrapper;
