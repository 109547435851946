import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(() => import(/* webpackChunkName: "popins.Create" */ '@containers/pushProducts/Create'));
const Update = lazy(() => import(/* webpackChunkName: "popins.Update" */ '@containers/pushProducts/Update'));
const List = lazy(() => import(/* webpackChunkName: "popins.List" */ '@containers/pushProducts/List'));

export default [
    <Route path={routesNames.CREATE_PUSH_PRODUCT} component={Create} key="create" />,
    <Route path={routesNames.UPDATE_PUSH_PRODUCT} component={Update} key="update" />,
    <Route path={routesNames.LIST_PUSH_PRODUCTS} component={List} key="list" />,
];
