import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(
    () => import(/* webpackChunkName: "customisationServices.Create" */ '@containers/customisationServices/Create')
);
const Update = lazy(
    () => import(/* webpackChunkName: "customisationServices.Update" */ '@containers/customisationServices/Update')
);
const List = lazy(
    () => import(/* webpackChunkName: "customisationServices.List" */ '@containers/customisationServices/List')
);

export default [
    <Route
        path={routesNames.CREATE_CUSTOMISATION_SERVICES}
        component={Create}
        strict={true}
        key="customisation-service-create"
    />,
    <Route
        path={routesNames.UPDATE_CUSTOMISATION_SERVICES}
        component={Update}
        strict={true}
        key="customisation-service-update"
    />,
    <Route path={routesNames.LIST_CUSTOMISATION_SERVICES} component={List} strict={true} key="customisation-service" />,
];
