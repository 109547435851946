import { StockViewFilters } from '@containers/stockView/Filters';

export const schema = 'stockView';
export const name = 'STOCK_VIEW';
export const baseUrl = `${window.REACT_APP_STOCK_API_URL}/stock-private/v1`;

export const getFilterParams = (
    { eans = '', productCodes = '', saleId, site, workspaceId, ...otherFilters }: StockViewFilters,
    search = ''
) => {
    const filterParams: Record<string, any> = { ...otherFilters };

    if (eans) {
        eans.split(';').forEach((ean, index) => {
            filterParams[`eans_filter[${index}]`] = ean;
        });
    }
    if (productCodes) {
        productCodes.split(';').forEach((productCode, index) => {
            filterParams[`product_codes_filter[${index}]`] = productCode;
        });
    }
    if (search) {
        search.split(';').forEach((searchPart, index) => {
            filterParams[`labels_filter[${index}]`] = searchPart;
        });
    }

    if (saleId) {
        filterParams.sale_id = saleId;
    }
    if (site) {
        filterParams.site = site.code;
    }
    if (workspaceId) {
        filterParams.workspace_id = workspaceId;
    }

    return filterParams;
};
