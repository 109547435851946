export const ZENDESK_URL = 'https://sezane.zendesk.com/agent/search/1?q=';

export type ZendeskData = { link: string; nbTickets: number };

export const isCustomerServiceView = () => {
    const searchParams = new URLSearchParams(document.location.search);
    const viewParam = searchParams.get('view');
    return !!viewParam && viewParam === 'sc';
};

export const openZendeskLink = (
    // TODO: better typing for api response promise
    searchFunction: (params: string) => Promise<any>,
    params: string,
    endUrl: string,
    callback: (data: ZendeskData | null) => void
) => {
    searchFunction(params).then(response => {
        if (response.payload.rawData.results.length > 0) {
            callback({
                link: encodeURI(`${ZENDESK_URL}${endUrl}`),
                nbTickets: response.payload.rawData.results.length,
            });
        } else {
            callback(null);
        }
    });
};
