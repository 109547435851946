import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from '@components/form/input/Checkbox';

const FieldCheckbox = ({ field, checked, toggle, className }) => (
    <Checkbox
        id={field}
        className={className}
        htmlEl="li"
        renderInput={(className, id) => (
            <input id={id} type="checkbox" checked={checked} className={className} onChange={toggle} />
        )}
        labelSecondary
        label={<FormattedMessage id={`products.configuration.fields.${field}`} />}
    />
);

FieldCheckbox.propTypes = {
    field: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default FieldCheckbox;
