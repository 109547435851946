import { FiltersParams, SortParams } from '@types';
import { listActionCreator, withPage, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl, getFilterParams, idAttribute } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl, idAttribute);
export const TYPES = types;

export default (
    page: number | null = 1,
    sort: SortParams | null = null,
    filters: FiltersParams = {},
    query: object | null = null
) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const filterParams = getFilterParams(filters);

    return withValues({
        sort,
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filterParams,
        })(withPage(page)(actionCreator()))
    );
};
