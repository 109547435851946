/* eslint-disable prefer-const */
import {
    TOGGLE_SIDEBAR,
    CHANGE_SITE,
    CHANGE_WORKSPACE,
    CHANGE_BRAND,
    CHANGE_LOCALE,
    CHANGE_LANGUAGE,
    CHANGE_THEME,
    SET_USER_CONFIGURATION,
    SET_MENU_FAVORITES,
    SET_DASHBOARD,
} from '@actions/ui';
import { FETCH_REFERENCES_SUCCESS } from '@actions/references';
import { TYPES as LIST_WORKSPACES_TYPES } from '@actions/workspaces/list';
import { schema as workspaceSchema } from '@actions/workspaces/config';
import { DEFAULT_BRAND_CODE, DEFAULT_BRAND_LABEL } from '@utils/brands';
import { STATUS_OFFLINE, findActiveWorkspaceForSite } from '@utils/workspaces';
import { DEFAULT_LOCALE } from '@utils/DefaultLocaleDataHoc';
import { Brands, Site, Workspace, References } from '@types';
import { getUserLocale, SupportedLocales } from '@utils/intl';
import { MenuItem, Theme } from 'sezane-components';
import { Layouts } from '@containers/dashboard';

const DEFAULT_SITE_ID = 1;

type UiState = {
    showSidebar: boolean;
    theme: Theme;
    site: Site;
    brand: Partial<References.Brand>;
    locale: {
        code: string;
        label: string;
    };
    language: SupportedLocales;
    workspace: Workspace | null;
    menuFavorites: MenuItem[];
    dashboard: Layouts | null;
};

export const INITIAL_STATE: UiState = {
    showSidebar: true,
    theme: 'light',
    site: {
        id: DEFAULT_SITE_ID,
    } as Site,
    brand: {
        code: DEFAULT_BRAND_CODE,
        label: DEFAULT_BRAND_LABEL,
        sites: [],
    },
    locale: { code: DEFAULT_LOCALE, label: 'Français' },
    language: getUserLocale(),
    workspace: null,
    menuFavorites: [],
    dashboard: null,
};

export default function (state = INITIAL_STATE, action: any): UiState {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return { ...state, showSidebar: !state.showSidebar };
        case CHANGE_THEME:
            return { ...state, theme: action.theme };
        case CHANGE_SITE:
            const site = action.site || { id: DEFAULT_SITE_ID };

            return {
                ...state,
                site,
                workspace: action.workspace,
                locale: site.translationLocale ? site.translationLocale : site.defaultLocale,
            };
        case CHANGE_WORKSPACE:
            return {
                ...state,
                workspace: action.workspace || null,
            };
        case CHANGE_BRAND: {
            const site = action.brand.sites.find((site: Site) => site.code === 'fr');
            const workspace = site.currentWorkspace || null;

            return {
                ...state,
                brand: action.brand,
                site,
                workspace,
            };
        }
        case CHANGE_LOCALE: {
            return {
                ...state,
                locale: action.locale,
            };
        }
        case CHANGE_LANGUAGE: {
            return {
                ...state,
                language: action.language,
            };
        }
        case FETCH_REFERENCES_SUCCESS: {
            const currentBrand = action.payload.rawData.brands.find((brand: Brands) => brand.code === state.brand.code);
            const site = currentBrand.sites.find((s: Site) => s.id === state.site.id) || currentBrand.sites[0];
            let workspace = state.workspace;

            if (!workspace || !site.workspacesIds.includes(workspace.id)) {
                workspace = site.currentWorkspace;
            }

            return {
                ...state,
                brand: currentBrand,
                site,
                workspace,
                locale: site.translationLocale ? site.translationLocale : site.defaultLocale,
            };
        }
        case LIST_WORKSPACES_TYPES.LIST_SUCCESS: {
            if (!state.workspace) {
                return state;
            }

            const workspaces = action.payload.entities[workspaceSchema];
            const workspace: Workspace = workspaces[state.workspace.id!];

            if (
                state.workspace?.status === STATUS_OFFLINE ||
                !Object.values<Workspace>(workspaces).find(workspace => workspace.id === state.workspace?.id)
            ) {
                return {
                    ...state,
                    workspace: findActiveWorkspaceForSite(Object.values(workspaces), state.site.id!),
                };
            } else if (workspace) {
                return {
                    ...state,
                    workspace,
                };
            }

            return state;
        }
        case SET_MENU_FAVORITES: {
            return {
                ...state,
                menuFavorites: action.menuFavorites,
            };
        }
        case SET_DASHBOARD: {
            return {
                ...state,
                dashboard: action.dashboard,
            };
        }
        case SET_USER_CONFIGURATION: {
            return {
                ...state,
                theme: action.userConfiguration.theme,
                dashboard: action.userConfiguration.dashboard,
                menuFavorites: action.userConfiguration.favorites,
            };
        }
        default:
            return state;
    }
}
