export const PAGES_DEFAULT_SEARCH =
    '?isTree=true&enabled=true&itemsPerPage=150&page=1&sort%5Bfield%5D=enabled&sort%5Bdirection%5D=DESC';

export default {
    DASHBOARD: '/',
    CREATE_BRAND: '/brands/create',
    CREATE_COMMERCIAL_OPERATION: '/commercial-operations/create',
    CREATE_COMPOSITION_LAYOUT: '/composition-layouts/create',
    CREATE_CUSTOMISATION_SERVICES: '/customisation-services/create',
    CREATE_MULTI_PRODUCT: '/multi-products/create',
    CREATE_ORDER_RETURN_MODES: '/order-return-modes/create',
    CREATE_PACKAGING: '/packagings/create',
    CREATE_POPIN: '/popins/create',
    CREATE_PUSH_PRODUCT: '/push-products/create',
    CREATE_SALE: '/sales/create',
    CREATE_SCHEDULED_UPDATE: '/scheduled-updates/create',
    CREATE_SHIPPING_MODE: '/shipping-modes/create',
    CREATE_SHOPS: '/shops/create',
    CREATE_SITE: '/sites/create',
    CREATE_VOUCHER_CAMPAIGN: '/voucher-campaigns/create',
    CREATE_WAREHOUSE: '/warehouses/create',
    EDIT_COMMERCIAL_OPERATION: '/commercial-operations/:id',
    EDIT_SCHEDULED_UPDATE: '/scheduled-updates/edit/:id',
    FOOTER: '/footer',
    LINKED_RETURN_MODES_SHIPPING_MODE: '/shipping-modes/:id/linked-return-modes',
    LIST_CARTS: '/carts',
    LIST_COLOR_PARAMETERS: '/color-parameters',
    LIST_COMMERCIAL_OPERATIONS: '/commercial-operations',
    LIST_COMPOSITION_LAYOUTS: '/composition-layouts',
    LIST_CRONS: '/crons',
    LIST_CUSTOMISATION_SERVICES: '/customisation-services',
    LIST_ECARDS: '/ecards',
    LIST_EMAIL_TEMPLATES: '/email-templates',
    LIST_EVERGREEN_MEDIAS: '/evergreen-medias',
    LIST_JOBS: '/jobs',
    LIST_LOG_REPORTS: '/log-reports',
    LIST_MOBILE_CONTENTS: '/mobile-contents',
    LIST_MODELS: '/models',
    LIST_MULTI_PRODUCTS: '/multi-products',
    LIST_NEWSLETTER_SUBSCRIBERS: '/newsletter-subscribers',
    LIST_ORDER_RETURN_MODES: '/order-return-modes',
    LIST_ORDERS: '/orders',
    LIST_PACKAGINGS: '/packagings',
    LIST_PAGES: '/pages',
    LIST_PARAMETERS: '/parameters',
    LIST_POPINS: '/popins',
    LIST_PREORDERS: '/preorders',
    LIST_PRODUCTS: '/products',
    LIST_PUSH_PRODUCTS: '/push-products',
    LIST_RETURNS: '/returns',
    LIST_ROLES: '/roles',
    LIST_SALES_STATISTICS: '/sales-statistics',
    LIST_SALES_TAX: '/sales-tax',
    LIST_SALES: '/sales',
    LIST_SCHEDULED_UPDATES: '/scheduled-updates',
    LIST_SEQUENCINGS: '/sequencings',
    LIST_SHIPPING_MODES: '/shipping-modes',
    LIST_SHOPS: '/shops',
    LIST_SITES: '/sites',
    LIST_STOCK_ALERTS: '/stock-alerts',
    LIST_USER_CREDITS: '/user-credits',
    LIST_USER_TAGS: '/user-tags',
    LIST_USERS: '/users',
    LIST_VAT: '/vat',
    LIST_VOUCHER_CAMPAIGNS: '/voucher-campaigns',
    LIST_WAREHOUSES: '/warehouses',
    LIST_WORKSPACES: '/workspaces',
    LOGIN: '/login',
    LOGISTIC_FILES: '/logistic-files',
    LOGOUT: '/logout',
    MAIN_MENU: '/menu',
    MEDIA_LIBRARY: '/media-library',
    NOT_FOUND: '/not-found',
    PRE_FOOTER: '/pre-footer',
    MASS_ORDERS: '/orders/mass-orders',
    REORDER: '/orders/:id/reorder',
    SHOW_CART: '/carts/:id',
    SHOW_ORDER: '/orders/:id',
    SHOW_RETURN: '/returns/:id',
    SHOW_SEQUENCING: '/sequencings/:id',
    SHOW_USER: '/users/:id',
    SHOW_VOUCHER_CAMPAIGN: '/voucher-campaigns/:id',
    STOCK_SHOP_VIEW: '/stock-shop-search',
    STOCK_VIEW_SEARCH: '/stock-view-search',
    STOCK_VIEW: '/stock-view',
    STYLEGUIDE_FORM: '/styleguide/form',
    STYLEGUIDE_SELECTS: '/styleguide/selects',
    STYLEGUIDE: '/styleguide',
    UPDATE_COMPOSITION_LAYOUT: '/composition-layouts/:id',
    UPDATE_CUSTOMISATION_SERVICES: '/customisation-services/:id',
    UPDATE_EMAIL_TEMPLATE: '/email-templates/:id',
    UPDATE_MANDATORY_LOGIN: '/mandatory-login',
    UPDATE_MULTI_PRODUCT: '/multi-products/:id',
    UPDATE_ORDER_RETURN_MODES: '/order-return-modes/:id',
    UPDATE_PAGE: '/pages/:id',
    UPDATE_POPIN: '/popins/:id',
    UPDATE_PRODUCT_WITH_VARIANT: '/products/:id?catalogVariantId=:variantId',
    UPDATE_PRODUCT: '/products/:id',
    UPDATE_PUSH_PRODUCT: '/push-products/:id',
    UPDATE_SALE: '/sales/:id/edit',
    UPDATE_SHIPPING_MODE: '/shipping-modes/:id',
    UPDATE_SHOPS: '/shops/:id',
    UPDATE_SITE: '/sites/:id',
    UPDATE_VOUCHER_CAMPAIGN: '/voucher-campaigns/:id/update',
    UPDATE_WAREHOUSE: '/warehouses/:id',
};
