import React, { FC, ReactElement } from 'react';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';

const TranslationWarning: FC<TranslationWarningProps> = ({ warning }) => {
    if (isString(warning)) {
        return (
            <p className="u-c(warning) u-mt(xs)">
                <FormattedMessage id={warning} />
            </p>
        );
    }

    return warning;
};

interface TranslationWarningProps {
    warning: ReactElement;
}

export default TranslationWarning;
