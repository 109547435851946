import { addOnlyTrueFilter, addRangeFilter } from '@utils/filters';

export const schema = 'popin';
export const name = 'POPIN';
export const baseUrl = 'ecommerce-private/v1/popins';

export const getFilterParams = filters => {
    const { onlyActive, beginBefore, beginAfter, ...otherFilters } = filters || {};

    const filterParams = { ...otherFilters };

    addOnlyTrueFilter('active', onlyActive, filterParams);

    addRangeFilter('beginOn', beginAfter, beginBefore, filterParams);

    return filterParams;
};
