import { addOnlyTrueFilter } from '@utils/filters';

export const schema = 'media';
export const name = 'MEDIA';
export const baseUrl = 'ecommerce-private/v1/medias';

export const getFilterParams = filters => {
    const {
        videoOnly,
        hideDisabled,
        lastImportOnly,
        hardVisualOnly,
        newsVisualOnly,
        moodVisualOnly,
        shopTheLookOnly,
        ...otherFilters
    } = filters || {};

    const filterParams = { ...otherFilters };

    addOnlyTrueFilter('video', videoOnly, filterParams);
    addOnlyTrueFilter('hideDisabled', hideDisabled, filterParams);
    addOnlyTrueFilter('lastImportOnly', lastImportOnly, filterParams);
    addOnlyTrueFilter('hardVisualOnly', hardVisualOnly, filterParams);
    addOnlyTrueFilter('newsVisualOnly', newsVisualOnly, filterParams);
    addOnlyTrueFilter('moodVisualOnly', moodVisualOnly, filterParams);
    addOnlyTrueFilter('shopTheLookOnly', shopTheLookOnly, filterParams);

    return filterParams;
};
