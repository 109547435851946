import { schema } from '@actions/oms/shippingModes/config';
import { TYPES as GET_TYPES } from '@actions/oms/shippingModes/get';
import { TYPES as LIST_TYPES } from '@actions/oms/shippingModes/list';
import { TYPES as UPDATE_TYPES } from '@actions/oms/shippingModes/update';
import { TYPES as DELETE_TYPES } from '@actions/oms/shippingModes/delete';
import { CRUD_INITIAL_STATE, deleteReducer, getReducer, listReducer, reduceReducers, updateReducer } from '../crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    deleteReducer(DELETE_TYPES)
);
