import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import zipObject from 'lodash/zipObject';

import changeEditionConfiguration from '@actions/catalogProducts/configuration';
import FieldsConfiguration from '../configuration';

const FIELDS = {
    prices: null,
    description: null,
    photos: null,
    availability: null,
    seo: null,
    cross_sell: null,
    reference_media: null,
    additional_content: null,
};

const HIDDEN_FIELDS = ['reference_media'];

export const DEFAULT_PRESET = zipObject(
    Object.keys(FIELDS),
    Object.keys(FIELDS).map(key => !HIDDEN_FIELDS.includes(key))
);

const Configuration = ({ configuration, changeConfiguration }) => {
    const toggleConfigurationValue = fieldKey => {
        const values = {
            [fieldKey]: !configuration[fieldKey],
        };

        changeConfiguration(values);
    };

    return (
        <FieldsConfiguration
            configuration={configuration}
            fields={FIELDS}
            toggleField={toggleConfigurationValue}
            icon="eye"
        />
    );
};

Configuration.propTypes = {
    configuration: PropTypes.object.isRequired,
    changeConfiguration: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    configuration: state.catalogProducts.editionConfiguration,
});

const mapDispatchToProps = dispatch => ({
    changeConfiguration: values => dispatch(changeEditionConfiguration(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
