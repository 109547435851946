import { listActionCreator, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, saleId = null, pagination = true) =>
    withValues({
        pagination,
        page,
    })(
        withRequestParams({
            pagination: pagination,
            page: pagination === true && !!page ? page : undefined,
            sale: saleId || undefined,
        })(actionCreator())
    );
