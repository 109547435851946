import { schema } from '@actions/logReports/config';
import { TYPES as LIST_TYPES } from '@actions/logReports/list';
import { TYPES as MARK_AS_TREATED_TYPES } from '@actions/logReports/markAsTreated';
import { reduceReducers, listReducer, batchUpdateReducer, CRUD_INITIAL_STATE } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    batchUpdateReducer(MARK_AS_TREATED_TYPES, schema, {
        init: 'MARK_AS_TREATED',
        success: 'MARK_AS_TREATED_SUCCESS',
        failure: 'MARK_AS_TREATED_FAILURE',
    }),
    listReducer(LIST_TYPES, schema)
);
