import apiSchema from '@config/schema';
import getNotifier, { SUCCESS } from '@config/notifier';

export const ID_PLACEHOLDER = '{ID}';
export const STATUS_PENDING = 'pending';
const ID_ARRAY_KEY = 'ids[]';

export const withValues = values => action => ({
    ...action,
    ...values,
});
export const withRequestHeaders = params => action => {
    action.payload.request.headers = {
        ...action.payload.request.headers,
        ...params,
    };

    return action;
};
export const withRequestParams = params => action => {
    action.payload.request.params = {
        ...action.payload.request.params,
        ...params,
    };

    return action;
};
export const withRequestData = data => action => {
    action.payload.request.data = {
        ...action.payload.request.data,
        ...data,
    };

    return action;
};
export const withWorkspace = workspaceId => action => {
    return withRequestParams({
        workspace: workspaceId || undefined,
    })(action);
};
export const withPage = page => action => {
    const newAction = withRequestParams({
        page,
    })(action);
    newAction.page = page;

    return newAction;
};
export const withNoSite = action => {
    action.noSite = true;

    return action;
};
export const withMultipleSite = action => {
    action.multipleSite = true;

    return action;
};
export const withNoBrand = action => {
    action.noBrand = true;

    return action;
};

export const notifyPendingCreation = (action, message, schema) => {
    return dispatch =>
        dispatch(action).then(data => {
            let pending = false;

            if (schema !== null) {
                const entity = data.payload.entities[schema][data.payload.result];
                pending = entity && entity.creationPending;
            } else {
                pending = data.payload.rawData.status === STATUS_PENDING;
            }

            if (message !== null && pending) {
                const Notifier = getNotifier();
                Notifier.notify(SUCCESS, {
                    id: message,
                });
            }

            return data;
        });
};

const getUpdateSchema = (schemaType, schema, idAttribute, context) => {
    if (schemaType) {
        return schemaType(schema, idAttribute);
    }

    if (context.preserveVersions) {
        return apiSchema.LIST(schema, idAttribute);
    }

    return apiSchema.ENTITY(schema, idAttribute);
};

export const listActionCreator = (type, schema, url, idAttribute) => {
    const types = {
        LIST: `LIST_${type}`,
        LIST_SUCCESS: `LIST_${type}_SUCCESS`,
        LIST_FAILURE: `LIST_${type}_FAILURE`,
    };

    const actionCreator = source => ({
        types: [types.LIST, types.LIST_SUCCESS, types.LIST_FAILURE],
        schema: apiSchema.LIST(schema, idAttribute),
        payload: {
            request: {
                url: url,
                method: 'GET',
                params: {},
                cancelToken: source?.token,
            },
        },
    });

    return {
        types,
        actionCreator,
    };
};

export const addNewActionCreator = (type, initialValues) => {
    const types = {
        ADD_NEW: `ADD_NEW_${type}`,
    };

    const actionCreator = (values = initialValues) => ({
        type: types.ADD_NEW,
        values,
    });

    return {
        types,
        actionCreator,
    };
};

export const createActionCreator = (type, schema, url, idAttribute) => {
    const types = {
        CREATE: `CREATE_${type}`,
        CREATE_SUCCESS: `CREATE_${type}_SUCCESS`,
        CREATE_FAILURE: `CREATE_${type}_FAILURE`,
    };

    const actionCreator = (values, context) => ({
        types: [types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILURE],
        schema: apiSchema.ENTITY(schema, idAttribute),
        payload: {
            request: {
                url: url,
                method: 'POST',
                data: values,
            },
        },
        context,
    });

    return {
        types,
        actionCreator,
    };
};

export const getActionCreator = (type, schema, url, idAttribute) => {
    const types = {
        GET: `GET_${type}`,
        GET_SUCCESS: `GET_${type}_SUCCESS`,
        GET_FAILURE: `GET_${type}_FAILURE`,
    };

    const actionCreator = id => ({
        types: [types.GET, types.GET_SUCCESS, types.GET_FAILURE],
        schema: apiSchema.ENTITY(schema, idAttribute),
        payload: {
            request: {
                url: url.replace(ID_PLACEHOLDER, id),
                method: 'GET',
            },
        },
    });

    return {
        types,
        actionCreator,
    };
};

export const updateActionCreator = (type, schema, url, idAttribute) => {
    const types = {
        UPDATE: `UPDATE_${type}`,
        UPDATE_SUCCESS: `UPDATE_${type}_SUCCESS`,
        UPDATE_FAILURE: `UPDATE_${type}_FAILURE`,
    };

    const actionCreator = (id, values, context = {}) => ({
        types: [types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILURE],
        schema: getUpdateSchema(null, schema, idAttribute, context),
        payload: {
            request: {
                url: url.replace(ID_PLACEHOLDER, id),
                method: 'PUT',
                data: values,
            },
        },
        context,
    });

    return {
        types,
        actionCreator,
    };
};

export const batchUpdateActionCreator = (type, schema, url, idAttribute) => {
    const types = {
        BATCH_UPDATE: `BATCH_UPDATE_${type}`,
        BATCH_UPDATE_SUCCESS: `BATCH_UPDATE_${type}_SUCCESS`,
        BATCH_UPDATE_FAILURE: `BATCH_UPDATE_${type}_FAILURE`,
    };

    const actionCreator = (values, context) => ({
        types: [types.BATCH_UPDATE, types.BATCH_UPDATE_SUCCESS, types.BATCH_UPDATE_FAILURE],
        schema: apiSchema.LIST(schema, idAttribute),
        payload: {
            request: {
                url: url,
                method: 'PUT',
                data: values,
            },
        },
        context,
    });

    return {
        types,
        actionCreator,
    };
};

export const deleteActionCreator = (type, url) => {
    const types = {
        DELETE: `DELETE_${type}`,
        DELETE_SUCCESS: `DELETE_${type}_SUCCESS`,
        DELETE_FAILURE: `DELETE_${type}_FAILURE`,
        DELETE_NEW: `DELETE_NEW_${type}`,
    };

    const actionCreator = (idOrArray, context) => {
        const array = Array.isArray(idOrArray);

        // map to query string array if needed
        const urlRequest = array
            ? url.replace(
                  ID_PLACEHOLDER,
                  `${ID_ARRAY_KEY}=${idOrArray.map(stockAlert => stockAlert.id).join(`&${ID_ARRAY_KEY}=`)}`
              )
            : url.replace(ID_PLACEHOLDER, idOrArray);

        if (idOrArray) {
            return {
                types: [types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILURE],
                payload: {
                    request: {
                        url: urlRequest,
                        method: 'DELETE',
                    },
                },
                id: array ? idOrArray.map(stockAlert => stockAlert.id) : idOrArray,
                context,
            };
        }
        return {
            type: types.DELETE_NEW,
        };
    };

    return {
        types,
        actionCreator,
    };
};

export const patchActionCreator = (type, schema, url, idAttribute, schemaType) => {
    const types = {
        PATCH: `PATCH_${type}`,
        PATCH_SUCCESS: `PATCH_${type}_SUCCESS`,
        PATCH_FAILURE: `PATCH_${type}_FAILURE`,
    };

    const actionCreator = (id, values, context = {}) => ({
        types: [types.PATCH, types.PATCH_SUCCESS, types.PATCH_FAILURE],
        schema: getUpdateSchema(schemaType, schema, idAttribute, context),
        payload: {
            request: {
                url: url.replace(ID_PLACEHOLDER, id),
                method: 'PATCH',
                data: values,
            },
        },
        context,
    });

    return {
        types,
        actionCreator,
    };
};

export const exportActionCreator = (
    type,
    schema,
    url,
    idAttribute,
    { method = 'GET', accept = 'text/csv', responseType = 'blob' }
) => {
    const types = {
        EXPORT: `EXPORT_${type}`,
        EXPORT_SUCCESS: `EXPORT_${type}_SUCCESS`,
        EXPORT_FAILURE: `EXPORT_${type}_FAILURE`,
    };

    const actionCreator = id => ({
        types: [types.EXPORT, types.EXPORT_SUCCESS, types.EXPORT_FAILURE],
        schema: apiSchema.ENTITY(schema, idAttribute),
        payload: {
            request: {
                headers: {
                    Accept: accept,
                },
                responseType,
                url: url.replace(ID_PLACEHOLDER, id),
                method: method,
                params: {},
            },
        },
    });

    return {
        types,
        actionCreator,
    };
};

export const changeListConfigurationCreator = type => {
    const types = {
        CHANGE_LIST_CONFIGURATION: `CHANGE_${type}_LIST_CONFIGURATION`,
    };

    const actionCreator = values => ({
        type: types.CHANGE_LIST_CONFIGURATION,
        values,
    });

    return {
        types,
        actionCreator,
    };
};

export const asyncExportActionCreator = (type, url) => {
    const types = {
        ASYNC_EXPORT: `ASYNC_EXPORT_${type}`,
        ASYNC_EXPORT_SUCCESS: `ASYNC_EXPORT_${type}_SUCCESS`,
        ASYNC_EXPORT_FAILURE: `ASYNC_EXPORT_${type}_FAILURE`,
    };

    const actionCreator = () => ({
        types: [types.ASYNC_EXPORT, types.ASYNC_EXPORT_SUCCESS, types.ASYNC_EXPORT_FAILURE],
        payload: {
            request: {
                url: url,
                method: 'GET',
                params: {},
            },
        },
    });

    return {
        types,
        actionCreator,
    };
};
