import { listActionCreator, withPage, withRequestParams, withValues, withNoSite, withNoBrand } from '../../crud';
import { name, schema, baseUrl, Filters } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, sort: any = null, filters?: Filters, query: any = null) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    if (sort && sort.field !== 'startAt') {
        sortParam['order[startAt]'] = 'DESC';
    }

    const filterParams: Record<string, any> = {};
    if (filters) {
        if (filters.enabledOrScheduled) {
            filterParams.enabledOrScheduled = true;
        }
        filterParams.brands = filters.brands || undefined;
        filterParams.recipientType = filters.recipientType || undefined;
        filterParams.searchOnCode = filters.searchOnCode || undefined;
        filterParams.sites = filters.sites || undefined;
        filterParams.sources = filters.sources || undefined;
        filterParams.isExcludedRetailOnly = filters.isExcludedRetailOnly || undefined;
        filterParams.currencies = filters.currencies?.map(c => c.toLowerCase()) || undefined;
    }

    return withValues({
        sort,
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filterParams,
        })(withPage(page)(withNoSite(withNoBrand(actionCreator()))))
    );
};
