import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(() => import(/* webpackChunkName: "sites.Create" */ '@containers/sites/Create'));
const Update = lazy(() => import(/* webpackChunkName: "sites.Update" */ '@containers/sites/Update'));
const List = lazy(() => import(/* webpackChunkName: "sites.List" */ '@containers/sites/List'));

export default [
    <Route path={routesNames.CREATE_SITE} component={Create} exact={true} key="create" />,
    <Route path={routesNames.UPDATE_SITE} component={Update} exact={true} key="show" />,
    <Route path={routesNames.LIST_SITES} component={List} strict={true} key="list" />,
];
