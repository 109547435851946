import React from 'react';

export default function Image() {
    return (
        <img
            src="https://media.sezane.com/image/upload/evergreen_media/account_sezane.jpg"
            alt="Visuel dashboard"
            style={{ objectFit: 'cover', width: '100%', height: '100%', objectPosition: 'top' }}
        />
    );
}
