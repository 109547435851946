import { schema } from '@actions/models/config';
import { TYPES as CREATE_TYPES } from '@actions/models/create';
import { TYPES as ADD_TYPES } from '@actions/models/add';
import { TYPES as GET_TYPES } from '@actions/models/get';
import { TYPES as DELETE_TYPES } from '@actions/models/delete';
import { TYPES as LIST_TYPES } from '@actions/models/list';
import { TYPES as UPDATE_TYPES } from '@actions/models/update';
import {
    reduceReducers,
    deleteReducer,
    createReducer,
    getReducer,
    listReducer,
    updateReducer,
    addNewReducer,
    CRUD_INITIAL_STATE,
    CRUD_NEW_ENTITY_KEY,
} from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;
export const NEW_MODEL_KEY = CRUD_NEW_ENTITY_KEY;

export default reduceReducers(
    createReducer(CREATE_TYPES, schema),
    addNewReducer(ADD_TYPES),
    deleteReducer(DELETE_TYPES),
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema)
);
