// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { createSelectorHook, useDispatch } from 'react-redux';
import type { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import type { AppDispatch } from '@index';
import type { RootState } from '@reducers';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector = createSelectorHook<RootState, AnyAction>();
