import remove from 'lodash/remove';
import uniq from 'lodash/uniq';

export const CRUD_INITIAL_STATE = {
    loading: false,
    entities: {},
    page: 1,
    total: 0,
    sortedIds: [],
    sort: null,
    filters: {},
    error: false,
    listConfiguration: null,
    loaders: {
        create: false,
        update: false,
        delete: false,
        list: false,
        get: false,
        export: false,
    },
};

export const CRUD_NEW_ENTITY_KEY = 'newEntity';

export const reduceReducers = (...args) => {
    const initialState = typeof args[args.length - 1] !== 'function' ? args.pop() : CRUD_INITIAL_STATE;
    const reducers = args;

    return (prevState, action, ...args) => {
        const prevStateIsUndefined = typeof prevState === 'undefined';
        const actionIsUndefined = typeof action === 'undefined';

        if (prevStateIsUndefined && actionIsUndefined && initialState) {
            return initialState;
        }

        return reducers.reduce(
            (newState, reducer, index) => {
                if (typeof reducer === 'undefined') {
                    throw new TypeError(`An undefined reducer was passed in at index ${index}`);
                }

                return reducer(newState, action, ...args);
            },
            prevStateIsUndefined && !actionIsUndefined && initialState ? initialState : prevState
        );
    };
};

export const LIST_TYPE_KEYS = {
    init: 'LIST',
    success: 'LIST_SUCCESS',
    failure: 'LIST_FAILURE',
};

export const listReducer =
    (types, schema, typeKeys = LIST_TYPE_KEYS) =>
    (state, action) => {
        const { save, pagination, sort, filters } = action;

        switch (action.type) {
            case types[typeKeys.init]: {
                if (save === false) {
                    return state;
                }

                const page = pagination !== false ? state.page || 1 : null;

                return {
                    ...state,
                    loading: true,
                    loaders: {
                        ...state.loaders,
                        list: true,
                    },
                    page,
                    sort,
                    filters,
                    error: false,
                };
            }
            case types[typeKeys.success]: {
                const { pagination, save } = action.meta.previousAction;
                if (save === false) {
                    return state;
                }

                const entities = action.payload.entities[schema] || {};
                const newEntity = state.entities[CRUD_NEW_ENTITY_KEY];
                if (newEntity) {
                    entities[CRUD_NEW_ENTITY_KEY] = newEntity;
                }

                const total = action.payload.total || Object.keys(entities).length;
                const page = action.payload.page || action.meta.previousAction.page || 1;
                const newPage = pagination !== false ? page : null;
                const sortedIds = uniq(action.payload.result);

                return {
                    ...state,
                    entities,
                    page: newPage,
                    total,
                    loading: false,
                    loaders: {
                        ...state.loaders,
                        list: false,
                    },
                    sortedIds,
                    error: false,
                };
            }
            case types[typeKeys.failure]: {
                if (action.meta.previousAction.save === false) {
                    return state;
                }

                return {
                    ...state,
                    loading: false,
                    loaders: {
                        ...state.loaders,
                        list: false,
                    },
                    error: true,
                };
            }

            default:
                return state;
        }
    };

const GET_TYPE_KEYS = {
    init: 'GET',
    success: 'GET_SUCCESS',
    failure: 'GET_FAILURE',
};

export const getReducer =
    (types, schema, typeKeys = GET_TYPE_KEYS) =>
    (state, action) => {
        switch (action.type) {
            case types[typeKeys.init]: {
                return {
                    ...state,
                    loading: true,
                    loaders: {
                        ...state.loaders,
                        get: true,
                    },
                    error: false,
                };
            }
            case types[typeKeys.success]: {
                const entities = {
                    ...state.entities,
                    ...(action.payload.entities[schema] || {}),
                };

                return {
                    ...state,
                    entities,
                    loading: false,
                    loaders: {
                        ...state.loaders,
                        get: false,
                    },
                    error: false,
                };
            }
            case types[typeKeys.failure]: {
                return {
                    ...state,
                    loading: false,
                    loaders: {
                        ...state.loaders,
                        get: false,
                    },
                    error: true,
                };
            }

            default:
                return state;
        }
    };

const CREATE_TYPE_KEYS = {
    init: 'CREATE',
    success: 'CREATE_SUCCESS',
    failure: 'CREATE_FAILURE',
};

export const createReducer =
    (types, schema, typeKeys = CREATE_TYPE_KEYS) =>
    (state, action) => {
        switch (action.type) {
            case types[typeKeys.init]: {
                return { ...state, loading: true, error: false };
            }
            case types[typeKeys.success]: {
                const entities = {
                    ...state.entities,
                    ...(action.payload.entities[schema] || {}),
                };

                delete entities[CRUD_NEW_ENTITY_KEY];

                const sortedIds = [...state.sortedIds];
                if (action.payload.rawData && action.payload.rawData.id) {
                    sortedIds.push(action.payload.rawData.id);
                }

                return {
                    ...state,
                    entities,
                    sortedIds,
                    total: state.total + 1,
                    loading: false,
                    error: false,
                };
            }
            case types[typeKeys.failure]: {
                return { ...state, loading: false, error: true };
            }

            default:
                return state;
        }
    };

const ADD_NEW_TYPE_KEY = 'ADD_NEW';

export const addNewReducer =
    types =>
    (state, action, typeKey = ADD_NEW_TYPE_KEY) => {
        switch (action.type) {
            case types[typeKey]: {
                const entities = {
                    [CRUD_NEW_ENTITY_KEY]: action.values,
                    ...state.entities,
                };

                return { ...state, entities };
            }

            default:
                return state;
        }
    };

const UPDATE_TYPE_KEYS = {
    init: 'UPDATE',
    success: 'UPDATE_SUCCESS',
    failure: 'UPDATE_FAILURE',
};

export const updateReducer =
    (types, schema, override = false, typeKeys = UPDATE_TYPE_KEYS) =>
    (state, action) => {
        switch (action.type) {
            case types[typeKeys.init]: {
                return { ...state, loading: true, error: false };
            }
            case types[typeKeys.success]: {
                const newEntities = action.payload.entities[schema] || {};
                const entities = {
                    ...state.entities,
                    ...newEntities,
                };
                const sortedIds = [...state.sortedIds];

                const preserveVersions = action.meta?.previousAction?.context?.preserveVersions || false;
                // Original entity was replaced (duplication for workspaces aware entities)
                if (override && !preserveVersions) {
                    const resultId = parseInt(action.payload.result, 10);
                    const originalEntityId = parseInt(action.meta?.previousAction?.id, 10);
                    if (resultId && originalEntityId && resultId !== originalEntityId) {
                        delete entities[originalEntityId.toString()];
                        const index = sortedIds.findIndex(id => id === originalEntityId);
                        sortedIds.splice(index, 1, resultId);
                    }
                }

                return {
                    ...state,
                    entities,
                    sortedIds,
                    loading: false,
                    error: false,
                };
            }
            case types[typeKeys.failure]: {
                return { ...state, loading: false, error: true };
            }

            default:
                return state;
        }
    };

const BATCH_UPDATE_TYPE_KEYS = {
    init: 'BATCH_UPDATE',
    success: 'BATCH_UPDATE_SUCCESS',
    failure: 'BATCH_UPDATE_FAILURE',
};

export const batchUpdateReducer = (types, schema, typeKeys = BATCH_UPDATE_TYPE_KEYS) =>
    updateReducer(types, schema, false, typeKeys);

const PATCH_TYPE_KEYS = {
    init: 'PATCH',
    success: 'PATCH_SUCCESS',
    failure: 'PATCH_FAILURE',
};

export const patchReducer = (types, schema, override = false, typeKeys = PATCH_TYPE_KEYS) =>
    updateReducer(types, schema, override, typeKeys);

const DELETE_TYPE_KEYS = {
    init: 'DELETE',
    initNew: 'DELETE_NEW',
    success: 'DELETE_SUCCESS',
    failure: 'DELETE_FAILURE',
};

export const deleteReducer =
    types =>
    (state, action, typeKeys = DELETE_TYPE_KEYS) => {
        switch (action.type) {
            case types[typeKeys.init]: {
                return {
                    ...state,
                    loading: true,
                    sort: action.sort || null,
                    filters: action.filters || {},
                    error: false,
                };
            }
            case types[typeKeys.initNew]: {
                const entities = { ...state.entities };
                delete entities[CRUD_NEW_ENTITY_KEY];

                const sortedIds = [...state.sortedIds];
                if (sortedIds.includes(CRUD_NEW_ENTITY_KEY)) {
                    remove(sortedIds, itemId => CRUD_NEW_ENTITY_KEY === itemId);
                }

                return {
                    ...state,
                    entities,
                    sortedIds,
                    loading: false,
                    error: false,
                };
            }
            case types[typeKeys.success]: {
                const entities = { ...state.entities };
                const ids = action.meta.previousAction.ids || [action.meta.previousAction.id];
                let deleted = 0;
                const sortedIds = [...state.sortedIds];

                ids.forEach(id => {
                    if (entities[id] !== undefined) {
                        delete entities[id];
                        deleted++;
                    }
                    // eslint-disable-next-line
                    remove(sortedIds, itemId => id == itemId);
                });

                const newTotal = state.total - deleted;

                return {
                    ...state,
                    entities,
                    sortedIds,
                    loading: false,
                    total: newTotal,
                    error: false,
                };
            }
            case types[typeKeys.failure]: {
                return {
                    ...state,
                    loading: false,
                    error: true,
                };
            }

            default:
                return state;
        }
    };

const BATCH_DELETE_TYPE_KEYS = {
    init: 'BATCH_DELETE',
    success: 'BATCH_DELETE_SUCCESS',
    failure: 'BATCH_DELETE_FAILURE',
};

export const batchDeleteReducer = (types, schema, typeKeys = BATCH_DELETE_TYPE_KEYS) =>
    deleteReducer(types, schema, typeKeys);

const CONFIGURATION_TYPE_KEYS = {
    changeList: 'CHANGE_LIST_CONFIGURATION',
};

export const configurationReducer =
    (types, fields) =>
    (state, action, typeKeys = CONFIGURATION_TYPE_KEYS) => {
        switch (action.type) {
            case types[typeKeys.changeList]: {
                return {
                    ...state,
                    listConfiguration: {
                        ...state.listConfiguration,
                        ...action.values,
                    },
                };
            }

            default:
                return state;
        }
    };

const ASYNC_EXPORT_TYPE_KEYS = {
    init: 'ASYNC_EXPORT',
    success: 'ASYNC_EXPORT_SUCCESS',
    failure: 'ASYNC_EXPORT_FAILURE',
};

export const asyncExportReducer =
    types =>
    (state, action, typeKeys = ASYNC_EXPORT_TYPE_KEYS) => {
        switch (action.type) {
            case types[typeKeys.init]: {
                return {
                    ...state,
                    loaders: {
                        ...state.loaders,
                        export: true,
                    },
                };
            }

            case types[typeKeys.success]:
            case types[typeKeys.failure]: {
                return {
                    ...state,
                    loaders: {
                        ...state.loaders,
                        export: false,
                    },
                };
            }

            default:
                return state;
        }
    };
