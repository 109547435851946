import { schema } from '@actions/oms/vat/config';
import { TYPES as LIST_TYPES } from '@actions/oms/vat/list';
import { TYPES as UPDATE_TYPES } from '@actions/oms/vat/update';
import { TYPES as BATCH_UPDATE_TYPES } from '@actions/oms/vat/batchUpdate';
import { batchUpdateReducer, CRUD_INITIAL_STATE, listReducer, reduceReducers, updateReducer } from '../crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    batchUpdateReducer(BATCH_UPDATE_TYPES, schema)
);
