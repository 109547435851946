import { schema } from '@actions/footerItems/config';
import { TYPES as CREATE_TYPES } from '@actions/footerItems/create';
import { TYPES as GET_TYPES } from '@actions/footerItems/get';
import { TYPES as LIST_TYPES } from '@actions/footerItems/list';
import { TYPES as UPDATE_TYPES } from '@actions/footerItems/update';
import { TYPES as BATCH_UPDATE_TYPES } from '@actions/footerItems/batchUpdate';
import {
    createReducer,
    getReducer,
    listReducer,
    updateReducer,
    CRUD_INITIAL_STATE,
    reduceReducers,
    batchUpdateReducer,
} from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    createReducer(CREATE_TYPES, schema),
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    batchUpdateReducer(BATCH_UPDATE_TYPES, schema)
);
