import { schema } from 'normalizr';

const entitySchema = (entity: string, idAttribute = 'id') =>
    new schema.Entity(
        entity,
        {},
        {
            idAttribute,
        }
    );

const listSchema = (entity: string, idAttribute = 'id') => [entitySchema(entity, idAttribute)];

export default {
    ENTITY: entitySchema,
    LIST: listSchema,
};
