import { FETCH_REFERENCES_SUCCESS } from '@actions/oms/references';
import { OMS } from '@types';

export const INITIAL_STATE: OMS.References = {
    credit_creation_reasons: [],
    globale_countries: [],
    order_return_motives: [],
    order_payment_status: [],
    order_return_status: [],
    order_sources: [],
    order_status: [],
    order_types: [],
    shipping_types: [],
    voucher_campaign_discount_types: [],
    voucher_campaign_format_type: [],
    warehouses: [],
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case FETCH_REFERENCES_SUCCESS: {
            return action.payload.rawData;
        }

        default:
            return state;
    }
}
