import { schema } from '@actions/queue/config';
import { TYPES as GET_TYPES } from '@actions/queue/get';
import { TYPES as UPDATE_TYPES } from '@actions/queue/update';
import { TYPES as ENABLE_TYPES } from '@actions/queue/enable';
import { TYPES as PAUSE_TYPES } from '@actions/queue/pause';
import { CRUD_INITIAL_STATE, reduceReducers, getReducer, updateReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    getReducer(GET_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    updateReducer(ENABLE_TYPES, schema),
    updateReducer(PAUSE_TYPES, schema)
);
