import { addRangeFilter } from '@utils/filters';

export const schema = 'store-variant';
export const name = 'STORE_VARIANT';
export const baseUrl = 'ecommerce-private/v1/store-variants';
export const searchUrl = `${baseUrl}/search`;

export const getFilterParams = filters => {
    const {
        saleId,
        excludeIds,
        ids,
        implementationDate,
        hidePastPreorders,
        isPreorderActiveOrScheduled,
        includeIds,
        excludeCatalogVariants, // sent in body
        includeProduct,
        ...otherFilters
    } = filters || [];

    const filterParams = { ...otherFilters };

    if (excludeIds && excludeIds.length) {
        filterParams.exclude = excludeIds || undefined;
    }

    if (includeIds && includeIds.length) {
        filterParams.include = includeIds || undefined;
    }

    if (ids && ids.length) {
        filterParams.ids = ids || undefined;
    }

    if (includeProduct && includeProduct.length) {
        filterParams.includeProduct = includeProduct || undefined;
    }

    if (saleId) {
        filterParams.sale = saleId;
    }

    if (hidePastPreorders) {
        addRangeFilter('preorderEnd', new Date(), null, filterParams);
    }

    if (isPreorderActiveOrScheduled) {
        addRangeFilter('preorderStart', new Date(), null, filterParams);
    }

    if (implementationDate) {
        addRangeFilter('implementationDate', implementationDate.after, implementationDate.before, filterParams);
    }

    return filterParams;
};

export const getFilterBody = filters => {
    const { excludeCatalogVariants } = filters || [];

    if (excludeCatalogVariants && excludeCatalogVariants.length > 0) {
        return {
            excludeCatalogVariants,
        };
    }

    return undefined;
};
