/* eslint no-magic-numbers: ["error", {ignore: [2, 100]}] */
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import withDefaultLocaleData from '@utils/DefaultLocaleDataHoc';

const CURRENCIES_WITH_SYMBOL_LEFT = ['GBP', 'USD', 'CAD', 'AUD'];

export const floatPriceToInteger = value => (isNaN(Number(value)) ? '' : Math.abs(Math.round(value * 100)));
export const integerPriceToFloat = value => (isNaN(Number(value)) ? '' : parseFloat(value / 100));

export const formatSimplePrice = (price, currency, intl) => {
    const formattedPrice = intl.formatNumber((price / 100).toFixed(2));
    return CURRENCIES_WITH_SYMBOL_LEFT.includes(currency.code)
        ? `${currency.label}${formattedPrice}`
        : `${formattedPrice}\u00a0${currency.label}`;
};

export const formatPrice = (prices, currency, intl) => {
    if (!prices) {
        return null;
    }

    const price = prices[currency.code];
    if (!price || isNaN(price)) {
        return null;
    }

    return formatSimplePrice(price, currency, intl);
};

const SimplePriceComp = ({ price, currency, defaultCurrency, intl }) =>
    formatSimplePrice(price, currency || defaultCurrency, intl);

SimplePriceComp.propTypes = {
    price: PropTypes.number.isRequired,
    defaultCurrency: PropTypes.object.isRequired,
    currency: PropTypes.object,
};

export const SimplePrice = injectIntl(withDefaultLocaleData(SimplePriceComp));

const Price = ({ currency, defaultCurrency, prices, intl }) => formatPrice(prices, currency || defaultCurrency, intl);

Price.propTypes = {
    defaultCurrency: PropTypes.object.isRequired,
    currency: PropTypes.object,
    prices: PropTypes.object.isRequired,
};

export default injectIntl(withDefaultLocaleData(Price));
