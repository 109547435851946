import { addNewActionCreator } from '../crud';
import { name } from './config';

export const NEW_MODEL = {
    metricHeight: '',
    imperialHeight: '',
};

const { types, actionCreator } = addNewActionCreator(name, NEW_MODEL);
export const TYPES = types;

export default actionCreator;
