import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "orderReturnModes.List" */ '@containers/orderReturnModes/List'));
const Create = lazy(
    () => import(/* webpackChunkName: "orderReturnModes.Create" */ '@containers/orderReturnModes/Create')
);
const Update = lazy(
    () => import(/* webpackChunkName: "orderReturnModes.Update" */ '@containers/orderReturnModes/Update')
);

export default [
    <Route path={routesNames.CREATE_ORDER_RETURN_MODES} component={Create} strict={true} key="create" />,
    <Route path={routesNames.UPDATE_ORDER_RETURN_MODES} component={Update} strict={true} key="update" />,
    <Route path={routesNames.LIST_ORDER_RETURN_MODES} component={List} strict={true} key="list" />,
];
