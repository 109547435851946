import { REHYDRATE } from 'redux-persist';

import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, SET_REALM } from '@actions/auth';
import { FETCH_REFERENCES_SUCCESS } from '@actions/references';
import { ACCESS_RIGHTS } from '@utils/acl';

type AuthState = {
    user?: {
        id: number;
        given_name: string;
        family_name: string;
        email: string;
        roles: string[];
    };
    accessRights: ACCESS_RIGHTS[];
    error?: string;
    realm?: string;
};

export const INITIAL_STATE: AuthState = {
    accessRights: [],
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case SET_REALM:
            return { ...state, realm: action.realm };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: {
                    id: 1,
                    given_name: action.keycloak.tokenParsed.given_name,
                    family_name: action.keycloak.tokenParsed.family_name,
                    email: action.keycloak.tokenParsed.email,
                    roles: action.keycloak.tokenParsed.realm_access.roles,
                },
            };
        case FETCH_REFERENCES_SUCCESS:
            return {
                ...state,
                accessRights: action.payload.rawData.access_rights,
            };
        case LOGIN_FAILURE:
            return { ...state, user: null, error: action.error ? action.error.message : null };
        case LOGOUT:
            return { ...state, user: null, error: null, keycloak: null };
        case REHYDRATE:
            const auth = action.payload ? action.payload.auth : {};

            return { ...state, ...auth };

        default:
            return state;
    }
}
