import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface InfoLineProps {
    children?: ReactNode;
    className?: string;
    title: string;
    titleClassName?: string;
    titleLight?: boolean;
    withoutList?: boolean;
}

const InfoLine: FC<InfoLineProps> = ({ children, title, titleClassName, titleLight, withoutList, className }) => {
    const content = (
        <>
            <strong className={classNames('u-mr(xxs)', { 'u-c(gray-500) u-fw(base)': titleLight }, titleClassName)}>
                <FormattedMessage id={title} />
                {' : '}
            </strong>
            {children}
        </>
    );
    return withoutList ? content : <li className={className}>{content}</li>;
};

export default InfoLine;
