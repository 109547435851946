import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(
    () => import(/* webpackChunkName: "commercialOperations.Create" */ '@containers/commercialOperations/Create')
);
const Update = lazy(
    () => import(/* webpackChunkName: "commercialOperations.Update" */ '@containers/commercialOperations/Update')
);
const List = lazy(
    () => import(/* webpackChunkName: "commercialOperations.List" */ '@containers/commercialOperations/List')
);

export default [
    <Route path={routesNames.CREATE_COMMERCIAL_OPERATION} component={Create} exact={true} key="create" />,
    <Route path={routesNames.EDIT_COMMERCIAL_OPERATION} component={Update} exact={true} key="update" />,
    <Route path={routesNames.LIST_COMMERCIAL_OPERATIONS} component={List} strict={true} key="list" />,
];
