import { listActionCreator, withPage, withValues, withRequestParams, withNoSite } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    query = null,
    sale = null,
    packaging = null,
    variantId = null,
    pimVariantUuid = null,
    productId = null,
    subCategoryId = null,
    itemsPerPage = null,
    retailSale = null,
    filters = {}
) => {
    const filterParams = {
        typology: filters.typologies,
        category: filters.category,
        subcategories: filters.subcategory ? [filters.subcategory] : undefined,
    };

    return withValues({
        query,
        sale,
        packaging,
        filters,
    })(
        withRequestParams({
            search: query || undefined,
            sale: sale || undefined,
            packaging: packaging || undefined,
            variant: variantId || undefined,
            pimVariantUuid: pimVariantUuid || undefined,
            product: productId || undefined,
            subCategory: subCategoryId || undefined,
            itemsPerPage: itemsPerPage || undefined,
            retailSale: retailSale || undefined,
            ...filterParams,
        })(withPage(page)(withNoSite(actionCreator())))
    );
};
