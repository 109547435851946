import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'sezane-components';

import TableDropdown from '@components/table/Dropdown';
import { formatLink } from '@config/formatters';
import routesNames from '@config/routesNames';
import { getBucketQuantity, ReferencesWarehouse, StockBucketTypes } from '@containers/scheduledUpdates/utils';
import { Stock } from '@types';

export const SORTABLE_FIELDS = {
    stock_diff_knt_purchased: StockBucketTypes.DIFF_KNT_PURCHASED,
    stock_diff_web_purchased: StockBucketTypes.DIFF_WEB_PURCHASED,
    stock_diff: StockBucketTypes.DIFF,
    stock_temp: StockBucketTypes.TEMP,
    stock_rack: StockBucketTypes.RACK,
    stock_purchased: StockBucketTypes.SOLD,
    stock_web_to_integrate: StockBucketTypes.WEB_TO_INTEGRATE,
    stock_knr: StockBucketTypes.KNR,
    stock_cart: StockBucketTypes.CART,
    stock_diff_warehouse_purchased: StockBucketTypes.DIFF_WAREHOUSE_PURCHASED,
    stock_web_purchased: StockBucketTypes.WEB_PURCHASED,
    stock_real: StockBucketTypes.REAL,
    stock_web_to_integrate_us: StockBucketTypes.WEB_TO_INTEGRATE,
};

export const DEFAULT_FIELDS = {
    product: true,
    product_code: true,
    ean: true,
    update_mode: true,
    stock_diff_knt_purchased: true,
    stock_diff_web_purchased: true,
    stock_diff: true,
    stock_temp: true,
    stock_rack: true,
    stock_purchased: true,
    stock_web_to_integrate: false,
    stock_knr: true,
    stock_cart: false,
    stock_diff_warehouse_purchased: false,
    stock_web_purchased: false,
    stock_real: false,
    stock_web_to_integrate_us: false,
};

export const fieldOptions = {
    product: {
        align: 'left',
        width: '200p',
        className: 'u-pl(xs) u-mw(300p)',
    },
    product_code: {
        className: 'u-fz(sm)',
        width: '150p',
    },
    ean: {
        className: 'u-fz(sm)',
        width: '150p',
    },
    update_mode: {
        className: 'u-fz(sm)',
    },
    stock_diff_knt_purchased: {
        className: 'u-fz(sm)',
    },
    stock_diff_web_purchased: {
        className: 'u-fz(sm)',
        helper: <FormattedMessage id="stock_view.fields.stock_diff_web_purchased.helper" />,
    },
    stock_diff: {
        className: 'u-fz(sm)',
        helper: <FormattedMessage id="stock_view.fields.stock_diff.helper" />,
    },
    stock_temp: {
        className: 'u-fz(sm)',
    },
    stock_rack: {
        className: 'u-fz(sm)',
    },
    stock_purchased: {
        className: 'u-fz(sm)',
    },
    stock_web_to_integrate: {
        className: 'u-fz(sm)',
        helper: <FormattedMessage id="stock_view.fields.stock_web_to_integrate.helper" />,
    },
    stock_knr: {
        className: 'u-fz(sm)',
    },
    stock_cart: {
        className: 'u-fz(sm)',
    },
    stock_diff_warehouse_purchased: {
        className: 'u-fz(sm)',
    },
    stock_web_purchased: {
        className: 'u-fz(sm)',
    },
    stock_real: {
        className: 'u-fz(sm)',
    },
    stock_web_to_integrate_us: {
        className: 'u-fz(sm)',
    },
    actions: {
        hideLabel: true,
        fillCell: true,
        width: '60p',
    },
};

export const formatProduct = ({ model_name, color, size }: Stock.EanStocks) => (
    <>
        {model_name}
        <div className="u-fz(sm) u-fw(lighter)">
            {color} - {size}
        </div>
    </>
);

export const formatProductCode = ({ product_code }: Stock.EanStocks) => (
    <CopyToClipboard value={product_code || ''}>
        <pre>{formatLink(product_code, `${routesNames.LIST_PRODUCTS}?search=${product_code}`)}</pre>
    </CopyToClipboard>
);

export const formatEan = ({ ean }: Stock.EanStocks) => (
    <CopyToClipboard value={ean}>
        <pre>{formatLink(ean, `${routesNames.LIST_PRODUCTS}?search=${ean}`)}</pre>
    </CopyToClipboard>
);

export const formatUpdateMode = ({ update_mode }: Stock.EanStocks) =>
    update_mode ? <FormattedMessage id={`scheduled_updates.update_mode.${update_mode?.toLowerCase()}`} /> : null;

export const formatStockQuantity =
    (type: StockBucketTypes, warehouse?: ReferencesWarehouse) =>
    ({ stocks }: Stock.EanStocks) => {
        const quantity = getBucketQuantity(stocks, type, warehouse);
        return quantity !== undefined ? quantity : '-';
    };

export default (openEditModal: (editedEan: Stock.EanStocks) => void, usWarehouse?: ReferencesWarehouse) => ({
    product: formatProduct,
    product_code: formatProductCode,
    ean: formatEan,
    update_mode: formatUpdateMode,
    stock_diff_knt_purchased: formatStockQuantity(StockBucketTypes.DIFF_KNT_PURCHASED),
    stock_diff_web_purchased: formatStockQuantity(StockBucketTypes.DIFF_WEB_PURCHASED),
    stock_diff: formatStockQuantity(StockBucketTypes.DIFF),
    stock_temp: formatStockQuantity(StockBucketTypes.TEMP),
    stock_rack: formatStockQuantity(StockBucketTypes.RACK),
    stock_purchased: formatStockQuantity(StockBucketTypes.SOLD),
    stock_web_to_integrate: formatStockQuantity(StockBucketTypes.WEB_TO_INTEGRATE),
    stock_knr: formatStockQuantity(StockBucketTypes.KNR),
    stock_cart: formatStockQuantity(StockBucketTypes.CART),
    stock_diff_warehouse_purchased: formatStockQuantity(StockBucketTypes.DIFF_WAREHOUSE_PURCHASED, usWarehouse),
    stock_web_purchased: formatStockQuantity(StockBucketTypes.WEB_PURCHASED, usWarehouse),
    stock_real: formatStockQuantity(StockBucketTypes.REAL, usWarehouse),
    stock_web_to_integrate_us: formatStockQuantity(StockBucketTypes.WEB_TO_INTEGRATE, usWarehouse),
    actions: (eanStock: Stock.EanStocks) => (
        <TableDropdown
            options={[
                {
                    renderOption: () => <FormattedMessage key="edit" id="common.edit" />,
                    onSelect: () => openEditModal(eanStock),
                },
            ]}
        />
    ),
});
