import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Case, Col, DateInput, Grid } from 'sezane-components';

import oms from '@api/clients/oms';
import InfoLine from '@components/info/InfoLine';
import { OMS } from '@types';
import { formatShortDate } from '@utils/dates';

export default function Turnover() {
    const [date, setDate] = React.useState<Date>();

    const { data } = useQuery(['turnover', date], () =>
        oms.getTurnoverWidget({
            date: date ? formatShortDate(date) : 'now',
        })
    );
    const turnover = data?.data;

    const infoLineClassNames = 'u-d(flex) u-ai(center) u-jc(space-between)';

    return (
        <Case size="sm" color="primary" style={{ height: '100%' }}>
            <div className="u-d(flex) u-jc(space-between) u-ai(center)">
                <h2 className="u-pt(xs) u-pb(xs) u-h3 u-tt(uppercase) u-fw(bolder) u-c(info)">
                    <FormattedMessage id="dashboard.turnover.title" />
                </h2>
                <DateInput
                    selected={date}
                    maxDate={new Date()}
                    /* @ts-expect-error */
                    onChange={value => setDate(value)}
                    className="u-w(150p)"
                    calendarClassName="react-datepicker--right"
                    alignRight
                />
            </div>
            {turnover && (
                <>
                    <Grid className="u-mt(xs)">
                        {['sezane', 'octobre'].map(brand => {
                            // @ts-expect-error
                            const brandTurnover: OMS.TurnoverWidgetData = turnover[brand];
                            return brandTurnover ? (
                                <Col key={brand} number="6">
                                    <h3 className="u-fz(md) u-tt(uppercase) u-fw(bolder)">
                                        <FormattedMessage id={`dashboard.turnover.brands.${brand}`} />
                                    </h3>
                                    <ul className="u-mt(xs) u-res(list)">
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.total_vat_excluded"
                                        >
                                            <FormattedNumber
                                                value={brandTurnover.total_vat_excluded!}
                                                style="currency"
                                                currency={'EUR'}
                                                maximumFractionDigits={0}
                                            />
                                        </InfoLine>
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.total_vat_included"
                                        >
                                            <FormattedNumber
                                                value={brandTurnover.total_vat_included!}
                                                style="currency"
                                                currency={'EUR'}
                                                maximumFractionDigits={0}
                                            />
                                        </InfoLine>
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.out_of_france_proportion"
                                        >
                                            {(brandTurnover.out_of_france_proportion! * 100).toFixed(2)}%
                                        </InfoLine>
                                    </ul>
                                    <ul className="u-mt(xs) u-res(list)">
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.orders_count"
                                        >
                                            {brandTurnover.orders_count}
                                        </InfoLine>
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.orders_web_count"
                                        >
                                            {brandTurnover.web_orders_count}
                                        </InfoLine>
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.orders_retail_count"
                                        >
                                            {brandTurnover.retail_orders_count}
                                        </InfoLine>
                                    </ul>
                                    <ul className="u-mt(xs) u-res(list)">
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.average_cart"
                                        >
                                            <FormattedNumber
                                                value={brandTurnover.average_cart!}
                                                style="currency"
                                                currency={'EUR'}
                                            />
                                        </InfoLine>
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.average_web_cart"
                                        >
                                            <FormattedNumber
                                                value={brandTurnover.web_average_cart!}
                                                style="currency"
                                                currency={'EUR'}
                                            />
                                        </InfoLine>
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.average_retail_cart"
                                        >
                                            <FormattedNumber
                                                value={brandTurnover.retail_average_cart!}
                                                style="currency"
                                                currency={'EUR'}
                                            />
                                        </InfoLine>
                                    </ul>
                                    <ul className="u-mt(xs) u-res(list)">
                                        <InfoLine
                                            className={infoLineClassNames}
                                            title="dashboard.turnover.users.newsletter"
                                        >
                                            {brandTurnover.newsletter}
                                        </InfoLine>
                                    </ul>
                                </Col>
                            ) : null;
                        })}
                        <Col number={6}>
                            <h3 className="u-fz(md) u-tt(uppercase) u-fw(bolder)">
                                <FormattedMessage id="dashboard.turnover.users.title" />
                            </h3>
                            <ul className="u-mt(xs) u-res(list)">
                                <InfoLine title="dashboard.turnover.users.customer">
                                    {turnover.users?.customer}
                                </InfoLine>
                            </ul>
                        </Col>
                    </Grid>
                </>
            )}
        </Case>
    );
}
