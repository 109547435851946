import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(() => import(/* webpackChunkName: "shippingModes.Create" */ '@containers/shippingModes/Create'));
const Update = lazy(() => import(/* webpackChunkName: "shippingModes.Update" */ '@containers/shippingModes/Update'));
const List = lazy(() => import(/* webpackChunkName: "shippingModes.List" */ '@containers/shippingModes/List'));
const LinkedReturnModes = lazy(
    () =>
        import(/* webpackChunkName: "shippingModes.LinkedReturnModes" */ '@containers/shippingModes/linkedReturnModes')
);

export default [
    <Route path={routesNames.CREATE_SHIPPING_MODE} component={Create} exact={true} key="create" />,
    <Route path={routesNames.UPDATE_SHIPPING_MODE} component={Update} exact={true} key="show" />,
    <Route
        path={routesNames.LINKED_RETURN_MODES_SHIPPING_MODE}
        component={LinkedReturnModes}
        strict={true}
        key="LinkedReturnModes"
    />,
    <Route path={routesNames.LIST_SHIPPING_MODES} component={List} strict={true} key="list" />,
];
