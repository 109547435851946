import { schema } from '@actions/stockAlertsTransaction/config';
import { TYPES as LIST_TYPES } from '@actions/stockAlertsTransaction/list';
import { TYPES as DELETE_TYPES } from '@actions/stockAlertsTransaction/delete';
import { TYPES as UPDATE_TYPES } from '@actions/stockAlertsTransaction/update';
import { reduceReducers, listReducer, deleteReducer, CRUD_INITIAL_STATE, updateReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    deleteReducer(DELETE_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema)
);
