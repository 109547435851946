import { addRangeFilter } from '@utils/filters';

export const schema = 'stock-alerts';
export const name = 'STOCK_ALERT';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/stock-alerts`;

export const getFilterParams = filters => {
    const { emailPlannedAtAfter, emailPlannedAtBefore, ...otherFilters } = filters || {};

    const filterParams = { ...otherFilters };

    addRangeFilter('emailPlannedAt', emailPlannedAtAfter, emailPlannedAtBefore, filterParams);

    return filterParams;
};
