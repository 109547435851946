import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faWpforms } from '@fortawesome/free-brands-svg-icons';
import {
    faAlignLeft,
    faAngleDoubleDown,
    faAngleDoubleRight,
    faAngleDoubleUp,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowsAlt,
    faAt,
    faBagShopping,
    faBan,
    faBell,
    faBicycle,
    faBook,
    faBoxOpen,
    faBusinessTime,
    faCalculator,
    faCalendar,
    faCalendarAlt,
    faCamera,
    faCaretUp,
    faCartPlus,
    faChartLine,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClock,
    faClockRotateLeft,
    faCloudUploadAlt,
    faCode,
    faCog,
    faCogs,
    faComment,
    faCompass,
    faCopy,
    faCreditCard,
    faCropAlt,
    faCubes,
    faDatabase,
    faDesktop,
    faDollar,
    faDotCircle,
    faDownload,
    faEdit,
    faEnvelope,
    faEnvelopeOpenText,
    faEuroSign,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandArrowsAlt,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFastForward,
    faFile,
    faFileExport,
    faFileImport,
    faFileInvoice,
    faFileLines,
    faFilePdf,
    faFlag,
    faGift,
    faGifts,
    faGlobeEurope,
    faHandHoldingHeart,
    faHandsHelping,
    faHashtag,
    faHeart,
    faHistory,
    faHome,
    faHourglassHalf,
    faIdCard,
    faImage,
    faImages,
    faInbox,
    faInfinity,
    faInfo,
    faInfoCircle,
    faKey,
    faLanguage,
    faLaptop,
    faLayerGroup,
    faLink,
    faList,
    faListAlt,
    faLocationDot,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMessage,
    faMinus,
    faMobile,
    faMobileScreen,
    faMoneyBill,
    faPalette,
    faPaperPlane,
    faPaste,
    faPaw,
    faPenToSquare,
    faPencilAlt,
    faPercent,
    faPhone,
    faPhotoVideo,
    faPiggyBank,
    faPlay,
    faPlus,
    faPlusCircle,
    faQuestion,
    faReceipt,
    faRectangleList,
    faRedo,
    faRocket,
    faRotate,
    faRotateLeft,
    faSave,
    faScissors,
    faSearch,
    faShirt,
    faShoePrints,
    faShop,
    faShoppingCart,
    faSignOutAlt,
    faSpinner,
    faStar,
    faStickyNote,
    faStopCircle,
    faStore,
    faStream,
    faSync,
    faSyncAlt,
    faTag,
    faTags,
    faTicket,
    faTimes,
    faTools,
    faTrashAlt,
    faTruck,
    faTshirt,
    faUpload,
    faUser,
    faUsers,
    faVideo,
    faWandMagicSparkles,
    faWarehouse,
    faWrench,
    faArrowsLeftRightToLine,
} from '@fortawesome/free-solid-svg-icons';

export default () =>
    library.add(
        faAlignLeft,
        faAngleDoubleDown,
        faAngleDoubleRight,
        faAngleDoubleUp,
        faAngleLeft,
        faAngleRight,
        faArrowDown,
        faArrowLeft,
        faArrowRight,
        faArrowsAlt,
        faArrowUp,
        faAt,
        faBagShopping,
        faBan,
        faBell,
        faBicycle,
        faBook,
        faBoxOpen,
        faBusinessTime,
        faCalculator,
        faCalendar,
        faCalendarAlt,
        faCamera,
        faCaretUp, // triangle
        faCartPlus,
        faChartLine,
        faCheck,
        faCheckCircle,
        faCheckDouble,
        faCheckSquare,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faChevronUp,
        faCircle,
        faClock,
        faClockRotateLeft,
        faCloudUploadAlt,
        faCode,
        faCog,
        faCogs,
        faComment,
        faCompass,
        faCopy,
        faCreditCard,
        faCropAlt,
        faCubes,
        faDatabase,
        faDesktop,
        faDollar,
        faDotCircle,
        faDownload,
        faEdit,
        faEnvelope,
        faEnvelopeOpenText,
        faEuroSign,
        faExchangeAlt,
        faExclamationCircle,
        faExclamationTriangle,
        faExpandArrowsAlt,
        faExternalLinkAlt,
        faEye,
        faEyeSlash,
        faFastForward,
        faFile,
        faFileExport,
        faFileImport,
        faFileInvoice,
        faFileLines,
        faFilePdf,
        faFlag,
        faGift,
        faGifts,
        faGlobeEurope,
        faHandHoldingHeart,
        faHandsHelping,
        faHashtag,
        faHeart,
        faHistory,
        faHome, // house
        faHourglassHalf,
        faIdCard,
        faImage,
        faImages,
        faInbox,
        faInfinity,
        faInfo,
        faInfoCircle,
        faInstagram,
        faKey,
        faLanguage,
        faLaptop,
        faLayerGroup,
        faLink,
        faList,
        faListAlt,
        faLocationDot,
        faLock,
        faLockOpen,
        faMagnifyingGlass,
        faMessage,
        faMinus,
        faMobile,
        faMobileScreen,
        faMoneyBill,
        faPalette,
        faPaperPlane,
        faPaste,
        faPaw,
        faPencilAlt,
        faPenToSquare,
        faPercent,
        faPhone,
        faPhotoVideo,
        faPiggyBank,
        faPlay,
        faPlus,
        faPlusCircle,
        faQuestion,
        faReceipt,
        faRectangleList,
        faRedo,
        faRocket,
        faRotate,
        faRotateLeft,
        faSave,
        faScissors,
        faSearch,
        faShirt,
        faShoePrints,
        faShop,
        faShoppingCart,
        faSignOutAlt,
        faSpinner,
        faStar,
        faStickyNote,
        faStopCircle,
        faStore,
        faStream,
        faSync,
        faSyncAlt,
        faTag,
        faTags,
        faTicket,
        faTimes, // cross
        faTools,
        faTrashAlt,
        faTruck,
        faTshirt,
        faUpload,
        faUser,
        faUsers,
        faVideo,
        faWandMagicSparkles,
        faWarehouse,
        faWpforms,
        faWrench,
        faBicycle,
        faArrowsLeftRightToLine
    );
