import PropTypes from 'prop-types';

import translate from '@config/translator';
import withDefaultLocaleData from '@utils/DefaultLocaleDataHoc';

const TranslatedProperty = ({ object, property, locale, defaultLocale, fallback }) => {
    return translate(object, property, locale || defaultLocale, fallback);
};

TranslatedProperty.propTypes = {
    object: PropTypes.object.isRequired,
    fallback: PropTypes.bool,
    property: PropTypes.string.isRequired,
    locale: PropTypes.string,
    defaultLocale: PropTypes.string.isRequired,
};

export default withDefaultLocaleData(TranslatedProperty);
