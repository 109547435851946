import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Tooltip } from 'sezane-components';

import FieldCheckbox from './FieldCheckbox';

const FieldsConfiguration = ({ fields, configuration, toggleField, icon, limit }) => {
    const intl = useIntl();

    return (
        <Tooltip
            position="right"
            arrowPosition="right"
            renderToggleButton={toggle => (
                <Button
                    icon={icon}
                    iconOnly
                    size="sm"
                    color="tertiary"
                    hiddenLabel={intl.formatMessage({ id: 'products.configuration.fields.button' })}
                    onClick={toggle}
                />
            )}
        >
            <fieldset>
                {limit && (
                    <p>
                        <FormattedMessage id="products.configuration.limit" />
                    </p>
                )}
                <ul className="u-res(list) u-mt(xs)">
                    {Object.keys(fields).map(fieldKey => {
                        const children = fields[fieldKey];

                        return (
                            <React.Fragment key={fieldKey}>
                                <FieldCheckbox
                                    className="u-mt(xs)"
                                    field={fieldKey}
                                    toggle={() => toggleField(fieldKey, children)}
                                    checked={configuration[fieldKey] || false}
                                />
                                {!!children && (
                                    <ul className="u-res(list) u-mt(none)">
                                        {children.map(child => (
                                            <FieldCheckbox
                                                className="u-pl(sm) u-mt(xs)"
                                                key={child}
                                                field={child}
                                                toggle={() => toggleField(child, null, fieldKey)}
                                                checked={configuration[child] || false}
                                            />
                                        ))}
                                    </ul>
                                )}
                            </React.Fragment>
                        );
                    })}
                </ul>
            </fieldset>
        </Tooltip>
    );
};

FieldsConfiguration.propTypes = {
    fields: PropTypes.object.isRequired,
    configuration: PropTypes.object.isRequired,
    toggleField: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    limit: PropTypes.bool,
};

FieldsConfiguration.defaultProps = {
    icon: 'cog',
};

export default FieldsConfiguration;
