import { SortParams } from '@types';
import { listActionCreator, withRequestParams, withValues, withPage } from '../crud';
import { name, schema, baseUrl, getFilterParams } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, filters = {}, sort: SortParams, query: any) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const filterParams = getFilterParams(filters);

    return withPage(page)(
        withValues({
            sort,
            filters,
        })(
            withRequestParams({
                search: query || undefined,
                ...filterParams,
                ...sortParam,
            })(actionCreator())
        )
    );
};
