import Status from '@components/status';
import { Color, TranslationStatus as TranslationStatusType } from '@types';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const LocaleTranslationStatus: FC<LocaleTranslationStatusProps> = ({ translationStatus, className }) => {
    let color: Color = 'warning';
    let label = `translation.status.${translationStatus}`;

    switch (translationStatus) {
        case 'not_sent':
            color = 'danger';
            break;
        case 'in_progress':
            color = 'warning';
            break;
        case 'translated':
            color = 'success';
            break;
        case 'missing_trad':
            color = 'danger';
            break;
    }

    return <Status color={color} label={<FormattedMessage id={label} />} className={className} size="xs" />;
};

interface LocaleTranslationStatusProps {
    translationStatus: TranslationStatusType;
    className?: string;
}

export default LocaleTranslationStatus;
