import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'sezane-components';

const getFallbackValue = (object: any, property: string) => {
    if (!object || !object.translations || Object.keys(object.translations).length === 0) {
        return null;
    }

    const fallbackLocale = Object.keys(object.translations).find(locale => {
        return object.translations[locale] && object.translations[locale][property];
    });

    return fallbackLocale ? (
        <>
            {object.translations[fallbackLocale][property]}
            <span className="u-c(danger) u-fz(sm)">
                <Icon size="xs" icon="exclamation-triangle" className="u-ml(xxs) u-mr(xxs)" />
                <FormattedMessage id="common.translation_warning" />
            </span>
        </>
    ) : null;
};

export default (object: any, property: string, locale = 'fr', fallback = false): string | null => {
    let value = null;

    if (object && object.translations && object.translations[locale]) {
        value = object.translations[locale][property];
    }

    if (!value && fallback) {
        value = getFallbackValue(object, property);
    }

    if (value) {
        return value;
    }

    if (!fallback) {
        // If fallback is disabled, we want to allow undefined values.
        return null;
    }

    console.error(`[Translation] : missing ${property} property in object`, object);

    return `{${property}}`;
};
