import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { enhanceTranslationValues } from '@utils/forms';

const Error: FC<ErrorProps> = ({ className, message, messageKey, values }) => (
    <p className={classNames('u-c(danger) u-mt(xs)', className)}>
        <FormattedMessage id={`validation.${messageKey}`} defaultMessage={message} values={values} />
    </p>
);

interface ErrorProps {
    className?: string;
    message: string;
    messageKey?: string;
    values?: Record<string, React.ReactNode>;
}

type CMSError = { message: string; messageKey: string; parameters: any };
type OMSError = Record<string, { message: string; messageKey: string }>;
interface TranslationErrorProps {
    className?: string;
    error: string | CMSError | CMSError[] | OMSError[];
}

const TranslationError = ({ error, className }: TranslationErrorProps) => {
    if (typeof error === 'string') {
        return <Error message={error} messageKey={error} className={className} />;
    }

    if ('message' in error) {
        return (
            <Error
                className={className}
                message={error.message}
                messageKey={error.messageKey}
                values={enhanceTranslationValues(error.parameters)}
            />
        );
    }

    if (Array.isArray(error)) {
        return (
            <>
                {error.map((errorItem, index) => {
                    let message: string | undefined = undefined;
                    let messageKey: string | undefined = undefined;

                    if (typeof errorItem.message === 'string') {
                        message = errorItem.message;
                        messageKey = (errorItem as CMSError).messageKey;
                    } else if (Object.values(errorItem).length > 0) {
                        message = Object.values(errorItem)[0].message;
                        messageKey = Object.values(errorItem)[0].messageKey;
                    }

                    return message ? (
                        <Error
                            key={index}
                            className={className}
                            message={message}
                            messageKey={messageKey}
                            values={enhanceTranslationValues(errorItem.parameters)}
                        />
                    ) : null;
                })}
            </>
        );
    }

    return null;
};

export default TranslationError;
