import { SEARCH_SUCCESS, SEARCH_CLEAR_RESULTS } from '@actions/search';

export const INITIAL_STATE = {
    results: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SEARCH_SUCCESS: {
            return { ...state, results: action.payload.rawData };
        }
        case SEARCH_CLEAR_RESULTS: {
            return { ...state, results: [] };
        }

        default:
            return state;
    }
}
