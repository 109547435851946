import { listActionCreator, withPage, withRequestParams, withValues, withWorkspace } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, query = null, workspaceId, locale, filters = {}, sort, save = true) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    return withValues({
        sort,
        filters,
        locale,
        save,
    })(
        withRequestParams({
            search: query || undefined,
            ...filters,
            locale,
            ...sortParam,
        })(withWorkspace(workspaceId)(withPage(page)(actionCreator())))
    );
};
