import { addRangeFilter } from '@utils/filters';

export const schema = 'user';
export const name = 'USER';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/users`;
export const idAttribute = 'keycloakId';

export type UsersFilters = {
    lastOrderedAfter?: string;
    lastOrderedBefore?: string;
    createdAtAfter?: number;
    createdAtBefore?: number;
    hasReturn?: boolean;
    totalPriceMax?: number;
    totalPriceMin?: number;
    toValidate?: boolean;
};
export const getFilterParams = (filters: UsersFilters) => {
    const { lastOrderedAfter, lastOrderedBefore, createdAtAfter, createdAtBefore, ...otherFilters } = filters || {};

    const filterParams = { ...otherFilters };

    lastOrderedAfter &&
        lastOrderedBefore &&
        addRangeFilter('lastOrderedAt', lastOrderedAfter, lastOrderedBefore, filterParams);
    createdAtAfter && createdAtBefore && addRangeFilter('createdAt', createdAtAfter, createdAtBefore, filterParams);

    return filterParams;
};
