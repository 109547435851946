import { reducer as formReducer } from 'redux-form';

import { CHANGE_ALL } from '@actions/form/changeAll';

export default (state: any, action: any) => {
    switch (action.type) {
        case CHANGE_ALL:
            const formState = state[action.form];
            return {
                ...state,
                [action.form]: {
                    ...formState,
                    values: action.values,
                },
            };
        default:
            return formReducer(state, action);
    }
};
