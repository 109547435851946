import React from 'react';
import { IntlShape } from 'react-intl';

import { IntlMessage } from '@types';
import { enhanceTranslationValues } from '@utils/forms';
import { intlProvider } from '../../app/App';

interface TranslationMapping {
    regexp: RegExp;
    translationKey: string;
}

const translationsMapping: TranslationMapping[] = [
    {
        regexp: /^compositions\[\d*\]\.blocks\[\d*\]\.translations\.en\.htmlContent$/,
        translationKey: 'violations.page_versions.translation_block',
    },
    {
        regexp: /^compositions\[\d*\]\.blocks\[\d*\].page$/,
        translationKey: 'violations.page_versions.page',
    },
    {
        regexp: /^compositions\[\d*\].blocks\[\d*\]\.media$/,
        translationKey: 'violations.page_versions.media_block',
    },
    {
        regexp: /^translations\.\w{2}\.title$/,
        translationKey: 'violations.page_versions.translation_title',
    },
    {
        regexp: /^translations\.\w{2}\.path$/,
        translationKey: 'violations.page_versions.translation_path',
    },
    {
        regexp: /^translations\.\w{2}\.label$/,
        translationKey: 'violations.sale_versions.translation_label',
    },
    {
        regexp: /^position$/,
        translationKey: 'violations.page_versions.position',
    },
    {
        regexp: /^media$/,
        translationKey: 'violations.page_versions.media',
    },
    {
        regexp: /^catalogVariant$/,
        translationKey: 'violations.page_versions.product',
    },
    {
        regexp: /^translations\.\w{2}\.alternateTitleNews$/,
        translationKey: 'violations.page_versions.alternate_title_translation',
    },
];

const mapIdToTranslation = (id: string) => {
    return translationsMapping.find(({ regexp }) => regexp.test(id))?.translationKey || id;
};

const translateMsg = (msg: string | IntlMessage, intl: IntlShape) => {
    let id;
    let values = {};

    if (typeof msg === 'string') {
        id = mapIdToTranslation(msg);
    } else {
        id = mapIdToTranslation(msg.id);
        values = msg.values || {};
    }

    return id ? intl.formatMessage({ id }, enhanceTranslationValues(values)) : msg;
};

const getKey = (msg: string | IntlMessage) => {
    return typeof msg === 'string' ? msg : msg.id + (msg.values?.name || '');
};

const SubMessage = ({ messages: propsMsg }: Props) => {
    const messages = Array.isArray(propsMsg) ? propsMsg : [propsMsg];
    const intl = intlProvider.state.intl;

    return (
        <ul className="u-mt(none)">
            {messages.map(msg => (
                // @ts-ignore TODO Upgrade react-intl
                <li key={getKey(msg)}>{translateMsg(msg, intl)}</li>
            ))}
        </ul>
    );
};

interface Props {
    messages: string | string[] | IntlMessage | IntlMessage[];
}

export default SubMessage;
