import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

export const DEFAULT_LOCALE = 'fr';
export const DEFAULT_AVAILABLE_LOCALES = ['fr', 'en'];
export const DEFAULT_CURRENCY = { code: 'EUR', label: '€' };

const getDisplayName = Component => Component.displayName || Component.name || 'Component';

const withDefaultLocaleData = Component => {
    class WithDefaultLocaleData extends React.PureComponent {
        render() {
            const { site, defaultLocale, ...rest } = this.props;
            const localeData = {
                defaultLocale,
                defaultAvailableLocales: site?.availableLocales
                    ? site.availableLocales.map(locale => locale.code)
                    : DEFAULT_AVAILABLE_LOCALES,
                defaultCurrency: site?.defaultShippingCountry?.currency
                    ? site.defaultShippingCountry.currency
                    : DEFAULT_CURRENCY,
                defaultAvailableCurrencies: site?.availableCurrencies ? site.availableCurrencies : [DEFAULT_CURRENCY],
            };

            return <Component {...localeData} {...rest} />;
        }
    }

    WithDefaultLocaleData.displayName = `WithDefaultLocaleData(${getDisplayName(Component)})`;

    return WithDefaultLocaleData;
};

const mapStateToProps = state => {
    const site = state.ui.site;
    const locale = state.ui.locale;

    return {
        site,
        defaultLocale: locale ? locale.code : {},
    };
};

export default compose(connect(mapStateToProps, null), withDefaultLocaleData);
