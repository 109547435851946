import React, { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import isString from 'lodash/isString';
import classNames from 'classnames';

const TranslationLabel: FC<TranslationLabelProps> = ({ label, values, className, htmlFor, isRequired }) =>
    isString(label) ? (
        <label className={classNames('c-label', className)} htmlFor={htmlFor}>
            {values ? <FormattedMessage id={label} values={values} /> : <FormattedMessage id={label} />}
            {isRequired && <span className="u-ml(xxxs)">*</span>}
        </label>
    ) : (
        label
    );

interface TranslationLabelProps {
    className?: string;
    htmlFor?: string;
    isRequired?: boolean;
    label: ReactElement | string;
    values?: any;
}

export default TranslationLabel;
