import apiSchema from '@config/schema';
import { name, schema, baseUrl } from './config';
import { notifyPendingCreation } from '@actions/crud';

export const TYPES = {
    DUPLICATE: `DUPLICATE_${name}_FROM_VERSION`,
    DUPLICATE_SUCCESS: `DUPLICATE_${name}_FROM_VERSION_SUCCESS`,
    DUPLICATE_FAILURE: `DUPLICATE_${name}_FROM_VERSION_FAILURE`,
};

export const getAction = saleVersionId => ({
    types: [TYPES.DUPLICATE, TYPES.DUPLICATE_SUCCESS, TYPES.DUPLICATE_FAILURE],
    schema: apiSchema.ENTITY(schema),
    payload: {
        request: {
            url: `${baseUrl}/duplicate-from-version/${saleVersionId}`,
            method: 'POST',
        },
    },
    noSite: true,
});

export default saleVersionId => {
    const action = getAction(saleVersionId);

    return notifyPendingCreation(action, 'sales.duplicate.pending', null);
};
