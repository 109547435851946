import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "carts.List" */ '@containers/carts/List'));
const Show = lazy(() => import(/* webpackChunkName: "carts.Show" */ '@containers/carts/Show'));

export default [
    <Route path={routesNames.SHOW_CART} component={Show} strict={true} key="show" />,
    <Route path={routesNames.LIST_CARTS} component={List} strict={true} key="list" />,
];
