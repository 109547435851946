import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "shops.List" */ '@containers/shops/List'));
const Create = lazy(() => import(/* webpackChunkName: "shops.List" */ '@containers/shops/Create'));

export default [
    <Route path={routesNames.CREATE_SHOPS} component={Create} key="create" />,
    <Route path={routesNames.LIST_SHOPS} component={List} strict={true} key="list" />,
];
