import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Profile = ({ user }) => {
    if (!user) {
        return null;
    }

    return (
        <div className="u-d(flex) u-ai(baseline) u-pt(xs) u-pb(xs)">
            <h1>
                <FormattedMessage id="common.welcome" values={{ name: user.given_name }} />,
            </h1>
        </div>
    );
};

Profile.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = state => ({
    user: state.auth.user,
});
export default connect(mapStateToProps)(Profile);
