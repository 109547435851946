import { OMS } from '@types';
import { useAppSelector } from '@utils/hooks/redux';
import { accessRightsSelector, ACCESS_RIGHTS, hasAccessRight, getAccessRightFromPath } from './config';

const ORDER_NON_CANCELLABLE_STATUS: OMS.OrderRead['status'][] = [
    'canceled',
    'delivered_to_customer',
    'delivered',
    'payment_failed',
    'shipped',
];

export default () => {
    const accessRights = useAppSelector(accessRightsSelector);

    const isGranted = (accessRight: ACCESS_RIGHTS) => {
        return hasAccessRight(accessRights, accessRight);
    };

    const isPathAllowed = (path: string) => {
        const accessRight = getAccessRightFromPath(path);

        if (!accessRight) {
            if (process.env.NODE_ENV === 'development' && path !== '/') {
                // eslint-disable-next-line no-console
                console.log('No access rights defined for path', path);
            }

            return true;
        }

        return isGranted(accessRight);
    };

    const canCancelOrder = (orderStatus: OMS.OrderRead['status']) => {
        if (ORDER_NON_CANCELLABLE_STATUS.includes(orderStatus)) {
            return false;
        }
        return isGranted(ACCESS_RIGHTS.ROLE_CANCEL_ORDER);
    };

    return { isGranted, isPathAllowed, canCancelOrder };
};
