import { listActionCreator, withWorkspace, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    query = null,
    workspaceId = null,
    pagination = true,
    filters = {},
    sort = null,
    locale = undefined,
    save = true
) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const params = { ...filters };
    params.enabled = params.show_inactive ? undefined : true;
    delete params.show_inactive;

    return withValues({
        sort,
        pagination,
        page,
        filters,
        save,
    })(
        withRequestParams({
            search: query || undefined,
            pagination: pagination,
            page: pagination === true && !!page ? page : undefined,
            ...params,
            locale,
            ...sortParam,
        })(withWorkspace(workspaceId)(actionCreator()))
    );
};
