import { ENTITY_HISTORY_TYPES } from '@utils/entityHistory';
import { listActionCreator, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (type: ENTITY_HISTORY_TYPES, objectId?: number, page: number = 1, itemsPerPage: number = 15) => {
    return withRequestParams({ objectId, type, page, itemsPerPage })(actionCreator());
};
