import { listActionCreator, withPage, withValues, withRequestParams, withWorkspace } from '../crud';
import { name, schema, baseUrl, getFilterParams, getSortParam } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    workspaceId = null,
    query = null,
    locale = 'fr',
    sort = null,
    filters = null,
    save = true,
    source
) => {
    const sortParam = getSortParam(sort, filters?.isTree);
    const filterParams = getFilterParams(filters);

    return withValues({
        sort,
        query,
        filterParams,
        save,
    })(
        withRequestParams({
            search: query || undefined,
            locale,
            ...sortParam,
            ...filterParams,
        })(withWorkspace(workspaceId)(withPage(page)(actionCreator(source))))
    );
};
