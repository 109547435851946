import { baseUrl } from './config';

export const TYPES = {
    LIST_BY_KIND: 'LIST_COMPOSITION_TEMPLATES_BY_KIND',
    LIST_BY_KIND_SUCCESS: 'LIST_COMPOSITION_TEMPLATES_BY_KIND_SUCCESS',
    LIST_BY_KIND_FAILURE: 'LIST_COMPOSITION_TEMPLATES_BY_KIND_FAILURE',
};

export default () => ({
    types: [TYPES.LIST_BY_KIND, TYPES.LIST_BY_KIND_SUCCESS, TYPES.LIST_BY_KIND_FAILURE],
    payload: {
        request: {
            url: `${baseUrl}/grouped-by-kind`,
            method: 'GET',
        },
    },
});
