import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withKeycloak } from '@react-keycloak/web';

import { logout } from '@actions/auth';
import routesNames from '@config/routesNames';

class Logout extends React.Component {
    componentDidMount() {
        this.props.logout().then(() => {
            this.props.keycloak.logout();
        });
    }

    render() {
        return <Redirect to={routesNames.LOGIN} />;
    }
}

Logout.propTypes = {
    logout: PropTypes.func.isRequired,
    keycloak: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
});

export default withKeycloak(connect(null, mapDispatchToProps)(Logout));
