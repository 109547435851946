import { SortParams } from '@types';
import { listActionCreator, withNoBrand, withNoSite, withPage, withRequestParams, withValues } from '../../crud';
import { ShippingModesFilters, baseUrl, getFilterParams, name, schema } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, sort: SortParams, filters: ShippingModesFilters = {}, query: any = null) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const filterParams = getFilterParams(filters);
    return withValues({
        sort,
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...filterParams,
            ...sortParam,
        })(withPage(page)(withNoBrand(withNoSite(actionCreator()))))
    );
};
