import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import routesNames from '@config/routesNames';

const Create = lazy(
    () => import(/* webpackChunkName: "voucherCampaigns.Create" */ '@containers/voucherCampaigns/Create')
);
const Update = lazy(
    () => import(/* webpackChunkName: "voucherCampaigns.Update" */ '@containers/voucherCampaigns/Update')
);
const Show = lazy(() => import(/* webpackChunkName: "voucherCampaigns.Show" */ '@containers/voucherCampaigns/Show'));
const List = lazy(() => import(/* webpackChunkName: "voucherCampaigns.List" */ '@containers/voucherCampaigns/List'));

export default [
    <Route path={routesNames.CREATE_VOUCHER_CAMPAIGN} component={Create} strict={true} key="create" />,
    <Route path={routesNames.UPDATE_VOUCHER_CAMPAIGN} component={Update} strict={true} key="update" />,
    <Route path={routesNames.SHOW_VOUCHER_CAMPAIGN} component={Show} strict={true} key="show" />,
    <Route path={routesNames.LIST_VOUCHER_CAMPAIGNS} component={List} strict={true} key="list" />,
];
