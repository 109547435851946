import { produce } from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';

import { schema } from '@actions/menuItems/config';
import { TYPES as GET_TYPES } from '@actions/menuItems/get';
import { TYPES as LIST_TYPES } from '@actions/menuItems/list';
import { TYPES as BATCH_UPDATE_TYPES } from '@actions/menuItems/batchUpdate';
import { getReducer, listReducer, batchUpdateReducer, CRUD_INITIAL_STATE, reduceReducers } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

const mergeMenuItem = (oldMenuItem, newMenuItem) => {
    return {
        ...oldMenuItem,
        ...newMenuItem,
    };
};

const getPathForMenuItem = (entities, menuItem) => {
    const parentsIds = [...(menuItem.parentsIds || [])];
    parentsIds.push(menuItem.id);
    if (!parentsIds || parentsIds.length <= 1) {
        return null;
    }

    const root = parentsIds.shift();
    const nestedPath = [root];
    parentsIds.forEach(currentId => {
        const parent = get(entities, nestedPath);
        if (!parent) {
            return null;
        }
        const currentIndex = parent.children.findIndex(child => child.id === currentId);
        if (currentIndex === -1) {
            return null;
        }

        nestedPath.push('children');
        nestedPath.push(currentIndex);
    });

    return nestedPath;
};

const updateMenuItemFromAction = (draftState, action) => {
    const newMenuItemId = action.payload.result;
    const newMenuItem = action.payload.entities[schema][newMenuItemId];

    const path = getPathForMenuItem(draftState.entities, newMenuItem);
    if (path !== null) {
        const itemInTree = get(draftState.entities, path);
        const updatedItemInTree = mergeMenuItem(itemInTree, newMenuItem);
        set(draftState.entities, path, updatedItemInTree);
        draftState.entities[newMenuItemId] = updatedItemInTree;

        return draftState;
    }

    // If the menu item has no path, ie it's not deep in the tree, we simply update it at the root level
    draftState.entities[newMenuItemId] = mergeMenuItem(draftState.entities[newMenuItemId] || {}, newMenuItem);

    return draftState;
};

const getReducerWithMerge = (state, action) => {
    const baseReducer = getReducer(GET_TYPES, schema);

    if (action.type === GET_TYPES.GET_SUCCESS) {
        return produce(state, draftState => {
            draftState.loading = false;
            draftState.loaders.get = false;
            draftState.error = false;

            return updateMenuItemFromAction(draftState, action);
        });
    }

    return baseReducer(state, action);
};

export default reduceReducers(
    getReducerWithMerge,
    listReducer(LIST_TYPES, schema),
    batchUpdateReducer(BATCH_UPDATE_TYPES, schema)
);
