import { patchActionCreator, withNoSite, withRequestParams, ID_PLACEHOLDER, withValues } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = patchActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}`);
export const TYPES = types;

export default (id, values, workspaces, locale) =>
    withValues({
        id,
    })(
        withRequestParams({
            locale,
            workspaces,
        })(withNoSite(actionCreator(id, values)))
    );
