import { updateActionCreator, ID_PLACEHOLDER, withRequestParams } from '../crud';
import { schema, baseUrl } from './config';

const { types, actionCreator } = updateActionCreator(
    'CREATE_DRAFT',
    schema,
    `${baseUrl}/${ID_PLACEHOLDER}/create-draft`
);
export const TYPES = types;

export default (id, workspaces) => {
    return withRequestParams({
        workspaces,
    })(actionCreator(id, undefined, workspaces));
};
