import classNames from 'classnames';
import React, { lazy, Suspense, useEffect } from 'react';
import { matchPath, Route, RouteComponentProps, StaticContext, Switch, useLocation } from 'react-router';

import Header from '@components/_header';
import CommandMenu from '@components/commands/CommandMenu';
import routesNames from '@config/routesNames';
import Login from '@containers/account/login';
import Logout from '@containers/account/logout';
import Dashboard from '@containers/dashboard';
import NotAuthorized from '@containers/notAuthorized';
import NotFound from '@containers/notFound';
import brands from '@routes/brands';
import cartsRoutes from '@routes/carts';
import colorParameters from '@routes/colorParameters';
import commercialOperations from '@routes/commercialOperations';
import compositionLayouts from '@routes/compositionLayouts';
import crons from '@routes/crons';
import customisationServices from '@routes/customisationServices';
import ecards from '@routes/ecards';
import emailTemplates from '@routes/emailTemplates';
import evergreenMediasRoutes from '@routes/evergreenMedias';
import footerRoutes from '@routes/footer';
import jobsRoutes from '@routes/jobs';
import logisticFiles from '@routes/logisticFiles';
import logReports from '@routes/logReports';
import mandatoryLogin from '@routes/mandatoryLogin';
import mediaLibraryRoutes from '@routes/mediaLibrary';
import menu from '@routes/menu';
import mobileContents from '@routes/mobileContents';
import modelsRoutes from '@routes/models';
import multiProductsRoutes from '@routes/multiProducts';
import newsletterSubscribersRoutes from '@routes/newsletterSubscribers';
import orderReturnModesRoutes from '@routes/orderReturnModes';
import ordersRoutes from '@routes/orders';
import packagingsRoutes from '@routes/packagings';
import pagesRoutes from '@routes/pages';
import parametersRoutes from '@routes/parameters';
import popins from '@routes/popins';
import preFooterRoutes from '@routes/preFooter';
import preorders from '@routes/preorders';
import productsRoutes from '@routes/products';
import pushProductsRoutes from '@routes/pushProducts';
import returnsRoutes from '@routes/returns';
import rolesRoutes from '@routes/roles';
import salesRoutes from '@routes/sales';
import salesStatistics from '@routes/salesStatistics';
import salesTaxRoutes from '@routes/salesTax';
import scheduledUpdatesRoutes from '@routes/scheduledUpdates';
import sequencings from '@routes/sequencings';
import shippingModesRoutes from '@routes/shippingModes';
import shopsRoutes from '@routes/shops';
import sites from '@routes/sites';
import stockAlerts from '@routes/stockAlerts';
import stockViewRoutes from '@routes/stockView';
import userCreditsRoutes from '@routes/userCredits';
import usersRoutes from '@routes/users';
import userTagsRoutes from '@routes/userTags';
import vatRoutes from '@routes/vat';
import voucherCampaignsRoutes from '@routes/voucherCampaigns';
import warehousesRoutes from '@routes/warehouses';
import workspacesRoutes from '@routes/workspaces';
import { AuthorizedRoute } from '@utils/acl';
import { isCustomerServiceView } from '@utils/zendesk';
import SecureRoute from './SecureRoute';

const Styleguide = lazy(() => import(/* webpackChunkName: "Styleguide" */ '../styleguide'));
const StyleguideSelects = lazy(() => import(/* webpackChunkName: "Styleguide.Products" */ '../styleguide/selects'));
const StyleguideForm = lazy(() => import(/* webpackChunkName: "Styleguide.Forms" */ '../styleguide/form'));

const Router = () => (
    <Suspense fallback={null}>
        <Switch>
            <Route path={routesNames.LOGIN} component={Login} />
            <Route path={routesNames.LOGOUT} component={Logout} />

            <Route path={routesNames.STYLEGUIDE} component={Styleguide} exact />
            <SecureRoute path="/" render={props => <WrappedRoutes {...props} />} />
        </Switch>
    </Suspense>
);

const WrappedRoutes = (props: RouteComponentProps<{}, StaticContext>) => {
    const location = useLocation();
    const path = location.pathname;

    const withoutHeader = isCustomerServiceView();

    useEffect(() => {
        if (withoutHeader) {
            document.body.classList.add('is-full-viewport');
        }
    }, []);

    return (
        <>
            <CommandMenu />
            {!withoutHeader && <Header key="header" />}
            <main
                key="page-content"
                className={classNames('c-page__main', {
                    'c-page--gray': matchPath(location.pathname, [routesNames.DASHBOARD])?.isExact,
                })}
            >
                <AuthorizedRoute
                    path={path}
                    no={NotAuthorized}
                    yes={() => (
                        <Suspense fallback={null}>
                            <Switch>
                                <Route exact path={routesNames.DASHBOARD} component={Dashboard} />
                                {brands}
                                {cartsRoutes}
                                {colorParameters}
                                {commercialOperations}
                                {compositionLayouts}
                                {crons}
                                {customisationServices}
                                {ecards}
                                {emailTemplates}
                                {evergreenMediasRoutes}
                                {footerRoutes}
                                {jobsRoutes}
                                {logisticFiles}
                                {logReports}
                                {mandatoryLogin}
                                {mediaLibraryRoutes}
                                {menu}
                                {mobileContents}
                                {modelsRoutes}
                                {multiProductsRoutes}
                                {newsletterSubscribersRoutes}
                                {ordersRoutes}
                                {orderReturnModesRoutes}
                                {packagingsRoutes}
                                {pagesRoutes}
                                {parametersRoutes}
                                {popins}
                                {preFooterRoutes}
                                {preorders}
                                {productsRoutes}
                                {pushProductsRoutes}
                                {returnsRoutes}
                                {rolesRoutes}
                                {salesRoutes}
                                {salesStatistics}
                                {salesTaxRoutes}
                                {scheduledUpdatesRoutes}
                                {sequencings}
                                {shippingModesRoutes}
                                {shopsRoutes}
                                {sites}
                                {stockAlerts}
                                {stockViewRoutes}
                                {userCreditsRoutes}
                                {usersRoutes}
                                {userTagsRoutes}
                                {vatRoutes}
                                {voucherCampaignsRoutes}
                                {warehousesRoutes}
                                {workspacesRoutes}
                                <Route path={routesNames.STYLEGUIDE_SELECTS} component={StyleguideSelects} exact />
                                <Route path={routesNames.STYLEGUIDE_FORM} component={StyleguideForm} exact />
                                <Route component={NotFound} />
                            </Switch>
                        </Suspense>
                    )}
                />
            </main>
        </>
    );
};

export default Router;
