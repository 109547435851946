import { schema } from '@actions/workspaces/config';
import { TYPES as CREATE_FROM_PREVIOUS_TYPES } from '@actions/workspaces/createFromPreviousWorkspace';
import { TYPES as DELETE_TYPES } from '@actions/workspaces/delete';
import { TYPES as LIST_TYPES } from '@actions/workspaces/list';
import { TYPES as GET_TYPES } from '@actions/workspaces/get';
import { TYPES as PATCH_TYPES } from '@actions/workspaces/patch';
import { TYPES as PUBLISH_TYPES } from '@actions/workspaces/publish';
import { TYPES as UPDATE_TYPES } from '@actions/workspaces/update';
import { TYPES as DUPLICATE_TYPES } from '@actions/workspaces/duplicate';
import {
    CRUD_INITIAL_STATE,
    reduceReducers,
    listReducer,
    getReducer,
    updateReducer,
    deleteReducer,
    patchReducer,
    createReducer,
} from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    getReducer(GET_TYPES, schema),
    deleteReducer(DELETE_TYPES),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    patchReducer(PATCH_TYPES, schema),
    createReducer(CREATE_FROM_PREVIOUS_TYPES, schema, {
        init: 'CREATE_FROM_PREVIOUS',
        success: 'CREATE_FROM_PREVIOUS_SUCCESS',
        failure: 'CREATE_FROM_PREVIOUS_FAILURE',
    }),
    updateReducer(PUBLISH_TYPES, schema, false, {
        init: 'PUBLISH',
        success: 'PUBLISH_SUCCESS',
        failure: 'PUBLISH_FAILURE',
    }),
    createReducer(DUPLICATE_TYPES, schema, {
        init: 'DUPLICATE',
        success: 'DUPLICATE_SUCCESS',
        failure: 'DUPLICATE_FAILURE',
    })
);
