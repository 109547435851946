import { createActionCreator, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = createActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (values, workspaces) => {
    return withRequestParams({
        workspaces,
    })(actionCreator(values));
};
