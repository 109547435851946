import React from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

interface PageHeadProps {
    isResponsive?: boolean;
    title?: string;
    titleValues?: {};
    titleAsString?: boolean;
}

const BASE_TITLE = 'Back Office';

const PageHead = ({ isResponsive, title, titleValues, titleAsString }: PageHeadProps) => {
    const intl = useIntl();

    // Checking type string just in case, because Helmet throws an error if we pass a ReactNode by mistake
    const displayedTitle =
        title && typeof title === 'string'
            ? `${BASE_TITLE} - ${titleAsString ? title : intl.formatMessage({ id: title }, titleValues)}`
            : BASE_TITLE;

    return (
        <Helmet>
            <title>{displayedTitle}</title>
            {isResponsive ? (
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            ) : (
                <meta name="viewport" content="width=1024" />
            )}
        </Helmet>
    );
};

export default PageHead;
