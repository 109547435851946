import apiSchema from '@config/schema';
import { schema, baseUrl } from './config';
import { formatForApi } from '@utils/dates';

export const TYPES = {
    CREATE_FROM_PREVIOUS: 'CREATE_FROM_PREVIOUS_WORKSPACE',
    CREATE_FROM_PREVIOUS_SUCCESS: 'CREATE_FROM_PREVIOUS_WORKSPACE_SUCCESS',
    CREATE_FROM_PREVIOUS_FAILURE: 'CREATE_FROM_PREVIOUS_WORKSPACE_FAILURE',
};

export default values => ({
    types: [TYPES.CREATE_FROM_PREVIOUS, TYPES.CREATE_FROM_PREVIOUS_SUCCESS, TYPES.CREATE_FROM_PREVIOUS_FAILURE],
    schema: apiSchema.LIST(schema),
    payload: {
        request: {
            url: `${baseUrl}/create-from-previous-one`,
            method: 'POST',
            data: {
                ...values,
                beginOn: formatForApi(values.beginOn),
            },
        },
    },
});
