import { updateActionCreator, ID_PLACEHOLDER, withNoSite, withNoBrand, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = updateActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}`);
export const TYPES = types;

export default (id, values, workspaces) =>
    withRequestParams({
        workspaces,
    })(withNoSite(withNoBrand(actionCreator(id, values))));
