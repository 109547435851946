import PropTypes from 'prop-types';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { Loader, useKeycloak } from 'sezane-components';

import routesNames from '@config/routesNames';

type Props = {
    render: (props: RouteComponentProps) => React.ReactNode;
    path: string;
};

export const SecureRoute = ({ render, ...rest }: Props) => {
    const { keycloak, initialized } = useKeycloak();
    const location = useLocation();

    return (
        <Route
            {...rest}
            render={props => {
                if (!initialized) {
                    return <Loader />;
                } else if (keycloak.authenticated) {
                    return render(props);
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: routesNames.LOGIN,
                                state: { referrer: location },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

SecureRoute.propTypes = {
    render: PropTypes.func.isRequired,
};

export default SecureRoute;
