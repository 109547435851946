import qs from 'qs';
import history from '../history';

import { PERSIST_KEY } from '@config/store';

/* set the accurate initial workspace from the page url to avoid doing the wrong request first.
Because if it ends after the good request, it might save the data from the wrong workspace */

export default store => next => action => {
    if (action.key === PERSIST_KEY && history.location?.search && action.payload?.ui?.workspace) {
        const filters = qs.parse(history.location.search, { ignoreQueryPrefix: true });
        const workspaceId = parseInt(filters.workspace, 10);
        if (workspaceId) {
            action.payload.ui.workspace = {
                id: workspaceId,
            };
        }
    }
    return next(action);
};
