import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ACCESS_RIGHTS, useAcl } from '@utils/acl';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    DropdownOption,
    TableDropdown as TableDropdownComponent,
    TableDropdownProps as SezaneTableDropdownProps,
} from 'sezane-components';

export interface TableDropdownProps extends SezaneTableDropdownProps {
    options: Array<DropdownOption & { accessRight?: ACCESS_RIGHTS }>;
    icon?: IconProp;
    label?: string;
}

const TableDropdown = ({ options, icon = 'wrench', label, ...props }: TableDropdownProps) => {
    const { isGranted } = useAcl();
    const filteredOptions = options.filter(option => (option.accessRight ? isGranted(option.accessRight) : true));

    return (
        <TableDropdownComponent
            icon={icon}
            label={label && <FormattedMessage id={label} />}
            options={filteredOptions}
            {...props}
        />
    );
};

export default TableDropdown;
