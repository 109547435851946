import { batchUpdateActionCreator, withRequestParams, withNoSite } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = batchUpdateActionCreator(name, schema, `${baseUrl}/batch`);
export const TYPES = types;

export default (values, sites) => {
    return withRequestParams({
        sites,
    })(withNoSite(actionCreator(values)));
};
