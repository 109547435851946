import { listActionCreator, withPage, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl, idAttribute } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl, idAttribute);
export const TYPES = types;

export type CartsListFilters = {
    brand?: string;
    sites?: string[];
    countries?: string[];
    itemsPerPage?: number;
    cartId?: string;
};

export default (page = 1, sort: any = null, allFilters: CartsListFilters, query: any = null) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    const action = {
        ...actionCreator(),
        noSite: true,
        noBrand: true,
    };

    const { cartId, ...filters } = allFilters;
    const filtersParams = { ...filters, brands: [filters.brand] };

    return withValues({
        sort,
        filters: filtersParams,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filtersParams,
        })(withPage(page)(action))
    );
};
