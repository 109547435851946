import { addOnlyTrueFilter } from '@utils/filters';

export const schema = 'newsletterSubscribers';
export const name = 'NEWSLETTER_SUBSCRIBERS';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/newsletter/subscribers`;

export interface NewsletterSubscriptionsFilters {
    sezaneSubscriptionStatus?: boolean;
    octobreSubscriptionStatus?: boolean;
    sezaneSubscriptionDateAfter?: string;
    sezaneSubscriptionDateBefore?: string;
    octobreSubscriptionDateAfter?: string;
    octobreSubscriptionDateBefore?: string;
}

export const getFilterParams = (filters: NewsletterSubscriptionsFilters) => {
    const {
        sezaneSubscriptionStatus,
        octobreSubscriptionStatus,
        sezaneSubscriptionDateAfter,
        sezaneSubscriptionDateBefore,
        octobreSubscriptionDateAfter,
        octobreSubscriptionDateBefore,
        ...otherFilters
    } = filters || {};

    let filterParams: any = { ...otherFilters };

    addOnlyTrueFilter('sezaneSubscriptionStatus', !!sezaneSubscriptionStatus, filterParams);
    addOnlyTrueFilter('octobreSubscriptionStatus', !!octobreSubscriptionStatus, filterParams);

    if (sezaneSubscriptionDateAfter || sezaneSubscriptionDateBefore) {
        filterParams['range[0]'] = 'sezaneSubscriptionDate';

        if (sezaneSubscriptionDateAfter) {
            filterParams['sezaneSubscriptionDate[after]'] = sezaneSubscriptionDateAfter;
        }
        if (sezaneSubscriptionDateBefore) {
            filterParams['sezaneSubscriptionDate[before]'] = sezaneSubscriptionDateBefore;
        }
    }

    if (octobreSubscriptionDateAfter || octobreSubscriptionDateBefore) {
        filterParams['range[0]'] = 'octobreSubscriptionDate';

        if (octobreSubscriptionDateAfter) {
            filterParams['octobreSubscriptionDate[after]'] = octobreSubscriptionDateAfter;
        }
        if (octobreSubscriptionDateBefore) {
            filterParams['octobreSubscriptionDate[before]'] = octobreSubscriptionDateBefore;
        }
    }

    return filterParams;
};
