/* eslint no-magic-numbers: ["error", {ignore: [0, 1, 2, 12, 100]}] */
/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import qs from 'qs';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Button, Link } from 'sezane-components';

import { SimpleInput } from '@components/form/input/abstract/InputRender';
import Price, { floatPriceToInteger, integerPriceToFloat } from '@components/price';
import Status from '@components/status';
import TranslatedProperty from '@components/translatable';
import { formats } from '@config/mediaLibrary';

export const formatDate = value => (value ? <FormattedDate value={value} /> : null);

export const formatTime = value =>
    value ? (
        <FormattedTime value={value} hour="2-digit" minute="2-digit" second="2-digit" timeZoneName="short" />
    ) : null;

export const formatDateTime = value =>
    value ? (
        <>
            <div>{formatDate(value)}</div>
            <span className="u-c(gray-500) nowrap">{formatTime(value)}</span>
        </>
    ) : null;

export const formatMetricHeight = value => {
    if (!value) {
        return '';
    }

    const inMeters = (value / 100).toString().split('.');
    const meters = inMeters[0];
    let centimeters = inMeters[1] || 0;
    while (centimeters.toString().length < 2) {
        centimeters += '0';
    }

    return `${meters}m${centimeters}`;
};

export const parseMetricHeight = value => {
    if (!value) {
        return '';
    }

    return parseInt(value.replace('m', ''), 10).toString();
};

export const formatImperialHeight = value => {
    if (!value) {
        return '';
    }

    const feet = ~~(value / 12);
    const inches = value % 12;

    return `${feet}′${inches}″`;
};

export const parseImperialHeight = value => {
    if (!value) {
        return '';
    }

    const values = value.replace('″', '').split('′');

    return (values[0] * 12 + values[1]).toString();
};

export const formatMedia = (media, alt, format = 'medium', className) => {
    if (!media || !media.cdnThumbnails || !media.cdnThumbnails[formats[format]]) {
        return null;
    }

    return <img src={media.cdnThumbnails[formats[format]]} alt={alt} className={className} />;
};

export const formatVideo = video =>
    video?.cdnSecureUrl ? (
        <video controls>
            <source src={video.cdnSecureUrl} />
            <FormattedMessage id="common.video_not_supported" />
        </video>
    ) : null;

/**
 * Temporary doc waiting for the refactor of this file
 *
 * @param id
 * @param {string} route
 * @param {string|JSX.Element|null|undefined} [label]
 * @param {object|null} [searchParams]
 * @param {string} [className]
 * @param {string} [dataTestId]
 * @returns
 */
export const formatLink = (id, route, label = null, searchParams = null, className = 'u-res(link)', dataTestId) => {
    const search = searchParams ? qs.stringify(searchParams) : null;
    const to = search ? { pathname: route, search } : route;

    return (
        <Link key={id} to={to} data-test-id={dataTestId} className={className}>
            {label !== null ? label : id}
        </Link>
    );
};

export const formatGlobalELink = externalId => (
    <Link href={`${window.REACT_APP_GLOBALE_URL}/cp/GlobaleAdmin/Orders/Order?orderID=${externalId}`} target="_blank">
        <pre className="u-d(inline)">{externalId}</pre>
    </Link>
);

export const formatStatus = enabled => {
    return (
        <Status
            size="lg"
            label={<FormattedMessage id={enabled ? 'common.status.enabled' : 'common.status.disabled'} />}
            color={enabled ? 'success' : 'danger'}
            icon={enabled ? 'check' : 'times'}
        />
    );
};

export const formatAction = (key, route, className, icon, children, size = 'xs', color = 'primary', iconClassName) => (
    <Button
        icon={icon}
        className={className}
        key={key}
        size={size}
        color={color}
        linkTo={route}
        iconClassName={iconClassName}
    >
        {children}
    </Button>
);

export const formatBtnAction = (
    size,
    color,
    icon,
    onClick,
    key,
    hiddenLabel = <FormattedMessage id="common.action" />
) => <Button icon={icon} key={key} size={size} color={color} onClick={onClick} iconOnly hiddenLabel={hiddenLabel} />;

export const formatTranslated = (item, field, fallback) => (
    <TranslatedProperty object={item} property={field} fallback={fallback} />
);

export const formatStaticMessage = (value, icon, className) => {
    if (icon) {
        return (
            <span className={classNames('table__field-icon', className)}>
                {icon}
                <FormattedMessage id={value} />
            </span>
        );
    }

    if (value) {
        return (
            <span>
                <FormattedMessage id={value} />
            </span>
        );
    }

    return null;
};

export const formatArray = (values, formatter, spanWrapper = true) => {
    const fields = values.map(value => formatter(value));
    const TagName = spanWrapper ? 'span' : React.Fragment;

    return <TagName>{fields.map((field, idx) => (idx === 0 ? field : [', ', field]))}</TagName>;
};

export const formatPrice = (prices, currency) => <Price currency={currency} prices={prices} />;

export const formatPriceInput = (
    onChange,
    value,
    currencyData,
    size = 'sm',
    fieldSize = 'sm',
    disabled,
    deleteIcon,
    name
) => {
    const intValue = integerPriceToFloat(value);

    return (
        <SimpleInput
            inputSize={fieldSize}
            type="number"
            name={name}
            onChange={e => {
                const floatVal = e.target.value;
                if (floatVal === undefined || floatVal === null || floatVal === '') {
                    onChange(null);

                    return;
                }

                onChange(floatPriceToInteger(floatVal));
            }}
            value={value === undefined || value === null || value === '' ? '' : intValue}
            attributes={{ step: '1', min: 0 }}
            aside={
                !!currencyData && (
                    <div className="u-d(flex) u-ai(center)">
                        <span className={`${size === 'xs' ? 'u-m(xxs)' : 'u-m(xs)'} u-pb(xxxxs) u-fz(sm)`}>
                            {currencyData.label}
                        </span>
                        {deleteIcon && (
                            <Button
                                icon="times"
                                iconOnly
                                color="unstyled"
                                size={size}
                                onClick={() => onChange(null)}
                                hiddenLabel={<FormattedMessage id="common.delete" />}
                            />
                        )}
                    </div>
                )
            }
            disabled={disabled}
        />
    );
};
