export const FETCH_REFERENCES = 'OMS_FETCH_REFERENCES';
export const FETCH_REFERENCES_SUCCESS = 'OMS_FETCH_REFERENCES_SUCCESS';
export const FETCH_REFERENCES_FAILURE = 'OMS_FETCH_REFERENCES_FAILURE';

export default () => ({
    types: [FETCH_REFERENCES, FETCH_REFERENCES_SUCCESS, FETCH_REFERENCES_FAILURE],
    payload: {
        request: {
            url: `${window.REACT_APP_OMS_API_URL}/oms-private/v1/references`,
            method: 'GET',
        },
    },
    noSite: true,
});
