import { schema } from '@actions/sites/config';
import { TYPES as LIST_TYPES } from '@actions/sites/list';
import { TYPES as GET_TYPES } from '@actions/sites/get';
import { TYPES as CREATE_TYPES } from '@actions/sites/create';
import { TYPES as UPDATE_TYPES } from '@actions/sites/update';
import { TYPES as DELETE_TYPES } from '@actions/sites/delete';
import {
    CRUD_INITIAL_STATE,
    reduceReducers,
    listReducer,
    createReducer,
    updateReducer,
    deleteReducer,
    getReducer,
} from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    listReducer(LIST_TYPES, schema),
    getReducer(GET_TYPES, schema),
    createReducer(CREATE_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    deleteReducer(DELETE_TYPES)
);
