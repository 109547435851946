import { listActionCreator, withPage, withRequestParams, withValues } from '@actions/crud';
import { baseUrl, name, schema } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl, 'uid');
export const TYPES = types;

export default (page = 1, sort: any = null, filters: any = null, query: any = null) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    return withValues({
        sort,
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filters,
        })(withPage(page)(actionCreator()))
    );
};
