import { keycloakInstance } from 'sezane-components';
import errorHandler from '@api/errorHandler';

import { Api } from '../../types/ecommerce';

const ecommerceApiClient = new Api({
    baseUrl: window.REACT_APP_API_URL,
    baseApiParams: {
        headers: {
            Accept: 'application/json',
        },
        secure: true,
    },
    securityWorker: () => {
        return {
            headers: {
                Authorization: `Bearer ${keycloakInstance.token}`,
            },
        };
    },
});

export default new Proxy(ecommerceApiClient.ecommercePrivate, {
    get(target, propKey: keyof typeof ecommerceApiClient.ecommercePrivate) {
        const origMethod = target[propKey];
        if (typeof origMethod !== 'function') {
            return origMethod;
        }

        return function (...args: any[]) {
            // @ts-ignore
            let result = origMethod.apply(this, args);
            return result.catch(errorHandler);
        };
    },
});
