import { listActionCreator, withPage, withNoSite, withNoBrand, withRequestParams, withValues } from '../crud';
import { getFilterParams, name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, filters = {}) => {
    const filterParams = getFilterParams(filters);

    return withValues({
        filters,
    })(
        withRequestParams({
            ...filterParams,
        })(withNoSite(withPage(page)(withNoBrand(actionCreator()))))
    );
};
