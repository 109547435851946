import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import isString from 'lodash/isString';
import classNames from 'classnames';

import InheritedValue from '@components/form/input/abstract/InheritedValue';
import { Grid, Col } from 'sezane-components';
import { useIntl } from 'react-intl';
import { Size } from '@types';

export const SimpleInput: FC<SimpleInputProps> = ({
    aside,
    asideIcon,
    attributes,
    autocomplete,
    checked,
    className,
    disabled,
    id,
    inherited,
    inputSize = 'sm',
    label,
    meta,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder: propsPlaceholder,
    pristineValue,
    refCallback = () => {},
    secondary,
    type,
    value: propsValue,
    withPristineValue,
}) => {
    const intl = useIntl();
    let placeholder: string | number | undefined = propsPlaceholder;
    if (typeof placeholder === 'undefined' && isString(label)) {
        placeholder = label;
    }
    placeholder = placeholder ? intl.formatMessage({ id: placeholder }) : '';
    // surcharge placeholderValue if with pristine value
    if (withPristineValue) {
        placeholder = pristineValue;
    }

    const value = propsValue === null ? (type === 'checkbox' ? undefined : '') : propsValue;

    const input = (
        <input
            className={classNames(
                'c-field c-form-group__field',
                {
                    'c-field--secondary': secondary,
                    [`c-field--${inputSize}`]: inputSize !== 'md',
                    'u-c(gray-200)': withPristineValue && meta && meta.pristine,
                    'c-field--compact': withPristineValue,
                },
                className
            )}
            autoComplete={autocomplete}
            ref={refCallback()}
            placeholder={placeholder}
            disabled={disabled}
            id={id}
            type={type}
            {...attributes}
            checked={checked}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            // Fix to prevent changing the value in number inputs
            onWheel={e => e.currentTarget.blur()}
        />
    );

    if (!aside && !inherited && !withPristineValue) {
        return input;
    }

    if (withPristineValue) {
        return (
            <Grid gutter="none">
                <Col
                    number="5"
                    className={classNames('u-ta(right) u-m(auto)', {
                        'u-c(gray-500)': !(meta && meta.pristine),
                    })}
                >
                    {pristineValue}
                </Col>
                <Col number="7" className="u-pl(xxxs)">
                    {input}
                </Col>
            </Grid>
        );
    }

    const asideClassName = classNames('c-form-group__button', {
        'c-button': asideIcon,
        'c-button--icon-only': asideIcon,
        'c-button--unstyled': asideIcon,
        'u-cursor(none)': asideIcon,
        [`c-button--${inputSize}`]: asideIcon && inputSize !== 'md',
    });

    return (
        <div className="u-d(flex) u-fxd(column) u-pos(relative)">
            {input}
            <span className={asideClassName}>
                {aside}
                <InheritedValue inherited={inherited} size={inputSize} />
            </span>
        </div>
    );
};

const InputRender: FC<InputRenderProps> = ({ input, type, ...rest }) => (
    // TODO: remove this default input.type value?
    <SimpleInput {...input} {...rest} type={type || (input as any).type} />
);

interface SimpleInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onDragStart' | 'onDrop' | 'size'> {
    aside?: ReactNode;
    asideIcon?: boolean;
    attributes?: any;
    autocomplete?: string;
    inherited?: ReactNode;
    inputSize?: Size;
    label?: ReactElement | string;
    meta?: WrappedFieldMetaProps;
    pristineValue?: string | number;
    refCallback?: () => void;
    secondary?: boolean;
    withPristineValue?: boolean;
    size?: Size;
}

interface InputRenderProps extends SimpleInputProps {
    input?: WrappedFieldInputProps;
}

export default InputRender;
