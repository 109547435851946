import { updateActionCreator, ID_PLACEHOLDER, withRequestParams } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = updateActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}`);
export const TYPES = types;

export default (id, values, workspaces) => {
    // Since we use PUT and we update workspaces via query params, we need to remove workspaces from the body to prevent
    // an overriding by API platform
    if (values.workspaces) {
        delete values.workspaces;
    }

    return withRequestParams({
        workspaces,
    })(actionCreator(id, values, workspaces));
};
