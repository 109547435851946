import oms from '@api/clients/oms';
import routesNames from '@config/routesNames';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Case, Col, DateInput, Grid, Link } from 'sezane-components';

import { PLACEHOLDER } from '@config/mediaLibrary';
import { formatShortDate } from '@utils/dates';
import { useAppSelector } from '@utils/hooks/redux';
import { WidgetDefaultProps } from '..';

const BestProducts = ({ w }: WidgetDefaultProps) => {
    const [date, setDate] = React.useState<Date>();
    const { brand } = useAppSelector(state => ({ brand: state.ui.brand }));
    const { data } = useQuery(['best-products', date, brand], () =>
        oms.getBestProductsWidget({
            page: 1,
            itemsPerPage: 24,
            brandCode: brand.code as 'sezane' | 'octobre',
            date: date ? formatShortDate(date) : 'now',
        })
    );
    let bestProducts = data?.data || [];

    return (
        <Case size="sm" color="primary" style={{ height: '100%', overflow: 'auto' }}>
            <div className="u-d(flex) u-jc(space-between) u-ai(center)">
                <h2 className="u-pt(xs) u-pb(xs) u-h3 u-tt(uppercase) u-fw(bolder) u-c(info)">
                    <FormattedMessage id="dashboard.best_products.title" />
                </h2>
                <DateInput
                    selected={date}
                    maxDate={new Date()}
                    /* @ts-expect-error */
                    onChange={value => setDate(value)}
                    className="u-w(150p)"
                    calendarClassName="react-datepicker--right"
                    alignRight
                />
            </div>
            <Grid gutter="xs" number={8}>
                {bestProducts?.map((product, index) => (
                    <Col
                        key={index}
                        number={w === 1 ? 2 : 1}
                        numberTablet={2}
                        className="c-preview u-fxw(nowrap) u-ai(center) u-mt(xs) u-fz(sm)"
                    >
                        <img
                            src={product.variant_image || PLACEHOLDER}
                            onError={e => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = PLACEHOLDER;
                            }}
                            alt={product.variant_label}
                            className="u-mb(xs)"
                            style={{ maxHeight: w === 1 ? '175px' : '200px', width: '100%', objectFit: 'cover' }}
                        />
                        <p className="u-mt(none) u-d(flex) u-jc(space-between)">
                            <Link to={`${routesNames.LIST_PRODUCTS}?search=${product.product_code}`}>
                                <strong>{product.variant_label}</strong>
                            </Link>{' '}
                            <span style={{ minWidth: 40, textAlign: 'right' }}>
                                <FormattedNumber
                                    value={product.unit_vat_included!}
                                    style="currency"
                                    maximumFractionDigits={0}
                                    currency={'EUR'}
                                />
                            </span>
                        </p>
                        <ul className="u-mt(xxs) u-res(list)">
                            <li className="u-d(flex) u-jc(space-between)">
                                <FormattedMessage id="dashboard.best_products.quantity" />{' '}
                                <span>{product.quantity}</span>
                            </li>
                            <li className="u-d(flex) u-jc(space-between)">
                                <FormattedMessage id="dashboard.best_products.stock_web" />{' '}
                                <span>{product.web_available}</span>
                            </li>
                            <li className="u-d(flex) u-jc(space-between)">
                                <FormattedMessage id="dashboard.best_products.stock_warehouse" />{' '}
                                <span>{product.warehouse_available}</span>
                            </li>
                            <li className="u-d(flex) u-jc(space-between)">
                                <FormattedMessage id="dashboard.best_products.stock_retail" />{' '}
                                <span>{product.retail_available}</span>
                            </li>
                        </ul>
                    </Col>
                ))}
            </Grid>
        </Case>
    );
};

export default BestProducts;
