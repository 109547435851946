import { produce } from 'immer';
import { schema } from '@actions/catalogProducts/config';
import { TYPES as GET_TYPES } from '@actions/catalogProducts/get';
import { TYPES as PATCH_TYPES } from '@actions/catalogProducts/patch';
import { CHANGE_EDITION_CONFIGURATION } from '@actions/catalogProducts/configuration';
import { DEFAULT_PRESET } from '@containers/products/edition/Configuration';
import { TYPES as PATCH_CATALOG_VARIANT_TYPES } from '@actions/catalogVariants/patch';
import { reduceReducers, patchReducer, CRUD_INITIAL_STATE } from './crud';

export const INITIAL_STATE = {
    ...CRUD_INITIAL_STATE,
    editionConfiguration: DEFAULT_PRESET,
};

const patchCatalogVariantReducer = (state, action) => {
    switch (action.type) {
        case PATCH_CATALOG_VARIANT_TYPES.PATCH_SUCCESS: {
            const catalogVariant = action.payload.rawData;

            return produce(state, draftState => {
                const catalogProduct = draftState.entities[catalogVariant.product.id];
                if (!catalogProduct) return draftState;

                const variantIndex = catalogProduct.variants.findIndex(variant => variant.id === catalogVariant.id);
                const previousVariantState = draftState.entities[catalogVariant.product.id].variants[variantIndex];
                draftState.entities[catalogVariant.product.id].variants[variantIndex] = {
                    ...previousVariantState,
                    // Only update the pim wrapper state since serialization groups are so different
                    pimWrapper: catalogVariant.pimWrapper,
                };

                return draftState;
            });
        }

        default:
            return state;
    }
};

const configurationReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_EDITION_CONFIGURATION: {
            return {
                ...state,
                editionConfiguration: {
                    ...state.editionConfiguration,
                    ...action.values,
                },
            };
        }

        default:
            return state;
    }
};

const getReducer = (state, action) => {
    switch (action.type) {
        case GET_TYPES.GET: {
            return {
                ...state,
                loading: true,
                loaders: {
                    ...state.loaders,
                    get: true,
                },
                error: false,
            };
        }
        case GET_TYPES.GET_SUCCESS: {
            const variantLoaded = Number(action.meta.previousAction.payload?.request.params.variantId);
            const stateEntity = state.entities[action.payload.rawData?.id];
            const apiEntity = action.payload.rawData;
            let entities = {
                ...state.entities,
                ...(action.payload.entities[schema] || {}),
            };

            // We keep previous loaded data from the API but update store variants
            if (stateEntity && variantLoaded) {
                entities = {
                    ...state.entities,
                    [stateEntity.id]: {
                        ...stateEntity,
                        variants: stateEntity.variants.map(variant => {
                            if (variant.id === variantLoaded) {
                                return apiEntity.variants.find(variant => variant.id === variantLoaded);
                            }

                            return variant;
                        }),
                    },
                };
            }

            return {
                ...state,
                entities,
                loading: false,
                loaders: {
                    ...state.loaders,
                    get: false,
                },
                error: false,
            };
        }
        case GET_TYPES.GET_FAILURE: {
            return {
                ...state,
                loading: false,
                loaders: {
                    ...state.loaders,
                    get: false,
                },
                error: true,
            };
        }

        default:
            return state;
    }
};

export default reduceReducers(
    getReducer,
    patchReducer(PATCH_TYPES, schema),
    patchCatalogVariantReducer,
    configurationReducer,
    INITIAL_STATE
);
