import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Icon } from 'sezane-components';

import { VIEWS, CUSTOM_VIEW } from './builder';

const ListViewToggle = ({ view, changeListView }) => (
    <Dropdown
        icon={<Icon icon="eye" />}
        size="sm"
        label={
            <>
                <strong>
                    <FormattedMessage id="products.configuration.views.prefix" />
                </strong>
                <span>&nbsp;</span>
                <FormattedMessage id={`products.configuration.views.${view}`} />
            </>
        }
        options={VIEWS.map(view => ({
            renderOption: () => (
                <span>
                    <FormattedMessage id={`products.configuration.views.${view}`} />
                </span>
            ),
            onSelect: () => changeListView(view),
        }))}
    />
);

ListViewToggle.propTypes = {
    view: PropTypes.oneOf([...VIEWS, CUSTOM_VIEW]).isRequired,
    changeListView: PropTypes.func.isRequired,
};

export default ListViewToggle;
