export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_CLEAR_RESULTS = 'SEARCH_CLEAR_RESULTS';

export const search = (query = null) => ({
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE],
    payload: {
        request: {
            url: 'search',
            method: 'GET',
            params: { q: query },
        },
    },
    query,
});

export const clearResults = () => ({
    type: SEARCH_CLEAR_RESULTS,
});
