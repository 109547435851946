import { name } from '@actions/storeVariants/config';

export const CHANGE_LIST_CONFIGURATION = `CHANGE_${name}_LIST_CONFIGURATION`;
export const CHANGE_LIST_VIEW = `CHANGE_${name}_LIST_VIEW`;

export const changeListConfiguration = values => ({
    type: CHANGE_LIST_CONFIGURATION,
    values,
});

export const changeListView = view => ({
    type: CHANGE_LIST_VIEW,
    view,
});
