import { listActionCreator, withPage, withRequestParams, withValues, withNoSite } from '../crud';
import { name, schema, baseUrl, getFilterParams } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, sort = null, filters = null, query = null) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const filterParams = getFilterParams(filters);

    return withValues({
        sort,
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filterParams,
        })(withPage(page)(withNoSite(actionCreator())))
    );
};
