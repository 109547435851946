import { updateActionCreator, ID_PLACEHOLDER, withRequestParams, notifyPendingCreation } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = updateActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}`);
export const TYPES = types;

export const getAction = (id, values, workspaces) =>
    withRequestParams({
        workspaces,
    })(actionCreator(id, values));

export default (id, values, workspaces) => {
    const action = getAction(id, values, workspaces);

    return notifyPendingCreation(action, 'sales.duplicate.implicit_pending', schema);
};
