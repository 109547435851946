import FileSaver from 'file-saver';

export const saveFileFromApiResponse = (response: any, defaultFileName: string) => {
    const fileName = response.payload.fileName || defaultFileName;
    saveFile(response.payload.rawData, fileName);
};

export const saveFile = (data: Blob | string, fileName: string) => {
    FileSaver.saveAs(data, fileName);
};
