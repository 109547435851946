import { schema } from '@actions/compositions/config';
import { TYPES as BATCH_UPDATE_TYPES } from '@actions/compositions/batchUpdate';
import { TYPES as DELETE_TYPES } from '@actions/compositions/delete';
import { TYPES as UPDATE_TYPES } from '@actions/compositions/update';
import { reduceReducers, updateReducer, deleteReducer, batchUpdateReducer, CRUD_INITIAL_STATE } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    updateReducer(UPDATE_TYPES, schema),
    batchUpdateReducer(BATCH_UPDATE_TYPES, schema),
    deleteReducer(DELETE_TYPES)
);
