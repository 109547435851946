import { Site, Workspace } from '@types';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export const STATUS_ONLINE = 'online';
export const STATUS_OFFLINE = 'offline';
export const STATUS_SCHEDULED = 'scheduled';

export const findWorkspaceInSiteForNextDay = (workspaces: Workspace[], siteId: number, date: Date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);

    return findWorkspaceInSiteByDate(workspaces, siteId, date);
};

export const findWorkspaceInSiteByDate = (workspaces: Workspace[], siteId: number, date: Date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);

    return workspaces.find(
        workspace =>
            workspace.site?.id === siteId &&
            nextDay >= new Date(workspace.beginOn) &&
            nextDay < new Date(workspace.finishOn!)
    );
};

export const findWorkspaceInSite = (workspaces: Workspace[], siteId: number) => {
    return workspaces.find(workspace => workspace.site?.id === siteId);
};

export const filterWorkspacesBySite = (workspaces: Workspace[], siteId: number) => {
    if (!siteId) {
        throw Error('No site id specified to filter by site.');
    }

    return workspaces.filter(workspace => workspace.site?.id === siteId);
};

export const workspaceHasStatus = (workspace: Workspace, status: string) => workspace.status === status;

export const workspaceIsOnline = (workspace: Workspace) => workspaceHasStatus(workspace, STATUS_ONLINE);

export const workspaceIsOffline = (workspace: Workspace) => workspaceHasStatus(workspace, STATUS_OFFLINE);

export const workspaceIsScheduled = (workspace: Workspace) => workspaceHasStatus(workspace, STATUS_SCHEDULED);

export const filterWorkspacesByStatus = (workspaces: Workspace[], status: string) => {
    return workspaces.filter(workspace => workspaceHasStatus(workspace, status));
};

export const filterOnlineWorkspaces = (workspaces: Workspace[]) => filterWorkspacesByStatus(workspaces, STATUS_ONLINE);

export const filterOfflineWorkspaces = (workspaces: Workspace[]) =>
    filterWorkspacesByStatus(workspaces, STATUS_OFFLINE);

export const filterScheduledWorkspaces = (workspaces: Workspace[]) =>
    filterWorkspacesByStatus(workspaces, STATUS_SCHEDULED);

export const filterWorkspacesByStatusExcluded = (workspaces: Workspace[], status: string) => {
    return workspaces.filter(workspace => workspace.status !== status);
};

export const filterNotOnlineWorkspaces = (workspaces: Workspace[]) =>
    filterWorkspacesByStatusExcluded(workspaces, STATUS_ONLINE);

export const filterNotOfflineWorkspaces = (workspaces: Workspace[]) =>
    filterWorkspacesByStatusExcluded(workspaces, STATUS_OFFLINE);

export const filterNotScheduledWorkspaces = (workspaces: Workspace[]) =>
    filterWorkspacesByStatusExcluded(workspaces, STATUS_SCHEDULED);

export const findPreviousWorkspace = (currentWorkspace: Workspace, workspaces: Workspace[]) => {
    return sortByNewest(workspaces).find(
        workspace => workspace.site?.id === currentWorkspace.site?.id && currentWorkspace.beginOn > workspace.beginOn
    );
};

export const sortByOldest = (workspaces: Workspace[]) => {
    return [...workspaces].sort((a, b) => (a.beginOn < b.beginOn ? -1 : 1));
};

export const sortByNewest = (workspaces: Workspace[]) => {
    return [...workspaces].sort((a, b) => (a.beginOn > b.beginOn ? -1 : 1));
};

export const findPreviousWorkspaceForSite = (workspaces: Workspace[], siteId: number) => {
    const siteWorkspaces = filterWorkspacesBySite(workspaces, siteId);
    const previousWorkspacesInSite = filterOfflineWorkspaces(siteWorkspaces);

    if (!previousWorkspacesInSite.length) {
        return null;
    }
    sortByNewest(previousWorkspacesInSite);

    return previousWorkspacesInSite[0];
};

export const findActiveWorkspace = (workspaces: Workspace[]) => {
    const onlineWorkspacesInSite = filterOnlineWorkspaces(workspaces);
    if (!onlineWorkspacesInSite.length) {
        return null;
    }

    return onlineWorkspacesInSite[0];
};

export const findActiveWorkspaceForSite = (workspaces: Workspace[], siteId: number) => {
    const siteWorkspaces = filterWorkspacesBySite(workspaces, siteId);

    return findActiveWorkspace(siteWorkspaces);
};

export const isFirstWorkspace = (workspace: Workspace, workspaces: Workspace[]) => {
    const previous = findPreviousWorkspace(workspace, workspaces);

    return !!previous;
};

export const getFutureWorkspaces = (workspace: Workspace, workspaces: Workspace[], equal = true) => {
    return workspaces.filter(ws => {
        return equal ? ws.beginOn >= workspace.beginOn : ws.beginOn > workspace.beginOn;
    });
};

export const canPublish = (workspace: Workspace, workspaces: Workspace[]) => {
    if (workspace.status !== STATUS_SCHEDULED) {
        return false;
    }

    const previous = findPreviousWorkspace(workspace, workspaces);

    return !previous || previous.status !== STATUS_SCHEDULED;
};

export const canDelete = (workspace: Workspace) => workspace.status === STATUS_SCHEDULED;

export const getDefaultWorkspaces = (currentWorkspace: Workspace, workspaces: Workspace[]) => {
    let defaultWorkspaces: number[] = [];
    if (currentWorkspace === null) {
        return [];
    }

    defaultWorkspaces.push(currentWorkspace.id!);

    const similarWorkspaces = findSimilarWorkspaces(workspaces, currentWorkspace);
    similarWorkspaces.forEach(workspace => {
        const workspacesInSite = filterWorkspacesBySite(workspaces, workspace.site?.id!);
        const futureWorkspaces = getFutureWorkspaces(workspace, workspacesInSite).map(ws => ws.id!);
        defaultWorkspaces = [...defaultWorkspaces, ...futureWorkspaces];
    });

    if (defaultWorkspaces.length > 0) {
        return uniq(defaultWorkspaces);
    }

    return currentWorkspace ? [currentWorkspace.id!] : [];
};

export const displayNextWorkspaceAvailableWarning = (
    currentItem: any,
    data: any,
    workspace: Workspace,
    workspaces: Workspace[],
    key: string,
    type: string
) => {
    if (!data || !data[key]) {
        return;
    }

    if (currentItem !== null && currentItem.status !== 'disabled') {
        return;
    }

    const nextWorkspaces = getFutureWorkspaces(workspace, values(workspaces), false).map(ws => ws.id);

    let nextEnabledWorkspaceId = null;
    const workspacesAwareItems = data[key];
    for (let i = 0; i < workspacesAwareItems.length; i++) {
        const item = workspacesAwareItems[i];

        if (item.workspacesIds && item.status === 'enabled') {
            const diff = intersection(nextWorkspaces, item.workspacesIds);

            if (diff.length > 0) {
                nextEnabledWorkspaceId = diff[0];
                break;
            }
        }
    }

    return nextEnabledWorkspaceId && workspaces[nextEnabledWorkspaceId] ? (
        <FormattedMessage
            id={`workspaces.${type}.disabled_workspace_next`}
            values={{
                label: workspaces[nextEnabledWorkspaceId].label,
                date: <FormattedDate value={workspaces[nextEnabledWorkspaceId].beginOn} />,
            }}
        />
    ) : (
        <FormattedMessage id={`workspaces.${type}.disabled`} />
    );
};

export const findSimilarWorkspaces = (workspaces: Workspace[], oldWorkspace: Workspace) => {
    return workspaces.filter(workspace => workspace.label === oldWorkspace.label);
};

export const findSimilarWorkspace = (workspaces: Workspace[], oldWorkspace: Workspace) => {
    const similarWorkspaces = findSimilarWorkspaces(workspaces, oldWorkspace);

    return similarWorkspaces.length > 0 ? similarWorkspaces[0] : null;
};

export const findSimilarWorkspaceForSite = (site: Site, workspaces: Workspace[], oldWorkspace: Workspace) => {
    const workspacesInSite = filterWorkspacesBySite(workspaces, site.id!);

    return findSimilarWorkspace(workspacesInSite, oldWorkspace);
};

export const getWorkspacesWithExistingVersion = (
    currentWorkspaceIds: number | number[],
    allWorkspacesIds: number[] = []
) => {
    return difference(
        allWorkspacesIds,
        Array.isArray(currentWorkspaceIds) ? currentWorkspaceIds : [currentWorkspaceIds]
    );
};
