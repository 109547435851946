import PropTypes from 'prop-types';

import type translationType from '../translations/fr.json';

// TODO: remove this and use react-intl typings instead
export const IntlPropType = PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
});

export type LocaleMessages = typeof translationType;
// TODO See if we can use this type on the whole app
export type LocaleKey = keyof LocaleMessages;

export type SupportedLocales = 'fr' | 'en' | 'es';
export const SUPPORTED_LANGAGES: SupportedLocales[] = ['fr', 'en', 'es'];

export function importMessages(locale: SupportedLocales): Promise<Partial<LocaleMessages>> {
    switch (locale) {
        case 'fr':
            return import('@translations/fr.json').then(json => json.default);
        case 'en':
            return import('@translations/en.json').then(json => json.default);
        case 'es':
            return import('@translations/es.json').then(json => json.default);
    }
}

export function getUserLocale(): SupportedLocales {
    let locale: SupportedLocales = 'fr';

    for (const lang of navigator.languages) {
        if (SUPPORTED_LANGAGES.includes(lang as SupportedLocales)) {
            locale = lang as SupportedLocales;
            break;
        }
    }

    return locale;
}
