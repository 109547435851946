import { listActionCreator, withPage, withRequestParams, withValues } from '../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, sort: any = null, filters: any = null, query: any = null) => {
    let action = actionCreator();
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    return withValues({
        sort,
        filters,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filters,
        })(withPage(page)(action))
    );
};
