import { SortParams } from '@types';
import { listActionCreator, withPage, withRequestParams, withValues, withNoSite } from '../../crud';
import { name, schema, baseUrl, getFilterParams, EcardFilters } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (page = 1, sort: SortParams, filters: EcardFilters, query: any) => {
    const filterParams = getFilterParams(filters);
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    return withValues({
        sort,
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filterParams,
        })(withPage(page)(withNoSite(actionCreator())))
    );
};
