export const schema = 'user-credits';
export const name = 'USER_CREDITS';
export const baseUrl = `${window.REACT_APP_OMS_API_URL}/oms-private/v1/user/credit`;

export interface UserCreditFilters {
    amountAfter?: string;
    amountBefore?: string;
    brandCode?: string;
    createdAfter?: string;
    createdBefore?: string;
    currencyCode?: string[];
    enabled?: boolean;
    status?: ('actif' | 'inactif')[];
}

export const getFilterParams = (filters: UserCreditFilters) => {
    const {
        amountAfter,
        amountBefore,
        brandCode,
        createdAfter,
        createdBefore,
        currencyCode,
        enabled,
        status,
        ...otherFilters
    } = filters || {};

    let filterParams: Record<string, any> = { ...otherFilters };

    if (currencyCode?.length) {
        filterParams['terms[]'] = 'currencyCode';
        filterParams['currencyCode'] = currencyCode;
    }

    if (brandCode) {
        filterParams['term[]'] = 'brandFilter';
        filterParams['brandFilter'] = brandCode;
    }

    if (createdAfter || createdBefore) {
        filterParams['range[0]'] = 'createdAt';

        if (createdAfter) {
            filterParams['createdAt[after]'] = createdAfter.split('T')[0];
        }
        if (createdBefore) {
            filterParams['createdAt[before]'] = createdBefore.split('T')[0];
        }
    }

    if (amountAfter || amountBefore) {
        filterParams['range[1]'] = 'amount';

        if (amountAfter) {
            filterParams['amount[after]'] = amountAfter;
        }
        if (amountBefore) {
            filterParams['amount[before]'] = amountBefore;
        }
    }

    if (status !== undefined) {
        if (status.includes('actif') && !status.includes('inactif')) {
            filterParams['enabled'] = true;
        } else if (!status.includes('actif') && status.includes('inactif')) {
            filterParams['enabled'] = false;
        }
    }

    return filterParams;
};
