import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldInputProps } from 'redux-form';

import { Size } from '@types';

export const SegmentedRadioButtons: FC<SimpleRadioProps> = ({ className, listClassName, buttons }) => (
    <fieldset className={classNames('c-form-group c-segmented-control u-d(inline-block) u-mt(none)', className)}>
        <div className={classNames('c-segmented-control__list', listClassName)}>
            {Object.values(buttons)}
            <span className="c-segmented-control__mask" />
        </div>
    </fieldset>
);

export const SimpleRadioButtons: FC<SimpleRadioProps> = ({ className, buttons }) => (
    <ul className={classNames('u-res(list)', className)}>
        {Object.keys(buttons).map(key => (
            <li key={key} className="c-form-group u-mt(xs)">
                {buttons[key]}
            </li>
        ))}
    </ul>
);

export const RadioInput: FC<RadioInputProps> = ({
    segmented,
    id,
    name,
    value,
    onChange,
    checked,
    disabled,
    labelClassName,
    secondary,
    labelSecondary,
    label,
    size,
}) => {
    const labelElement = isString(label) && label.includes('.') ? <FormattedMessage id={label} /> : label;

    return (
        <React.Fragment>
            <input
                className={classNames({
                    'u-hidden': true,
                    'c-field c-form-group__field': !segmented,
                    'c-segmented-control__radio': !!segmented,
                    'u-pe(none)': disabled,
                })}
                type="radio"
                id={id}
                name={name}
                value={value}
                onChange={e => onChange && onChange(e)}
                checked={checked}
                disabled={disabled}
            />
            <label
                htmlFor={id}
                className={classNames(labelClassName, {
                    'c-label c-label--check c-label--radio c-form-group__label': !segmented,
                    'c-label--radio-secondary': !segmented && secondary,
                    'c-label--secondary': !segmented && labelSecondary,
                    'c-label--only': !segmented && !labelElement,
                    [`c-label--${size}`]: !segmented && size,
                    'c-segmented-control__label': segmented,
                })}
            >
                {labelElement}
            </label>
        </React.Fragment>
    );
};

export const Radio: FC<RadioProps> = props => {
    const { id, input, label, size, segmented, labelSecondary, secondary, value, disabled, labelClassName } = props;

    return (
        <RadioInput
            segmented={segmented}
            id={id}
            name={input.name}
            value={value}
            onChange={input.onChange}
            checked={input.value === value}
            disabled={disabled}
            labelClassName={labelClassName}
            secondary={secondary}
            labelSecondary={labelSecondary}
            label={label}
            size={size}
        />
    );
};

interface SimpleRadioProps {
    className?: string;
    listClassName?: string;
    buttons: Record<string, any>;
}

interface CommonRadioProps {
    disabled?: boolean;
    id: string;
    label?: ReactNode;
    labelClassName?: string;
    labelSecondary?: boolean;
    secondary?: boolean;
    segmented?: boolean;
    size?: Size;
    value: any;
}

type RadioInputProps = CommonRadioProps & Partial<WrappedFieldInputProps>;

interface RadioProps extends CommonRadioProps {
    input: WrappedFieldInputProps;
}

export default Radio;
