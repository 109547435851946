import { parseISO, format, isValid } from 'date-fns';

type IsoDateString =
    | `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`
    | `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
type ShortDate = `${number}-${string}-${string}`;

/* eslint-disable no-magic-numbers */
export const STANDARD_CALENDAR_DATE = 'P';
export const WITH_TIME_CALENDAR_DATE = 'Pp';
export const TIME_FORMAT = 'p';

export const getDateFormat = (withTime?: boolean, showTimeOnly?: boolean) => {
    if (showTimeOnly) {
        return TIME_FORMAT;
    }

    return withTime ? WITH_TIME_CALENDAR_DATE : STANDARD_CALENDAR_DATE;
};

export const getMinTimeFromDate = (date?: string | number | Date) => {
    if (!date) {
        return undefined;
    }

    const baseDate = new Date(date);
    const minTime = new Date();
    minTime.setHours(baseDate.getHours(), baseDate.getMinutes(), baseDate.getSeconds());

    return minTime;
};

export const getEndOfDayTime = () => {
    const time = new Date();
    time.setHours(23, 59, 59);

    return time;
};

export const parseDate = (value?: string | Date) => {
    if (!value || value === '') {
        return null;
    }

    if (typeof value === 'string') {
        return !isNaN(Date.parse(value)) ? new Date(value) : null;
    }

    return value;
};

export const getTimeFromDate = (date?: Date): string | undefined => {
    if (!date) {
        return undefined;
    }

    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
};

/**
 * @param {string} time Format hh:mm
 */
export const setDateFromTime = (time: string = '0:0'): Date | undefined => {
    const [hours, minutes] = time.split(':');

    const baseDate = new Date();
    baseDate.setHours(hours ? parseInt(hours) : 0);
    baseDate.setMinutes(minutes ? parseInt(minutes) : 0);
    baseDate.setSeconds(0);

    return baseDate;
};

/**
 * This function is used to format a date sent to the API.
 * APIP can understand the ISO 8601 Extended Format,
 * sadly PHP (and so Jane) doesn't support fully the extended format.
 */
export const formatForApi = (date?: string | Date) => {
    if (!date) return null;
    return format(date instanceof Date ? date : parseISO(date), "y-MM-dd'T'HH:mm:ssXXX");
};

export const formatShortDate = (input: Date | IsoDateString): ShortDate | undefined => {
    const date = input instanceof Date ? input : parseISO(input);
    if (!isValid(date)) return undefined;

    return `${date.getFullYear()}-${date.toLocaleString('default', {
        month: '2-digit',
    })}-${date.toLocaleString('default', {
        day: '2-digit',
    })}`;
};
