import apiSchema from '@config/schema';
import { name, schema, baseUrl } from './config';

export const TYPES = {
    UPDATE: `UPDATE_${name}`,
    UPDATE_SUCCESS: `UPDATE_${name}_SUCCESS`,
    UPDATE_FAILURE: `UPDATE_${name}_FAILURE`,
};

export default values => ({
    types: [TYPES.UPDATE, TYPES.UPDATE_SUCCESS, TYPES.UPDATE_FAILURE],
    schema: apiSchema.ENTITY(schema),
    payload: {
        request: {
            url: `${baseUrl}`,
            method: 'PUT',
            data: values,
        },
    },
});
