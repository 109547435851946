import { listActionCreator, withNoBrand, withNoSite, withPage, withRequestParams, withValues } from '../crud';
import { baseUrl, name, schema } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export default (
    page = 1,
    query = null,
    excludedIds?: number[],
    forcedBrand?: string,
    forcedSite?: number[],
    withoutBrand?: boolean,
    withoutSite?: boolean
) => {
    let action = withRequestParams({
        'translations.label': query || undefined,
        excludedIds,
        brand: forcedBrand,
        site: forcedSite,
    })(
        withValues({
            query,
            excludedIds,
        })(withPage(page)(actionCreator()))
    );

    if (withoutBrand || forcedBrand) {
        action = withNoBrand(action);
    }
    if (withoutSite || forcedSite) {
        action = withNoSite(action);
    }

    if (query) {
        action.save = false;
    }

    return action;
};
