export const DEFAULT_BRAND_LABEL = 'Sézane';

export const BRAND_CODES = {
    SEZANE: 'sezane',
    OCTOBRE: 'octobre',
} as const;

export const getBrandCodeFromLocation = (url: string) => {
    return url.includes((window as any).REACT_APP_BO_OCTOBRE_URL) ? BRAND_CODES.OCTOBRE : DEFAULT_BRAND_CODE;
};

export const DEFAULT_BRAND_CODE = BRAND_CODES.SEZANE;

export const BRAND_NAMES: Record<string, string> = {
    octobre: 'Octobre',
    sezane: 'Sézane',
};
