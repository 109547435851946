import classNames from 'classnames';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import React, { ElementType, FC, HTMLProps, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Size } from '@types';
import { Icon, Tooltip } from 'sezane-components';
import AbstractInput, { HelpBlock, InputRender } from './abstract';
import { AbstractInputProps } from './abstract/AbstractInput';

export const Checkbox: FC<CheckboxProps & ExtraProps> = props => {
    const {
        asToggle,
        className,
        disabled,
        help,
        hiddenLabel,
        htmlEl = 'div',
        input,
        label,
        labelClassName,
        labelGroup,
        labelSecondary,
        renderExtra,
        renderHelp,
        renderInput,
        secondary,
        size,
        stopPropagation,
    } = props;

    if (!label && !hiddenLabel) {
        throw new TypeError('You must set `hiddenLabel` prop to true if the checkbox does not have a label.');
    }
    const id = props.id || uniqueId('checkbox-');
    const renderedInput = renderInput('c-field c-form-group__field u-hidden', id, disabled || undefined);

    const renderLabel = () => (isString(label) ? <FormattedMessage id={label} /> : label);
    const labelElement = (
        <label
            className={classNames(labelClassName, 'c-label c-label--check c-form-group__label', {
                'c-label--check-secondary': secondary,
                'c-label--switch': asToggle,
                [`c-label--${size}`]: size,
                'c-label--only': !label,
                'c-label--secondary': labelSecondary || labelGroup,
            })}
            htmlFor={id}
        >
            {!!label && !labelGroup && renderLabel()}
            {labelGroup && (
                <FormattedMessage id={input && input.checked ? 'common.form.enabled' : 'common.form.disabled'} />
            )}
            {!!hiddenLabel && <span className="u-hidden">{hiddenLabel}</span>}
            {(help || renderHelp) && (
                <span className="u-ml(xs)" style={{ fontWeight: 'initial', textTransform: 'initial' }}>
                    <Tooltip
                        classNamesBox="u-w(200)"
                        onFocus
                        renderToggleButton={() => <Icon className="u-c(info)" icon="info-circle" color="secondary" />}
                    >
                        <HelpBlock
                            className="u-fz(sm) u-mt(xs) u-mw(300p) wrap"
                            help={help}
                            renderHelp={renderHelp}
                            value={input?.value}
                        />
                    </Tooltip>
                </span>
            )}
        </label>
    );

    const Tag = htmlEl;
    const tagProps: HTMLProps<HTMLElement> = {
        className: classNames(className, 'c-form-group c-form-group--check'),
    };
    if (stopPropagation) {
        tagProps.onClick = event => event.stopPropagation();
    }

    return (
        <Tag {...tagProps}>
            {labelGroup && (
                <fieldset>
                    <>
                        <legend className="c-label c-form-group__label">{renderLabel()}</legend>
                        {renderedInput}
                        {labelElement}
                    </>
                </fieldset>
            )}
            {!labelGroup && renderedInput}
            {!labelGroup && labelElement}
            {renderExtra && renderExtra()}
        </Tag>
    );
};

interface ExtraProps {
    renderInput: (className?: string, id?: string, disabled?: boolean) => void;
}

export interface CheckboxProps extends AbstractInputProps {
    htmlEl?: ElementType;
    asToggle?: boolean;
    disabled?: boolean;
    hiddenLabel?: ReactNode;
    labelGroup?: boolean;
    labelSecondary?: boolean;
    renderExtra?: () => ReactNode;
    secondary?: boolean;
    size?: Size;
    stopPropagation?: boolean;
    checked?: boolean;
    placeholder?: string;
}

export const CheckboxInput: FC<CheckboxProps> = ({ htmlEl = 'div', ...rest }) => (
    <Checkbox
        {...rest}
        htmlEl={htmlEl}
        renderInput={(className, id, disabled) => (
            <InputRender {...rest} id={id} className={className} type="checkbox" disabled={disabled} />
        )}
    />
);

export default AbstractInput<CheckboxProps>(props => <CheckboxInput {...props} />, false);
