import { schema } from '@actions/pushProducts/config';
import { TYPES as LIST_TYPES } from '@actions/pushProducts/list';
import { TYPES as GET_TYPES } from '@actions/pushProducts/get';
import { TYPES as UPDATE_TYPES } from '@actions/pushProducts/update';
import { TYPES as CREATE_TYPES } from '@actions/pushProducts/create';
import { TYPES as DELETE_TYPES } from '@actions/pushProducts/delete';
import {
    reduceReducers,
    CRUD_INITIAL_STATE,
    listReducer,
    updateReducer,
    createReducer,
    deleteReducer,
    getReducer,
} from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

export default reduceReducers(
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    deleteReducer(DELETE_TYPES),
    updateReducer(UPDATE_TYPES, schema),
    createReducer(CREATE_TYPES, schema)
);
