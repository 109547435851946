import { Dropdown } from 'sezane-components';
import classNames from 'classnames';
import React from 'react';

import { changeLocale } from '@actions/ui';
import { useAppDispatch, useAppSelector } from '@utils/hooks/redux';
import { uniqBy } from 'lodash';
import { Locale } from '@types';

type Props = {
    collapsed: boolean;
};

export const HeaderLocaleSwitcher = ({ collapsed }: Props) => {
    const { site, locale } = useAppSelector(state => state.ui);
    const dispatch = useAppDispatch();
    const locales = uniqBy(
        [...(site.availableLocales || []), site?.translationLocale, site?.defaultLocale],
        'code'
    ).filter(locale => locale) as Locale[];

    return (
        <Dropdown
            id="header-site-switcher"
            color="unstyled"
            textSecondary
            position="left"
            size="xs-bis"
            className={classNames('c-header__dropdown c-page__header-dropdown u-z-index-10', {
                'u-mt(xs)': !collapsed,
                'u-mt(lg)': collapsed,
            })}
            triggerClassName="c-header__dropdown-trigger"
            label={
                <>
                    <span className="c-header__dropdown-label c-page__dropdown-label">{locale.label}</span>
                </>
            }
            options={locales.map(locale => ({
                onSelect: () => dispatch(changeLocale(locale)),
                renderOption: () => locale.label,
            }))}
            selected={locales.findIndex(siteLocales => siteLocales.code === locale.code)}
        />
    );
};

export default HeaderLocaleSwitcher;
