import { addOnlyTrueFilter, addRangeFilter } from '@utils/filters';

export const schema = 'page-version';
export const name = 'PAGE_VERSION';
export const baseUrl = 'ecommerce-private/v1/page-versions';

export const getFilterParams = filters => {
    const { updatedAfter, updatedBefore, enabled, ...otherFilters } = filters || {};

    const filterParams = { ...otherFilters };

    addOnlyTrueFilter('enabled', enabled, filterParams);
    addRangeFilter('updatedAt', updatedAfter, updatedBefore, filterParams);

    return filterParams;
};

export const getSortParam = (sort, isTree = false) => {
    if (isTree) {
        return { [`order[hierarchyPath]`]: 'ASC', [`order[createdAt]`]: 'DESC' };
    }
    if (!sort) {
        return {};
    }

    const sortParam = { [`order[${sort.field}]`]: sort.direction };

    if (sort.field !== 'updatedAt') {
        sortParam['order[updatedAt]'] = 'DESC';
    }
    return sortParam;
};
