import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { matchPath, useLocation } from 'react-router';

import { ReactComponent as SezaneLogo } from '@img/logos/sezane.svg';
import { ReactComponent as OctobreLogo } from '@img/logos/octobre.svg';
import { ReactComponent as SezaneLogoSmall } from '@img/logos/sezane-small.svg';
import { ReactComponent as OctobreLogoSmall } from '@img/logos/octobre-small.svg';
import { changeBrand } from '@actions/ui';
import { Dropdown } from 'sezane-components';
import routesNames from '@config/routesNames';
import { getBrandCodeFromLocation } from '@utils/brands';
import { Brands } from '@types';

const brandsLogo = {
    sezane: SezaneLogo,
    sezane_phone: SezaneLogoSmall,
    octobre: OctobreLogo,
    octobre_phone: OctobreLogoSmall,
};

type logoKey = 'sezane' | 'octobre' | 'sezane_phone' | 'octobre_phone';

const isAllowedToSwitchBrand = (path: string) => {
    const allowedRoutes = [
        routesNames.LIST_SALES,
        routesNames.LIST_PRODUCTS,
        routesNames.LIST_MULTI_PRODUCTS,
        routesNames.MEDIA_LIBRARY,
        routesNames.LIST_CUSTOMISATION_SERVICES,
        routesNames.LIST_PREORDERS,
        routesNames.LIST_PAGES,
        routesNames.LIST_COMPOSITION_LAYOUTS,
        routesNames.MAIN_MENU,
        routesNames.LIST_MOBILE_CONTENTS,
        routesNames.FOOTER,
        routesNames.LIST_WORKSPACES,
        routesNames.UPDATE_MANDATORY_LOGIN,
        routesNames.LIST_PARAMETERS,
        routesNames.LIST_POPINS,
        routesNames.LIST_EVERGREEN_MEDIAS,
        routesNames.LIST_PACKAGINGS,
        routesNames.LIST_PUSH_PRODUCTS,
        routesNames.DASHBOARD,
    ];

    return matchPath(path, allowedRoutes)?.isExact;
};

type Props = {
    brands: Brands[];
    currentBrand: Brands;
    changeBrand: (brand: Brands) => void;
    collapsed: boolean;
};

export const BrandSwitcher = ({ changeBrand, brands, currentBrand, collapsed }: Props) => {
    const logoName: logoKey = collapsed ? (`${currentBrand.code}_phone` as logoKey) : (currentBrand.code as logoKey);
    const Logo = brandsLogo[logoName];
    const location = useLocation();

    const onSelect = (brand: Brands) => {
        if (brand.code === currentBrand.code) {
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            changeBrand(brand);
        } else {
            const boUrl =
                brand.code === 'sezane'
                    ? (window as any).REACT_APP_BO_SEZANE_URL
                    : (window as any).REACT_APP_BO_OCTOBRE_URL;
            window.location.href = `${boUrl}${window.location.pathname}${window.location.search}`;
        }
    };

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            const brandCode = getBrandCodeFromLocation(window.location.href);
            const switchedBrand = brands.find((brand: Brands) => brand.code === brandCode);

            if (switchedBrand && switchedBrand.code !== currentBrand.code) {
                changeBrand(switchedBrand);
            }
        }
    }, [window.location.href]);

    return (
        <Dropdown
            disabled={!isAllowedToSwitchBrand(location.pathname)}
            id="header-brand-switcher"
            color="unstyled"
            textSecondary
            position="left"
            size="xs-bis"
            className="c-header__dropdown c-page__header-dropdown u-mt(xs) u-z-index-30"
            triggerClassName="c-header__dropdown-trigger"
            label={
                <h1 className="c-logo u-mt(sm)">
                    <Logo />
                </h1>
            }
            options={brands.map(brand => ({
                onSelect: () => onSelect(brand),
                renderOption: () => brand.label,
            }))}
            selected={brands.findIndex(brand => brand.code === currentBrand.code)}
        />
    );
};

const mapStateToProps = (state: any) => ({
    brands: state.references.brands,
    currentBrand: state.ui.brand,
});

const mapDispatchToProps = {
    changeBrand,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandSwitcher);
