import { baseUrl } from './config';

export const TYPES = {
    IMPORT_PACKAGINGS_LINKED_ITEMS: 'IMPORT_PACKAGINGS_LINKED_ITEMS',
    IMPORT_PACKAGINGS_LINKED_ITEMS_SUCCESS: 'IMPORT_PACKAGINGS_LINKED_ITEMS_SUCCESS',
    IMPORT_PACKAGINGS_LINKED_ITEMS_FAILURE: 'IMPORT_PACKAGINGS_LINKED_ITEMS_FAILURE',
};

export default (file: Blob, id?: number) => {
    const data = new FormData();
    data.append('eans', file);

    return {
        types: [
            TYPES.IMPORT_PACKAGINGS_LINKED_ITEMS,
            TYPES.IMPORT_PACKAGINGS_LINKED_ITEMS_SUCCESS,
            TYPES.IMPORT_PACKAGINGS_LINKED_ITEMS_FAILURE,
        ],
        payload: {
            request: {
                url: `${baseUrl}/import/${id}`,
                method: 'POST',
                data,
            },
        },
        noSite: true,
    };
};
