import { listActionCreator, withPage, withRequestParams, withValues } from '../../crud';
import { name, schema, baseUrl, getFilterParams } from './config';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl, 'reference');
export const TYPES = types;

export default (page = 1, sort: any = null, filters: any = {}, query?: object | string) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};
    const { isPreOrder, ...otherFilters } = filters;
    const filterParams = getFilterParams(otherFilters);
    let action = actionCreator();
    return withValues({
        filters,
        query,
    })(
        withRequestParams({
            search: query || undefined,
            ...sortParam,
            ...filterParams,
            ...(isPreOrder && { 'isPreOrder[0]': isPreOrder }),
        })(withPage(page)(action))
    );
};
